import { IRouteProps } from '_common/interface/router';
import Dashboard from '.';
import { DASHBOARD_URL } from './url';

export const DashboardRouter: IRouteProps[] = [
  {
    key: DASHBOARD_URL.list.key,
    path: DASHBOARD_URL.list.shortenUrl,
    component: <Dashboard />,
  },
];
