import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const DomainAPIEndpoint = `${endpoint}/portal/domain`;

const DomainAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${DomainAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  searchDomain: async (params) =>
    await api
      .post(`${DomainAPIEndpoint}/search`, params)
      .then((res) => res.data),
  checkDomainAvailable: async (params) =>
    await api
      .post(`${DomainAPIEndpoint}/lookup`, params)
      .then((res) => res.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${DomainAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  requestCancel: async ({ id }) =>
    await api
      .put(`${DomainAPIEndpoint}/request-cancel/${id}`)
      .then((res) => res.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${DomainAPIEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
};

export default DomainAPI;
