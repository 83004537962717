import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { pick } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CUSTOMER_TYPE } from '../../constants';
import DatePicker from '_common/dof/Control/DatePicker';
import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import moment from 'moment';

type IProps = {
  open: boolean;
  onClose: () => void;
  onFilter: (value: any) => void;
  initialValue: any;
};

const PromoCodeFilter = ({ initialValue, onClose, onFilter, open }: IProps) => {
  const newForm = useForm({
    mode: 'onChange',
  });

  const handleReset = () => {
    const values = {
      time: null,
      customer: null,
    };
    onFilter(values);
    onClose();
  };

  const handleSubmitForm = (values: any) => {
    onFilter({
      ...values,
      ...(values?.time && { time: values?.time?.format(DATE_BACKEND_FORMAT) }),
    });
    onClose();
  };

  useEffect(() => {
    const values = pick(initialValue, ['customer']);

    newForm?.reset({
      ...values,
      ...(initialValue?.time && {
        time: moment(initialValue?.time, DATE_BACKEND_FORMAT),
      }),
    });
  }, [initialValue]);

  const customerOptions = Object.entries(CUSTOMER_TYPE).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Filter
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onFilter={() => newForm?.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={newForm} onSubmit={handleSubmitForm}>
        <FormTeraItem label="Khách hàng" name="customer">
          <Select placeholder="Vui lòng chọn" options={customerOptions} />
        </FormTeraItem>
        <FormTeraItem label="Thời gian hết hạn" name="time">
          <DatePicker format={DATE_FORMAT} />
        </FormTeraItem>
        <a
          className="text-sm font-normal text-red-500 cursor-pointer"
          onClick={handleReset}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
};

export default PromoCodeFilter;
