export const hostingTypes = [
  {
    id: 1,
    name: 'Hosting NVMe',
    description:
      'Trải nghiệm tốc độ siêu nhanh với ổ cứng NVMe Enterprise chuẩn U.2 với giá tốt nhất. Phần cứng máy chủ cao cấp và mạnh mẽ với vi xử lý Intel Xeon Scalable Gen 2, máy chủ Dell chuyên dụng cho mọi nhà.',
  },
  {
    id: 2,
    name: 'Business Hosting',
    description:
      'Trải nghiệm tốc độ siêu nhanh với ổ cứng NVMe Enterprise chuẩn U.2 với giá tốt nhất. Phần cứng máy chủ cao cấp và mạnh mẽ với vi xử lý Intel Xeon Scalable Gen 2, máy chủ Dell chuyên dụng dành cho doanh nghiệp.',
  },
  {
    id: 3,
    name: 'cPanel Reseller',
    description:
      'Dịch vụ chuyên dụng cho người bán lại với cấu hình cao, CPU Intel Xeon Gold và ổ cứng NVMe U.2',
  },
  {
    id: 4,
    name: 'Cloud Hosting',
    description:
      'Trải nghiệm tốc độ với ổ cứng NVME Enterprise chuẩn U.2 với giá tốt nhất. Phần cứng máy chủ cao cấp và mạnh mẽ với vi xử lý Intel Xeon Gold, máy chủ Dell chuyên dụng. Giá rẻ cho mọi nhu cầu.',
  },
];
export const servicePackages = [
  {
    id: 1,
    name: 'Host 1GB NVMe',
  },
  {
    id: 2,
    name: 'Business Hosting',
  },
  {
    id: 3,
    name: 'cPanel Reseller',
  },
  {
    id: 4,
    name: 'Host 1GB NVMe',
  },
  {
    id: 5,
    name: 'Business Hosting',
  },
  {
    id: 6,
    name: 'cPanel Reseller',
  },
  {
    id: 7,
    name: 'Cloud Hosting',
  },
];
export const dedicatedIPOptions = [
  { value: 1, label: '01 tháng' },
  { value: 2, label: '03 tháng - 16% discount' },
  { value: 4, label: '01 năm - 17% discount' },
  { value: 5, label: '02 năm - 27% discount' },
  { value: 6, label: '03 năm - 30% discount' },
  { value: 7, label: '04 năm - 29% discount' },
  { value: 8, label: '05 năm - 33% discount' },
];
export const comodoSSLOptions = [
  { value: 1, label: '01 năm' },
  { value: 2, label: '02 năm - 15% discount' },
  { value: 4, label: '03 năm - 20% discount' },
];
export const comodoSSLWildcardOptions = [
  { value: 1, label: '01 năm' },
  { value: 2, label: '02 năm - 26% discount' },
  { value: 4, label: '03 năm - 29% discount' },
];
export const durations = [
  { value: 1, label: '1 năm - 30,000 đ' },
  { value: 2, label: '2 năm - 60,000 đ' },
  { value: 3, label: '3 năm - 90,000 đ' },
  { value: 4, label: '4 năm - 120,000 đ' },
  { value: 5, label: '5 năm - 150,000 đ' },
  { value: 6, label: '6 năm - 180,000 đ' },
  { value: 7, label: '7 năm - 210,000 đ' },
  { value: 8, label: '8 năm - 240,000 đ' },
  { value: 9, label: '9 năm - 270,000 đ' },
  { value: 10, label: '10 năm - 300,000 đ' },
];
export const billingCycleOptions = [
  { value: 1, label: '01 tháng' },
  { value: 2, label: '03 tháng' },
  { value: 3, label: '06 tháng' },
  { value: 4, label: '01 năm - 10% discount' },
  { value: 5, label: '02 năm - 15% discount' },
  { value: 6, label: '03 năm - 20% discount' },
  { value: 7, label: '04 năm - 25% discount' },
  { value: 8, label: '05 năm - 30% discount' },
];
