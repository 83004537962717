import TableTera from '_common/dof/TableTera';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { SERVICE_REGISTRATION_URL } from 'pages/ServiceRegistration/url';
import { Fragment, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  TrashSolid,
  formatCurrency,
  getQueryParams,
} from 'tera-dls';

const OrderInformation = (): JSX.Element => {
  const {
    shoppingCartStore: { cart },
  } = useStores();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  queryParams;

  const serviceInCart = useMemo(() => cart?.[queryParams.cart], [cart]);

  const columns: ColumnsType<any> = [
    {
      title: 'Dịch vụ',
      dataIndex: '1',
      width: 350,
      render: (_, record) => record?.serviceName,
    },
    {
      title: 'Phí cài đặt',
      dataIndex: '2',
      render: (installation_fee) => formatCurrency(installation_fee),
    },
    {
      title: 'Giá',
      dataIndex: '3',
      render: (_, record) => formatCurrency(record?.first_payment),
    },
    {
      width: 40,
      fixed: 'right',
      render: () => (
        <TrashSolid
          color="#e05252"
          className="w-[20px] h-[20px] cursor-pointer"
          onClick={() => navigate(SERVICE_REGISTRATION_URL.list.path)}
        />
      ),
    },
  ];

  return (
    <div className="pb-8 border-b-[2px] border-dashed flex flex-col gap-4">
      <span className="text-blue-500">Thông tin đơn hàng</span>
      <TableTera
        rowKey={(_, index) => index}
        columns={columns}
        data={[serviceInCart]}
        className="[&_.tera-table-cell]:!align-middle"
        summary={() => (
          <Fragment>
            <tr className="tera-table-row">
              <td className="tera-table-cell" colSpan={2}>
                Tạm tính
              </td>
              <td className="tera-table-cell" colSpan={2}>
                {formatCurrency(serviceInCart?.first_payment)}
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell" colSpan={2}>
                Thanh toán theo định kỳ
              </td>
              <td className="tera-table-cell flex gap-1" colSpan={2}>
                {`${formatCurrency(serviceInCart?.recurring_amount)} / ${
                  serviceInCart?.period_text
                }`}
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell" colSpan={2}>
                Thanh toán hôm nay
              </td>
              <td className="tera-table-cell">
                <span className="text-[20px] text-[#0E9F6E]">
                  {formatCurrency(serviceInCart?.first_payment)}
                </span>
              </td>
            </tr>
          </Fragment>
        )}
      />
    </div>
  );
};

export default observer(OrderInformation);
