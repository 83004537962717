import CardDetail from '_common/component/CardDetail';
import Text from '_common/component/Text';
import TextNavigate from '_common/component/TextNavigate';
import ZingIcon from '_common/component/ZingIcon';
import { INVOICE_STATUS } from '_common/constants/invoiceManagement';
import TableTera from '_common/dof/TableTera';
import _ from 'lodash';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';
import { useMemo } from 'react';
import { Fragment } from 'react/jsx-runtime';
import {
  ArrowDownTraySolid,
  ArrowUpTraySolid,
  Button,
  ChevronDoubleRightSolid,
  Collapse,
  CollapseProps,
  ColumnsType,
  Tag,
  formatCurrency,
  formatDate,
} from 'tera-dls';
import {
  DOMAIN_AUTORENEW_COLOR,
  DOMAIN_AUTORENEW_TEXT,
  DOMAIN_PERIOD_TEXT,
  DOMAIN_PROTECTION_TEXT,
} from '../../constants';

interface IDetailContentProps {
  dataDetail: any;
}

const DetailContent = ({ dataDetail }: IDetailContentProps): JSX.Element => {
  const columns: ColumnsType<any> = [
    {
      title: 'Tên miền/Hostname',
      dataIndex: 'nameserver',
    },
    {
      title: 'Địa chỉ IP',
      dataIndex: 'ip',
      render: () => dataDetail?.ip,
    },
  ];

  const dataNameServer = useMemo(
    () =>
      dataDetail?.nameserver == '2'
        ? [
            { id: 1, nameserver: dataDetail?.ns1 },
            { id: 2, nameserver: dataDetail?.ns2 },
            { id: 3, nameserver: dataDetail?.ns3 },
            { id: 4, nameserver: dataDetail?.ns4 },
          ]
        : [{ id: 1, nameserver: `${dataDetail?.label}.zingcloud.vn` }],
    [dataDetail?.nameserver],
  );

  const domainDocuments = [
    {
      left: (
        <div className="pl-1 flex justify-between items-center">
          <span className="w-[300px]">
            Xác thực điện tử (EKYC) hồ sơ tên miền
          </span>
        </div>
      ),
      right: (
        <Button
          className="min-w-[150px] bg-green-500"
          prefix={<ChevronDoubleRightSolid />}
          onClick={() =>
            window.open(
              'https://kyc.info.vn/ekyc.php?token=JDIkNzdVVkhBNURvL3FMV2FHUVFTOW9DOUR1QzlSd3hieEt0Vm1pZlBhVENrZVNGTXZYVzFmU2xac29nSm9IQWlqRTlKUS83RnEvam9yKythNXdaUEpMZ1k0PQ==',
              '_blank',
            )
          }
        >
          Xác thực ngay
        </Button>
      ),
    },
    {
      left: (
        <div className="pl-1 flex justify-between items-center">
          <div className="w-[300px] flex flex-col">
            Bản khai tên miền
            <span className="text-[12px] text-gray-500">Bản khai tên miền</span>
          </div>
          <div className="flex flex-col">
            <Tag color="green03">Đang chờ duyệt</Tag>
            <span className="text-[12px] text-gray-500">
              dd/mm/yyyy - hh:mm
            </span>
          </div>
        </div>
      ),
      right: (
        <div className="flex gap-2">
          <Button
            className="min-w-[150px] bg-blue-500"
            prefix={<ArrowDownTraySolid />}
          >
            Tải về mẫu hồ sơ
          </Button>
          <Button
            className="min-w-[150px] bg-yellow-500"
            prefix={<ZingIcon.IconSign className="size-5" />}
            onClick={() =>
              window.open(
                'https://kyc.info.vn/?token=JDIkQ00yVjg0RmFaOFpvamRod25OSUp5SGpRWU9RUUwxYkMyQ0lFeEpkS3RFR3pWdE1sdXhJOFY3MFlvQldLajhXV2tDbjJDSEozWWtnSlFURGU0Ny9GOXBCZg==',
                '_blank',
              )
            }
          >
            Ký tên nhanh
          </Button>
          <Button
            className="min-w-[150px] bg-green-500"
            prefix={<ArrowUpTraySolid />}
          >
            Tải lên
          </Button>
        </div>
      ),
    },
    {
      left: (
        <div className="pl-1 flex justify-between items-center">
          <div className="w-[300px] flex flex-col">
            Căn cước công dân (Mặt trước)
            <span className="text-[12px] text-gray-500">
              Định dạng file được phép: png,jpg,jpeg
            </span>
          </div>
          <Tag color="yellow03">Chưa tải lên</Tag>
        </div>
      ),
      right: (
        <Button
          className="min-w-[150px] bg-green-500"
          prefix={<ArrowUpTraySolid />}
        >
          Tải lên
        </Button>
      ),
    },
    {
      left: (
        <div className="pl-1 flex justify-between items-center">
          <div className="w-[300px] flex flex-col">
            Căn cước công dân (Mặt sau)
            <span className="text-[12px] text-gray-500">
              Định dạng file được phép: png,jpg,jpeg
            </span>
          </div>
          <Tag color="yellow03">Chưa tải lên</Tag>
        </div>
      ),
      right: (
        <Button
          className="min-w-[150px] bg-green-500"
          prefix={<ArrowUpTraySolid />}
        >
          Tải lên
        </Button>
      ),
    },
  ];

  const itemCollapses: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Các loại hồ sơ khác',
      children: (
        <Fragment>
          <div className="p-2 border-b-[1px] flex justify-between items-center">
            <div className="pl-1 flex justify-between items-center">
              <span className="w-[300px]">Bản khai chuyển đổi nhà đăng ký</span>
            </div>
            <Button
              className="min-w-[150px] bg-blue-500"
              prefix={<ArrowDownTraySolid />}
            >
              Tải xuống
            </Button>
          </div>
          <div className="p-2 border-b-[1px] flex justify-between items-center">
            <div className="pl-1 flex justify-between items-center">
              <span className="w-[300px]">
                Đơn đề nghị thay đổi thông tin tên miền
              </span>
            </div>
            <Button
              className="min-w-[150px] bg-blue-500"
              prefix={<ArrowDownTraySolid />}
            >
              Tải xuống
            </Button>
          </div>
          <div className="p-2 border-b-[1px] flex justify-between items-center">
            <div className="pl-1 flex justify-between items-center">
              <span className="w-[300px]">
                Đơn đề nghị thay đổi tên chủ thể tên miền
              </span>
            </div>
            <Button
              className="min-w-[150px] bg-blue-500"
              prefix={<ArrowDownTraySolid />}
            >
              Tải xuống
            </Button>
          </div>
          <div className="p-2 border-b-[1px] flex justify-between items-center">
            <div className="pl-1 flex justify-between items-center">
              <span className="w-[300px]">
                Đơn đề nghị thay đổi tên chủ thể tên miền
              </span>
              <Tag color="yellow03">Chưa tải lên</Tag>
            </div>
            <Button
              className="min-w-[150px] bg-green-500"
              prefix={<ArrowUpTraySolid />}
            >
              Tải lên
            </Button>
          </div>
        </Fragment>
      ),
    },
  ];

  const detail = [
    {
      label: 'Tên miền',
      value: <span className="text-blue-500">{dataDetail?.name}</span>,
    },
    {
      label: 'Chu kỳ',
      value: DOMAIN_PERIOD_TEXT[dataDetail?.period],
    },
    {
      label: 'Ngày tạo',
      value: formatDate(dataDetail?.date_created),
    },
    {
      label: 'Ngày hết hạn',
      value: (
        <div className="flex gap-4">
          <span className="text-red-500">
            {formatDate(dataDetail?.expires)}
          </span>
          {!_.isEqual(
            dataDetail?.order[0].bill.status,
            INVOICE_STATUS.UNPAID,
          ) && <Text>Hoá đơn chưa thanh toán</Text>}
          <TextNavigate
            to={`${INVOICE_URL.detail.path}/${dataDetail?.order[0].bill?.id}`}
          >
            {dataDetail?.order[0].bill?.invoice_no}
          </TextNavigate>
        </div>
      ),
    },
    {
      label: 'Hạn thanh toán kế tiếp',
      value: formatDate(dataDetail?.next_invoice),
    },
    {
      label: 'Số ngày còn lại',
      value: dataDetail?.daytoexpire,
    },
    {
      label: 'Được bảo vệ',
      value: DOMAIN_PROTECTION_TEXT[dataDetail?.idprotection],
    },
    {
      label: 'Giá',
      value: formatCurrency(dataDetail?.order[0].bill?.final_total),
    },
    {
      label: 'Số tiền thanh toán định kỳ',
      value: formatCurrency(dataDetail?.order[0].bill?.final_total),
    },
    {
      label: 'Số tiền thanh toán lần đầu',
      value: formatCurrency(dataDetail?.order[0].bill?.final_total),
    },
    {
      label: 'Ghi chú / Thông tin thêm',
      value: dataDetail.note,
    },
    {
      label: 'Tự động gia hạn',
      value: (
        <Tag color={DOMAIN_AUTORENEW_COLOR[dataDetail?.autorenew]}>
          {DOMAIN_AUTORENEW_TEXT[dataDetail?.autorenew]}
        </Tag>
      ),
    },
  ];

  return (
    <CardDetail className="p-4 flex flex-col gap-4">
      <div className="pb-4 border-b-[2px] border-dashed">
        <span className="text-blue-500">Thông tin chung</span>
        <div className="p-4 flex flex-col gap-1">
          {detail.map((detail, index) => (
            <div key={index} className="py-3 border-b-[1px] flex items-center">
              <span className="flex-1 text-gray-500">{detail.label}</span>
              <span className="flex-1">{detail.value}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="pb-8 border-b-[2px] border-dashed mt-2 flex flex-col gap-4">
        <span className="text-blue-500">Nameservers</span>
        <TableTera rowKey="id" data={dataNameServer} columns={columns} />
      </div>
      <div className="pb-4 mt-2 flex flex-col gap-4">
        <span className="text-blue-500">Domain Documents</span>
        <div className="bg-white rounded-[6px] drop-shadow flex flex-col">
          <span className="p-4 border-b-[1px]">
            Để sử dụng tên miền đúng quy định pháp luật, bạn cần hoàn tất các
            tài liệu dưới đây.
          </span>
          {domainDocuments.map((document, index) => (
            <div
              key={index}
              className="p-2 border-b-[1px] flex justify-between items-center"
            >
              {document.left}
              {document.right}
            </div>
          ))}
          <Collapse
            items={itemCollapses}
            headingClassName="bg-white p-3 border-0 border-b-[1px]"
            contentClassName="p-0 border-0"
          />
        </div>
      </div>
    </CardDetail>
  );
};

export default DetailContent;
