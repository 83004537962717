import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const OrderAPIEndpoint = `${endpoint}/portal/order`;

const OrderAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${OrderAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${OrderAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${OrderAPIEndpoint}/create`, params)
      .then((res) => res?.data),
};

export default OrderAPI;
