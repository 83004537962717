import { IRouteProps } from '_common/interface/router';
import EmailSecurityPage from '.';
import AddEmailSecurityPage from './pages/Add';
import DetailEmailSecurityPage from './pages/Detail';
import { SERVICE_EMAIL_SECURITY_URL } from './url';

export const EmailSecurityRouter: IRouteProps[] = [
  {
    key: SERVICE_EMAIL_SECURITY_URL.list.key,
    path: SERVICE_EMAIL_SECURITY_URL.list.shortenUrl,
    component: <EmailSecurityPage />,
  },
  {
    key: SERVICE_EMAIL_SECURITY_URL.create.key,
    path: SERVICE_EMAIL_SECURITY_URL.create.shortenUrl,
    component: <AddEmailSecurityPage />,
  },
  {
    key: SERVICE_EMAIL_SECURITY_URL.detail.key,
    path: SERVICE_EMAIL_SECURITY_URL.detail.shortenUrl,
    component: <DetailEmailSecurityPage />,
  },
];
