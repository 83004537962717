import { IRouteProps } from '_common/interface/router';
import UnAuthSSLPage from '.';
import { UNAUTH_SERVICE_SSL_URL } from './url';

export const UnAuthSSLRouter: IRouteProps[] = [
  {
    key: UNAUTH_SERVICE_SSL_URL.index.key,
    path: UNAUTH_SERVICE_SSL_URL.index.shortenUrl,
    component: <UnAuthSSLPage />,
  },
];
