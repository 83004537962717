import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const UserAccountAPIEndpoint = `${endpoint}/portal/account`;

const UserAccountAPI = {
  create: async ({ params }) =>
    await api
      .post(`${UserAccountAPIEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${UserAccountAPIEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  updateSetting: async ({ id, params }) =>
    await api
      .put(`${UserAccountAPIEndpoint}/update/${id}/update-setting`, params)
      .then((res) => res.data),
  updateNote: async ({ id, params }) =>
    await api
      .put(`${UserAccountAPIEndpoint}/update/${id}/update-note`, params)
      .then((res) => res.data),
  updateKYC: async ({ id, params }) =>
    await api
      .put(`${UserAccountAPIEndpoint}/update/${id}/update-kyc`, params)
      .then((res) => res.data),
};

export default UserAccountAPI;
