import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const TransactionAPIEndpoint = `${endpoint}/portal/finance/transaction`;

const TransactionAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${TransactionAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${TransactionAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
};

export default TransactionAPI;
