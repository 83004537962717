import React, { useState } from 'react';
// import image from 'styles/images/uiNew/logo.png';

interface DefaultImageProps {
  src: string;
  alt: string;
  defaultSrc?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}

function DefaultImage({
  src,
  alt,
  defaultSrc,
  style,
  className = 'w-[15px] h-[15px]',
  onClick,
}: DefaultImageProps) {
  const [imgSrc, setImgSrc] = useState(src);

  function onError() {
    setImgSrc(defaultSrc);
  }

  return (
    <img
      src={imgSrc || defaultSrc}
      alt={alt}
      style={style}
      className={className}
      onClick={onClick}
      onError={onError}
    />
  );
}

export default DefaultImage;
