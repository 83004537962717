import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { forwardRef, useImperativeHandle } from 'react';
import WalletAPI from 'states/api/WalletAPI';
import { Spin, formatCurrency, notification } from 'tera-dls';

const Transfer = (_, ref) => {
  const {
    transactionStore: { account, amount, nextStep, setIsLoading },
  } = useStores();
  const queryClient = useQueryClient();

  const rechargeWithdrawal = useMutation(
    (params: any) => {
      return WalletAPI.withdrawal({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          setIsLoading(false);
          nextStep();
          queryClient.invalidateQueries(['get-finance-withdrawal-list']);
          queryClient.invalidateQueries(['get-profile']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleNextStep = () => {
    setIsLoading(true);
    rechargeWithdrawal.mutate({
      payment_method_id: account?.id,
      total: amount,
    });
  };

  useImperativeHandle(ref, () => {
    return { nextStep: handleNextStep };
  });

  return (
    <Spin spinning={rechargeWithdrawal.isLoading}>
      <div className="flex flex-col gap-y-4">
        <div className="flex justify-between items-center">
          <span className="text-gray-500">Loại giao dịch:</span>
          <span className="text-gray-800">Rút tiền</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-500">Phương thức thanh toán:</span>
          <span className="text-gray-800">Chuyển khoản</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-500">Số tiền:</span>
          <span className="text-gray-800">{formatCurrency(amount)}</span>
        </div>
        <p className="text-center text-blue-700">
          Vui lòng xác nhận thông tin tài khoản nhận tiền
        </p>
        <div className="p-4 border rounded-[10px] grid grid-cols-12 gap-x-6">
          <div className="col-span-5 flex flex-col items-end gap-y-2.5 text-gray-500">
            <span>Số tài khoản</span>
            <span>Người thụ hưởng</span>
            <span>Ngân hàng</span>
            <span>Số tiền</span>
            <span>Nội dung</span>
          </div>
          <div className="col-span-7 flex flex-col items-start gap-y-2.5 text-gray-700">
            <span>{account?.number || '__'}</span>
            <span>{account?.cardholder || '__'}</span>
            <span>{account?.cardName || '__'}</span>
            <span className="font-bold text-blue-500">
              {formatCurrency(amount) || '__'}
            </span>
            <span>{`Chuyển khoản cho ${account?.cardholder}`}</span>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default observer(forwardRef(Transfer));
