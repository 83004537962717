import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, MagnifyingGlassOutlined } from 'tera-dls';
import DomainPriceList from './components/DomainPriceList';
import SearchResult from './components/SearchResult';
import { IDomainItem } from './components/SearchResultItem';

interface ISearchValues {
  domain: string;
}

const UnAuthDomainPage = (): JSX.Element => {
  const form = useForm({ mode: 'onChange', defaultValues: { domain: '' } });
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [domainProcesses, setDomainProcesses] = useState<Array<IDomainItem>>(
    [],
  );

  const handleToggleDomain = (domain: IDomainItem) => {
    const isExisted =
      domainProcesses.filter((domainProcess) => domainProcess.id === domain.id)
        .length > 0;

    if (isExisted)
      setDomainProcesses((prevState) => [
        ...prevState.filter((domainProcess) => domainProcess.id !== domain.id),
      ]);
    else setDomainProcesses((prevState) => [...prevState, domain]);
  };

  const handleSearch = (values: ISearchValues) => {
    if (values.domain) setIsSearch(true);
  };

  return (
    <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb text-[16px] font-[600]">
            TÊN MIỀN
          </div>
        </div>
      </div>
      <div className="flex-1 px-2 flex flex-col gap-12">
        <div className="bg-[#3f83f8] p-10 rounded-[10px]">
          <FormTera
            form={form}
            onSubmit={handleSearch}
            className="flex flex-col gap-10"
          >
            <h2 className="text-[24px] text-white">
              Tìm tên miền bạn mong muốn
            </h2>
            <FormTeraItem
              className="w-full mb-0"
              name="domain"
              rules={[{ required: messageValidate.emptyText }]}
            >
              <Input
                placeholder="Nhập tên miền bạn muốn kiểm tra"
                className="h-[52px] border rounded-[10px]"
                suffix={
                  <Button
                    className="w-[110px] bg-[#3F83F8]"
                    prefix={<MagnifyingGlassOutlined />}
                    onClick={() => form.handleSubmit(handleSearch)()}
                  >
                    Tìm kiếm
                  </Button>
                }
              />
            </FormTeraItem>
          </FormTera>
        </div>
        {isSearch ? (
          <SearchResult
            searchKey={form.getValues('domain')}
            domainProcesses={domainProcesses}
            handleToggleDomain={handleToggleDomain}
          />
        ) : (
          <DomainPriceList />
        )}
      </div>
    </div>
  );
};

export default UnAuthDomainPage;
