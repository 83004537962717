import { observer } from 'mobx-react-lite';
import { Toggle } from 'tera-dls';

const HeaderModalMoreView = (): JSX.Element => {
  return (
    <div className="flex justify-between items-center gap-2 border-b-[1px] border-gray-200 p-2">
      <a className="text-blue-600 cursor-pointer">Đánh dấu đã đọc</a>
      <div className="flex items-center gap-2.5">
        <span>Thông báo</span>
        <Toggle containerClassName="block" />
      </div>
    </div>
  );
};

export default observer(HeaderModalMoreView);
