import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';
import EmptyImage from 'styles/images/wallet/empty_card.png';
import { PlusCircleOutlined, Spin } from 'tera-dls';
import CardList from '../../containers/CardList';
import Empty from '../../containers/Empty';
import ModalCreate from '../../containers/ModalCreate';
import Slider from './Slider';

function BankCard() {
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    data: dataList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-finance-account-payment-method-list'],
    () => {
      return PaymentMethodAPI.getListOfAccount({ params: {} });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="flex justify-between items-center">
        <h3 className="uppercase text-sm text-gray-700">Thẻ ngân hàng</h3>
        <div className="flex items-center gap-x-1">
          <PlusCircleOutlined
            className="size-5 text-green-500 cursor-pointer"
            onClick={() => setIsCreate(true)}
          />
          <span
            className="text-blue-400 hover:underline cursor-pointer"
            onClick={() => setIsOpenList(true)}
          >
            Xem tất cả
          </span>
        </div>
      </div>
      {!_.isEmpty(dataList?.data) ? (
        <Slider data={dataList?.data} />
      ) : (
        <div className="py-5">
          <Empty
            image={<img src={EmptyImage} />}
            sub={
              <>
                Nhấn{' '}
                <span>
                  <PlusCircleOutlined
                    className="size-5 text-green-400 cursor-pointer"
                    onClick={() => setIsCreate(true)}
                  />
                </span>{' '}
                để thêm thẻ
              </>
            }
          />
        </div>
      )}
      {isOpenList && (
        <CardList open={isOpenList} onCancel={() => setIsOpenList(false)} />
      )}
      {isCreate && (
        <ModalCreate
          id={null}
          onClose={() => setIsCreate(false)}
          open={isCreate}
          onRefetch={() =>
            queryClient.invalidateQueries(['get-card-bank-list'])
          }
        />
      )}
    </Spin>
  );
}

export default BankCard;
