import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { messageError } from '_common/constants/message';
import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBank from '_common/dof/Select/SelectBank';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Background from 'styles/images/wallet/bg-card-bank-list.png';
import EmptyImage from 'styles/images/wallet/empty_card.png';
import EmptySearch from 'styles/images/wallet/empty_search.png';
import {
  Modal,
  notification,
  PlusCircleOutlined,
  Spin,
  XMarkOutlined,
} from 'tera-dls';
import Card from '../../components/BankCard/Card';
import ModalCreate from '../ModalCreate';
import PaymentMethodApi from 'pages/InvoiceManagement/Withdrawal/api/payment';
import Empty from '../Empty';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';

function CardList(props) {
  const { open, onCancel } = props;
  const form = useForm();
  const confirm = useConfirm();
  const [modal, setModal] = useState({
    open: false,
    id: null,
  });
  const [queryParams, setQueryParams] = useState(null);
  const queryClient = useQueryClient();
  const limit = 15;
  const latestRef = useRef(null);
  const {
    data,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ['get-finance-account-payment-method-infinity'],
    staleTime: 30000,
    cacheTime: 30000,
    queryFn: ({ pageParam }) => {
      const params = {
        ...queryParams,
        page: pageParam || 1,
        limit,
      };
      return PaymentMethodAPI.getListOfAccount({ params });
    },
    enabled: false,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.current_page < lastPage?.last_page
        ? allPages?.length + 1
        : undefined;
    },
  });

  const value = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      return _.unionBy(acc, page?.data ?? [], 'id');
    }, []);
  }, [data]);

  const deleteCard = useMutation(
    (id: number) => PaymentMethodApi.deleteCardBank({ id }),
    {
      onSuccess(res: any) {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-card-bank-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        const errorMessage = error?.data?.msg || messageError.ERROR_API;
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleObserver = (entries: any) => {
    if (
      entries[0].isIntersecting &&
      hasNextPage &&
      !isFetching &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0.5,
    });

    if (observer && latestRef.current) {
      observer.observe(latestRef.current);
    }

    return () => {
      if (latestRef.current) {
        observer.disconnect();
      }
    };
  }, [value, handleObserver, latestRef]);

  const handleDeleteBankCard = (card, index) => {
    confirm.warning({
      title: 'Xác nhận xóa ngân hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa thẻ ngân hàng</p>
          <p>
            <b>{card?.type?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: async () => {
        const response = (await deleteCard.mutateAsync(card?.id)) as any;
        const currentPage = Math.floor(index / limit) + 1;
        if (!currentPage || currentPage <= 0 || response?.code !== 200) return;

        queryClient.setQueryData(
          ['get-finance-account-payment-method-list'],
          (oldData: any) => {
            return {
              pages: oldData?.pages?.filter(
                (_, index) => index <= currentPage - 1,
              ),
              pageParams: oldData?.pageParams.filter(
                (_, index) => index <= currentPage - 1,
              ),
            };
          },
        );

        refetch({
          refetchPage: (page, index) => {
            return index === currentPage - 1;
          },
        });
      },
    });
  };

  useEffect(() => {
    // if (queryParams) {
    queryClient.setQueryData(
      ['get-card-bank-list-infinity'],
      (oldData: any) => {
        return {
          pages: [oldData?.pages[0]],
          pageParams: [1],
        };
      },
    );
    refetch();
    // }
  }, [queryParams]);

  const handleSubmitForm = (value) => {
    setQueryParams(value);
  };

  const hasSearch = (): boolean => {
    const value = form.watch();
    const result = Object.values(value).some(
      (val) => val !== null && val !== undefined && val !== '',
    );
    return result;
  };

  return (
    <Modal
      open={open}
      width="90%"
      onCancel={onCancel}
      footerClassName="!hidden"
      className="modal-card-bank-list "
    >
      <Spin spinning={isLoading}>
        <div
          className="relative py-20 px-32 h-[90vh] flex flex-col gap-y-8 rounded-3xl"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundSize: '100% 100%',
          }}
        >
          <div className="flex items-center justify-between">
            <span
              className="text-3xl text-shadow-lg text-white"
              style={{
                textShadow: '2px 2px 5px #333',
              }}
            >
              Thẻ của tôi <span>({data?.pages?.[0]?.total ?? 0})</span>
            </span>
            <FormTera
              form={form}
              className="flex items-center gap-x-2"
              onSubmit={form.handleSubmit(handleSubmitForm)}
            >
              <PlusCircleOutlined
                className="size-10 text-white cursor-pointer hover:text-green-40 0"
                onClick={() => setModal({ open: true, id: null })}
              />
              <>
                <FormTeraItem name="type_id" className="mb-0">
                  <SelectBank
                    placeholder="Tất cả thẻ"
                    className="w-[400px] bg-white rounded-full"
                    onSelect={() => form.handleSubmit(handleSubmitForm)()}
                    onClear={() => form.handleSubmit(handleSubmitForm)()}
                  />
                </FormTeraItem>
                <FormTeraItem name="keyword" className="mb-0">
                  <Search placeholder="Tìm kiếm STK, người thụ hưởng" />
                </FormTeraItem>
              </>
            </FormTera>
          </div>
          <div id="list-bank-card" className="overflow-auto">
            {value?.length > 0 ? (
              <div className="grid grid-cols-4 gap-10 overflow-auto p-1 ">
                {value?.map((card, index) => (
                  <div
                    key={index}
                    className="hover:-translate-y-1 transition-all"
                    ref={index === value?.length - 1 ? latestRef : undefined}
                  >
                    <Card
                      image={card?.type?.image_bank}
                      fullName={card?.account_name}
                      bankName={card?.type?.name}
                      description={card?.type?.description}
                      cardNumber={card?.account_number}
                      onDelete={() => handleDeleteBankCard(card, index)}
                      onUpdate={() => setModal({ open: true, id: card?.id })}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex w-[300px] h-[300px] rounded-full bg-white/30 shadow-inner mx-auto">
                <Empty
                  image={
                    hasSearch() ? (
                      <img src={EmptySearch} />
                    ) : (
                      <img src={EmptyImage} />
                    )
                  }
                  sub={
                    hasSearch() ? (
                      'Không tìm thấy thẻ'
                    ) : (
                      <>
                        Nhấn{' '}
                        <span>
                          <PlusCircleOutlined
                            className="size-5 text-green-400 cursor-pointer"
                            onClick={() => setModal({ open: true, id: null })}
                          />
                        </span>{' '}
                        để thêm thẻ
                      </>
                    )
                  }
                />
              </div>
            )}
          </div>

          <XMarkOutlined
            className="w-16 h-16 text-white cursor-pointer absolute top-[20px] right-[20px]"
            onClick={onCancel}
          />
        </div>
      </Spin>
      {modal?.open && (
        <ModalCreate
          id={modal?.id}
          onClose={() => setModal({ open: false, id: null })}
          open={modal?.open}
          onRefetch={() => refetch()}
        />
      )}
    </Modal>
  );
}

export default CardList;
