import Text from '_common/component/Text';
import { observer } from 'mobx-react-lite';
import { Badge, Popover, ShoppingCartSolid } from 'tera-dls';

const UserShoppingCart = (): JSX.Element => {
  const Content = (): JSX.Element => {
    return (
      <div className="">
        <div className="p-2 px-4 border-b-[1px]">Giỏ hàng</div>
        <div className="w-[300px] max-h-[200px] overflow-y-scroll">
          {Array(1)
            .fill(null)
            .map((_, index) => {
              return (
                <Text
                  key={index}
                  className="flex justify-between items-center p-2 px-4 border-b-[1px]"
                >
                  <span>Chức năng đang cập nhật</span>
                  <span className="text-[#0E9F6E] font-bold" />
                </Text>
              );
            })}
        </div>
        {/* <a
          className="block text-blue-600 p-2 px-4 cursor-pointer"
          onClick={() => navigate(SHOPPING_CART_URL.cart.path)}
        >
          Đến trang giỏ hàng &gt;&gt;
        </a> */}
      </div>
    );
  };

  return (
    <Popover
      trigger="click"
      content={<Content />}
      placement="bottom-end"
      showArrow={false}
      className="border p-0"
    >
      <div className="cursor-pointer relative">
        <ShoppingCartSolid className="size-6" />
        <Badge
          showZero
          count={0}
          className="text-white text-[8px] bg-[#F98080] px-1 py-0 absolute -top-1 -right-1"
        />
      </div>
    </Popover>
  );
};

export default observer(UserShoppingCart);
