export const UNAUTH_SERVICE_CLOUD_VPS_URL = {
  index: {
    key: '',
    path: '/welcome/service/cloud-vps',
    shortenUrl: 'cloud-vps',
  },
  add: {
    key: '',
    path: '/welcome/service/cloud-vps/add',
    shortenUrl: 'cloud-vps/add',
  },
};
