import { Col, Row } from 'tera-dls';
import BankCard from './components/BankCard';
import DepositContent from './components/DepositContent';
import Transaction from './components/Transaction';

const DepositPage = () => {
  return (
    <div className="tera-page-form !gap-0">
      <div className="page-header-sticky bg-[#F3F3F9]">
        <div className="page-header-v2">
          <div className="text-gray-700 font-semibold text-base uppercase">
            Nạp tiền
          </div>
        </div>
      </div>
      <div className="page-content-v2">
        <Row className="grid grid-cols-3">
          <Col className="col-span-2 flex flex-col gap-[16px]">
            <DepositContent />
            <Transaction />
          </Col>
          <Col className="col-span-1">
            <BankCard />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DepositPage;
