import TeraFilter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface FilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (values: any) => void;
  initialValue: any;
}

function Filter({ open, onClose, onFilter, initialValue }: FilterProps) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      created_at: null,
    },
  });

  useEffect(() => {
    const values = _.pick(initialValue, ['created_from', 'created_to']);

    form.reset({
      ...values,
      created_at: values.created_from &&
        values.created_to && [
          moment(values.created_from),
          moment(values.created_to),
        ],
    });
  }, [initialValue]);

  const handleSubmitForm = (values) => {
    onFilter({
      ...values,
      created_from:
        values.created_at?.[0] &&
        values.created_at[0].format(DATE_BACKEND_FORMAT),
      created_to:
        values.created_at?.[1] &&
        values.created_at[1].format(DATE_BACKEND_FORMAT),
    });
    onClose();
  };

  const handleReset = () => {
    onFilter({
      created_at: null,
      created_from: null,
      created_to: null,
    });
    onClose();
  };

  return (
    <TeraFilter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera
        form={form}
        onSubmit={form.handleSubmit(handleSubmitForm)}
        className="flex flex-col gap-2"
      >
        <FormTeraItem label="Ngày rút" name="created_at" className="mb-0">
          <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </TeraFilter>
  );
}

export default Filter;
