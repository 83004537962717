import { IRouteProps } from '_common/interface/router';
import HistoryPage from '.';
import { HISTORY_URL } from './url';

export const HistoryRouter: IRouteProps[] = [
  {
    key: HISTORY_URL.list.key,
    path: HISTORY_URL.list.shortenUrl,
    component: <HistoryPage />,
  },
];
