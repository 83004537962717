import { GENDER } from '_common/constants/common';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';

const CustomerInformation = (): JSX.Element => {
  const {
    authStore: { user },
  } = useStores();

  const details = [
    { label: 'Tên', value: user?.account?.first_name },
    { label: 'Họ và tên đệm', value: user?.account?.last_middle_name },
    { label: 'Danh xưng', value: GENDER[user?.account?.gender] },
    { label: 'Số CCCD/Passport', value: user?.account?.citizen_id },
    { label: 'Email', value: user?.account?.email },
    { label: 'Điện thoại', value: user?.account?.phone },
    { label: 'Quốc gia', value: user?.account?.country },
    { label: 'Tỉnh/Thành', value: user?.account?.province },
    { label: 'Quận/Huyện', value: user?.account?.district },
    { label: 'Phường/Xã', value: user?.account?.ward },
    { label: 'Địa chỉ', value: user?.account?.address },
    { label: 'Email nhận hóa đơn', value: user?.account?.email },
  ];

  return (
    <div className="py-8 border-b-[2px] border-dashed flex flex-col gap-4">
      <span className="text-blue-500">Thông tin khách hàng</span>
      <div className="flex flex-wrap">
        {details.map((detail, index) => (
          <div key={index} className="w-[50%] py-4 border-b-[1px]">
            <span className="w-[150px] text-gray-500 inline-block">
              {detail.label}
            </span>
            <span>{detail.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(CustomerInformation);
