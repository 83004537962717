import { IRouteProps } from '_common/interface/router';
import CloudVPSPage from '.';
import CreateUpdatePage from './pages/CreateUpdate';
import DetailPage from './pages/Detail';
import ReinstallOSVPSPage from './pages/ReinstallOS';
import UpgradeVPSPage from './pages/Upgrade';
import UpgradeVPSPaymentPage from './pages/UpgradePayment';
import { SERVICE_CLOUD_VPS_URL } from './url';

export const CloudVPSRouter: IRouteProps[] = [
  {
    key: SERVICE_CLOUD_VPS_URL.list.key,
    path: SERVICE_CLOUD_VPS_URL.list.shortenUrl,
    component: <CloudVPSPage />,
  },
  {
    key: SERVICE_CLOUD_VPS_URL.create.key,
    path: SERVICE_CLOUD_VPS_URL.create.shortenUrl,
    component: <CreateUpdatePage />,
  },
  {
    key: SERVICE_CLOUD_VPS_URL.detail.key,
    path: SERVICE_CLOUD_VPS_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
  {
    key: SERVICE_CLOUD_VPS_URL.upgrade.key,
    path: SERVICE_CLOUD_VPS_URL.upgrade.shortenUrl,
    component: <UpgradeVPSPage />,
  },
  {
    key: SERVICE_CLOUD_VPS_URL.upgradePayment.key,
    path: SERVICE_CLOUD_VPS_URL.upgradePayment.shortenUrl,
    component: <UpgradeVPSPaymentPage />,
  },
  {
    key: SERVICE_CLOUD_VPS_URL.reinstallOS.key,
    path: SERVICE_CLOUD_VPS_URL.reinstallOS.shortenUrl,
    component: <ReinstallOSVPSPage />,
  },
];
