import PageTitle from '_common/component/PageTitle';
import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import InputPassword from '_common/dof/Control/InputPassword';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, EyeOutlined, EyeSlashOutlined, Row, Spin } from 'tera-dls';
import { LOGIN_URL } from '../Login/url';

interface IResetPasswordValues {
  new_password: string;
  renew_password: string;
}

const ResetPasswordPage = (): JSX.Element => {
  const form = useForm({ mode: 'onChange' });
  const navigate = useNavigate();

  useEffect(() => {
    const initData: IResetPasswordValues = {
      new_password: '',
      renew_password: '',
    };
    form.reset(initData);
  }, []);

  const handleReset = (values: IResetPasswordValues) => {
    console.log(values);
    navigate(LOGIN_URL.index.path);
  };

  return (
    <>
      <PageTitle title="THAY ĐỔI MẬT KHẨU" />
      <div className="tera-page h-full flex-1 flex flex-col justify-between items-center">
        <Row className="flex-1 flex items-center">
          <Spin spinning={false}>
            <Row className="w-[476px] h-[703px] bg-white px-[2rem] py-[3rem] rounded-[30px] drop-shadow flex flex-col justify-center items-center gap-[4rem]">
              <FormTera
                form={form}
                onSubmit={handleReset}
                className="w-full h-full flex flex-col justify-between"
              >
                <Row className="w-full flex flex-col items-center">
                  <h2 className="text-[30px] text-[#1E429F] pb-10">
                    Thay đổi mật khẩu
                  </h2>
                  <FormTeraItem
                    className="w-full mb-0"
                    label="Mật khẩu mới"
                    name="new_password"
                    rules={[
                      {
                        required: messageValidate.emptyText,
                        pattern: {
                          value: new RegExp(REGEX.PASSWORD),
                          message: messageValidate.password,
                        },
                        minLength: {
                          value: 8,
                          message: messageValidate.min_length_password,
                        },
                        maxLength: {
                          value: 16,
                          message: messageValidate.max_length_password,
                        },
                      },
                    ]}
                  >
                    <InputPassword
                      placeholder="Vui lòng nhập mật khẩu mới"
                      className="h-[52px] border rounded-[10px]"
                      iconRender={(visible) =>
                        visible ? (
                          <EyeSlashOutlined className="w-[24px] h-[24px]" />
                        ) : (
                          <EyeOutlined className="w-[24px] h-[24px]" />
                        )
                      }
                    />
                  </FormTeraItem>
                  <FormTeraItem
                    className="w-full mb-0"
                    label="Xác nhận mật khẩu mới"
                    name="renew_password"
                    rules={[
                      {
                        required: messageValidate.emptyText,
                        pattern: {
                          value: new RegExp(REGEX.PASSWORD),
                          message: messageValidate.password,
                        },
                        minLength: {
                          value: 8,
                          message: messageValidate.min_length_password,
                        },
                        maxLength: {
                          value: 16,
                          message: messageValidate.max_length_password,
                        },
                        validate: {
                          existed: (
                            value: string,
                            values: IResetPasswordValues,
                          ) => {
                            const password = values?.new_password.trim();

                            if (value?.trim() !== password) {
                              return messageValidate.confirm_password;
                            }
                          },
                        },
                      },
                    ]}
                  >
                    <InputPassword
                      placeholder="Vui lòng nhập lại mật khẩu mới"
                      className="h-[52px] border rounded-[10px]"
                      iconRender={(visible) =>
                        visible ? (
                          <EyeSlashOutlined className="w-[24px] h-[24px]" />
                        ) : (
                          <EyeOutlined className="w-[24px] h-[24px]" />
                        )
                      }
                    />
                  </FormTeraItem>
                </Row>
                <Row className="flex flex-col items-center">
                  <Button
                    type="primary"
                    className="w-full h-[49px] text-[20px] font-bold bg-[#1975A7] rounded-[10px] mt-5"
                    disabled={false}
                  >
                    Thay đổi mật khẩu
                  </Button>
                  <Row className="mt-5 flex">
                    <span>Bạn đã có tài khoản ZingServer?</span>
                    <Button
                      className="bg-transparent hover:bg-transparent p-0 cursor-pointer"
                      onClick={() => navigate(LOGIN_URL.index.path)}
                    >
                      <span className="text-[15px] text-[#005880] font-normal">
                        Đăng nhập
                      </span>
                    </Button>
                  </Row>
                </Row>
              </FormTera>
            </Row>
          </Spin>
        </Row>
      </div>
    </>
  );
};

export default ResetPasswordPage;
