const WindowIcon = (props) => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.144531"
      width="39.9801"
      height="39.9446"
      rx="19.9723"
      fill="#DEF7EC"
    />
    <path
      d="M30.0945 25.9544C30.0932 26.5085 29.9767 27.0563 29.7525 27.5629C29.5284 28.0696 29.2014 28.5242 28.7923 28.8979C28.3832 29.2716 27.9009 29.5562 27.3761 29.7337C26.8512 29.9113 26.2952 29.9778 25.7433 29.9291C25.1913 29.8805 24.6555 29.7177 24.1698 29.4511C23.6841 29.1845 23.2591 28.8199 22.9217 28.3804C22.5843 27.9409 22.3419 27.4361 22.2098 26.898C22.0778 26.3599 22.0589 25.8002 22.1545 25.2544L17.2545 23.0344C16.8801 23.499 16.4064 23.8739 15.8683 24.1317C15.3302 24.3895 14.7412 24.5237 14.1445 24.5244C13.0837 24.5244 12.0662 24.103 11.3161 23.3529C10.566 22.6027 10.1445 21.5853 10.1445 20.5244C10.1445 19.4636 10.566 18.4462 11.3161 17.696C12.0662 16.9459 13.0837 16.5244 14.1445 16.5244C14.693 16.5262 15.2352 16.6407 15.7376 16.8609C16.2399 17.0811 16.6916 17.4023 17.0645 17.8044L22.2245 15.0044C22.1343 14.6789 22.0905 14.3422 22.0945 14.0044C22.0935 13.0738 22.417 12.1719 23.0093 11.4541C23.6016 10.7363 24.4257 10.2475 25.3396 10.0719C26.2535 9.89625 27.2001 10.0448 28.0163 10.4919C28.8325 10.939 29.4672 11.6567 29.8112 12.5215C30.1552 13.3862 30.1869 14.3438 29.9009 15.2294C29.6149 16.115 29.029 16.8731 28.2442 17.3733C27.4594 17.8734 26.5247 18.0842 25.6012 17.9695C24.6777 17.8547 23.823 17.4215 23.1845 16.7444L18.0245 19.5544C18.1145 19.8804 18.1585 20.2164 18.1545 20.5544C18.1551 20.7891 18.135 21.0233 18.0945 21.2544L22.9945 23.4744C23.5127 22.8231 24.2212 22.3497 25.0212 22.1201C25.8212 21.8905 26.6729 21.9162 27.4576 22.1935C28.2423 22.4709 28.921 22.9861 29.399 23.6674C29.8771 24.3487 30.1307 25.1622 30.1245 25.9944L30.0945 25.9544Z"
      fill="#057A55"
    />
  </svg>
);
export default WindowIcon;
