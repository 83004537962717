export const mockDataList = [
  {
    id: 1,
    auto_renewal: true,
  },
  {
    id: 2,
    status: 0,
    payment_period: '3 tháng',
    auto_renewal: false,
  },
  {
    id: 3,
    status: 2,
    payment_period: '3 tháng',
    auto_renewal: false,
  },
  {
    id: 4,
    auto_renewal: true,
  },
  {
    id: 5,
    auto_renewal: true,
  },
  {
    id: 6,
    auto_renewal: true,
  },
  {
    id: 7,
    auto_renewal: true,
  },
  {
    id: 8,
    auto_renewal: true,
  },
  {
    id: 9,
    auto_renewal: true,
  },
  {
    id: 10,
    auto_renewal: true,
  },
];
export const TABS_STATUS_ITEM = ['all', '1', '2', '0'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  0: 'Đã hủy',
  1: 'Đang hoạt động',
  2: 'Đang chờ',
};
export const DOMAIN_STATUS_COLOR = {
  0: 'red03',
  1: 'green03',
  2: 'yellow03',
};
export const DOMAIN_STATUS = {
  0: 'Đã hủy',
  1: 'Đang hoạt động',
  2: 'Đang chờ',
};
export const durations = [
  { value: 1, label: '1 năm - 30,000 đ' },
  { value: 2, label: '2 năm - 60,000 đ' },
  { value: 3, label: '3 năm - 90,000 đ' },
  { value: 4, label: '4 năm - 120,000 đ' },
  { value: 5, label: '5 năm - 150,000 đ' },
  { value: 6, label: '6 năm - 180,000 đ' },
  { value: 7, label: '7 năm - 210,000 đ' },
  { value: 8, label: '8 năm - 240,000 đ' },
  { value: 9, label: '9 năm - 270,000 đ' },
  { value: 10, label: '10 năm - 300,000 đ' },
];
