import { IRouteProps } from '_common/interface/router';
import HostingPage from '.';
import AddHostingPage from './pages/Add';
import DetailHostingPage from './pages/Detail';
import { SERVICE_HOSTING_URL } from './url';

export const HostingRouter: IRouteProps[] = [
  {
    key: SERVICE_HOSTING_URL.list.key,
    path: SERVICE_HOSTING_URL.list.shortenUrl,
    component: <HostingPage />,
  },
  {
    key: SERVICE_HOSTING_URL.create.key,
    path: SERVICE_HOSTING_URL.create.shortenUrl,
    component: <AddHostingPage />,
  },
  {
    key: SERVICE_HOSTING_URL.detail.key,
    path: SERVICE_HOSTING_URL.detail.shortenUrl,
    component: <DetailHostingPage />,
  },
];
