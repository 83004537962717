export enum INVOICE_STATUS {
  UNPAID = 'Unpaid',
  PROCESSING = 'Processing',
  PAID = 'Paid',
  FAILED = 'Failed',
}
export enum TRANSACTION_STATUS {
  ALL = 'All',
  PROCESSING = 'Processing',
  PAID = 'Paid',
  FAILED = 'Failed',
  ERROR = 'Error',
}
export enum TRANSACTION_TYPE {
  PAY = 'Pay',
  EXTEND = 'Extend',
  DEPOSIT = 'Deposit',
  WITHDRAWAL = 'Withdrawal',
}
export enum PAYMENT_METHOD_TYPE {
  BANK = 'bank',
  E_WALLET = 'e-wallet',
  WALLET = 'wallet',
}
export const TRANSACTION_TYPE_TEXT = {
  [TRANSACTION_TYPE.PAY]: 'Thanh toán',
  [TRANSACTION_TYPE.EXTEND]: 'Gia hạn',
  [TRANSACTION_TYPE.DEPOSIT]: 'Nạp tiền',
  [TRANSACTION_TYPE.WITHDRAWAL]: 'Rút tiền',
};
export const TRANSACTION_STATUS_COLOR = {
  [TRANSACTION_STATUS.PROCESSING]: 'blue03',
  [TRANSACTION_STATUS.PAID]: 'green03',
  [TRANSACTION_STATUS.FAILED]: 'red03',
  [TRANSACTION_STATUS.ERROR]: 'gray03',
};
export const TRANSACTION_STATUS_TEXT = {
  [TRANSACTION_STATUS.PROCESSING]: 'Đang xử lý',
  [TRANSACTION_STATUS.PAID]: 'Hoàn thành',
  [TRANSACTION_STATUS.FAILED]: 'Thất bại',
  [TRANSACTION_STATUS.ERROR]: 'Báo lỗi',
};
export const PAYMENT_METHOD_TEXT = {
  [PAYMENT_METHOD_TYPE.BANK]: 'Chuyển khoản',
  [PAYMENT_METHOD_TYPE.E_WALLET]: 'Ví điện tử',
  [PAYMENT_METHOD_TYPE.WALLET]: 'Ví ZingCloud',
};
