import { promoData } from 'pages/Affiliates/PromoCode/containers/Form';
import { Button, Modal } from 'tera-dls';

interface IProps {
  open: boolean;
  onCancel: () => void;
  value?: any;
}

const CommissionDetail = (props: IProps) => {
  const { open, onCancel } = props;

  return (
    <Modal
      open={open}
      title={'Danh sách products'}
      closeIcon={false}
      okText="Đồng ý"
      cancelText="Hủy"
      width={500}
      maskClosable={false}
      footer={<Button onClick={onCancel}>Đóng</Button>}
    >
      <ul className="max-h-[600px] overflow-auto">
        {Object.entries(promoData)?.map(([key, value]) => (
          <li className="p-2.5 border-b" key={key}>
            {value}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default CommissionDetail;
