import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { forwardRef, useImperativeHandle } from 'react';
import { InputNumber, formatCurrency } from 'tera-dls';
import { MAX_AMOUNT, MIN_AMOUNT } from '.';
import TagInfo from './TagInfo';

function Amount(_, ref) {
  const {
    authStore: { user },
    transactionStore: { amount, account, setAmount, nextStep },
  } = useStores();

  const lengthAmount = String(amount)?.length;

  useImperativeHandle(ref, () => {
    return {
      nextStep: () => nextStep(),
    };
  });

  return (
    <div className="flex flex-col gap-y-4">
      <p>Đến</p>
      <TagInfo
        cardName={account?.cardName}
        name={account?.cardholder}
        number={account?.number}
      />
      <p>Bạn muốn rút bao nhiêu?</p>
      <div className="h-[300px] flex items-center justify-center flex-col">
        <div className="flex items-center gap-x-2.5">
          <InputNumber
            min={0}
            max={user.account?.wallet?.available_balance}
            value={amount}
            className="border-none focus:ring-0 p-0 text-5xl"
            style={{
              width: amount && lengthAmount > 0 ? 35 * lengthAmount : 35,
            }}
            allowStepHandler={false}
            onChange={(value: number) => setAmount(value)}
            formatter={(value) =>
              String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            placeholder=""
          />
          <span>đ</span>
        </div>
        <p className="mt-2.5">
          Còn lại: {formatCurrency(user.account?.wallet?.available_balance)}
        </p>
        {amount < MIN_AMOUNT && (
          <p className="mt-2.5 text-red-500">
            Tối thiếu: {formatCurrency(MIN_AMOUNT)}
          </p>
        )}
        {amount > MAX_AMOUNT && (
          <p className="mt-2.5 text-red-500">
            Tối đa: {formatCurrency(MAX_AMOUNT)}
          </p>
        )}
      </div>
    </div>
  );
}

export default observer(forwardRef(Amount));
