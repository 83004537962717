import { useMutation, useQueryClient } from '@tanstack/react-query';
import Text from '_common/component/Text';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import FormTera from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import UserAccountAPI from 'pages/Account/_common/apis';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Row, Spin, notification } from 'tera-dls';
import CardImageUpload from './CardImageUpload';
import CardImageInfor from './CardImageInfor';
import CardHint from './CardHint';

interface IFormCreateUpdateProps {
  dataDetail: any;
}

const FormCreateUpdate = ({
  dataDetail,
}: IFormCreateUpdateProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      front_card_url: dataDetail?.kyc?.front_card_url,
      back_card_url: dataDetail?.kyc?.back_card_url,
      face_image_url: dataDetail?.kyc?.face_image_url,
      id_number: dataDetail?.kyc?.id_number,
      full_name: dataDetail?.kyc?.full_name,
      birthday: dataDetail?.kyc?.birthday
        ? moment(dataDetail?.kyc?.birthday)
        : null,
      gender: dataDetail?.kyc?.gender,
      residence: dataDetail?.kyc?.residence,
      ward_code: dataDetail?.kyc?.ward_code,
      district_code: dataDetail?.kyc?.district_code,
      province_code: dataDetail?.kyc?.province_code,
      date_of_expiry: dataDetail?.kyc?.date_of_expiry
        ? moment(dataDetail?.kyc?.date_of_expiry)
        : null,
      provided_by: dataDetail?.kyc?.provided_by,
      provided_at: dataDetail?.kyc?.provided_at
        ? moment(dataDetail?.kyc?.provided_at)
        : null,
    },
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!_.isEmpty(dataDetail?.kyc))
      form.reset({
        ...form.getValues(),
        front_card_url: dataDetail?.kyc?.front_card_url,
        back_card_url: dataDetail?.kyc?.back_card_url,
        face_image_url: dataDetail?.kyc?.face_image_url,
        id_number: dataDetail?.kyc?.id_number,
        full_name: dataDetail?.kyc?.full_name,
        birthday: dataDetail?.kyc?.birthday
          ? moment(dataDetail?.kyc?.birthday)
          : null,
        gender: dataDetail?.kyc?.gender,
        residence: dataDetail?.kyc?.residence,
        ward_code: dataDetail?.kyc?.ward_code,
        district_code: dataDetail?.kyc?.district_code,
        province_code: dataDetail?.kyc?.province_code,
        date_of_expiry: dataDetail?.kyc?.date_of_expiry
          ? moment(dataDetail?.kyc?.date_of_expiry)
          : null,
        provided_by: dataDetail?.kyc?.provided_by,
        provided_at: dataDetail?.kyc?.provided_at
          ? moment(dataDetail?.kyc?.provided_at)
          : null,
      });
  }, [dataDetail?.kyc]);

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (params) => UserAccountAPI.updateKYC({ id: dataDetail?.id, params }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          form.reset({});
          queryClient.invalidateQueries(['get-profile']);
          queryClient.invalidateQueries(['get-user-account-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleSubmitForm = async (values: any) => {
    if (_.isEmpty(values.front_card_url))
      return notification.error({
        message: 'Vui lòng chọn ảnh CMT/CCCD mặt trước',
      });
    if (_.isEmpty(values.back_card_url))
      return notification.error({
        message: 'Vui lòng chọn ảnh CMT/CCCD mặt sau',
      });
    if (_.isEmpty(values.face_image_url))
      return notification.error({
        message: 'Vui lòng chọn ảnh khuôn mặt',
      });

    mutateUpdate({
      ...values,
      birthday: values?.birthday
        ? moment(values?.birthday).format(DATE_BACKEND_FORMAT)
        : null,
      date_of_expiry: values?.date_of_expiry
        ? moment(values?.date_of_expiry).format(DATE_BACKEND_FORMAT)
        : null,
      provided_at: values?.provided_at
        ? moment(values?.provided_at).format(DATE_BACKEND_FORMAT)
        : null,
    });
  };

  return (
    <Spin spinning={isLoadingUpdate}>
      <FormTera form={form} className="flex flex-col gap-8">
        <Col>
          <Text className="text-2xl font-semibold">
            Quý khách vui lòng cung cấp thông tin chủ thể tên miền
          </Text>
          <Text className="text-base">
            <i>* Hình ảnh CMT/CCCD của Quý khách được bảo mật</i>
          </Text>
        </Col>
        <Row className="grid grid-cols-2 gap-8">
          <CardImageUpload form={form} />
          <CardImageInfor />
        </Row>
        <Row className="flex flex-col items-center">
          <Col className="flex flex-col gap-4">
            <Text className="text-base font-semibold">
              Lưu ý khi tải lên hình ảnh CCCD của bạn
            </Text>
            <Row className="flex gap-12">
              <CardHint text="Hợp lệ" />
              <CardHint text="Không bị cắt" isCut />
              <CardHint text="Không mờ" isBlur />
              <CardHint text="Không bị lóa" isGlare />
            </Row>
            <Text className="text-base text-red-500">
              Vui lòng sử dụng thiết bị có camera/webcam để tiến hành xác thực
              (KYC)
            </Text>
            <Button
              className="w-full mt-6"
              onClick={form.handleSubmit(handleSubmitForm)}
            >
              Đăng ký KYC
            </Button>
          </Col>
        </Row>
      </FormTera>
    </Spin>
  );
};

export default FormCreateUpdate;
