import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { EffectCards, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ModalCreate from '../../containers/ModalCreate';
import Card from './Card';

import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import './styles.css';
import { notification } from 'tera-dls';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';
import useConfirm from '_common/hooks/useConfirm';

function Slider({ data }) {
  const [modal, setModal] = useState({
    open: false,
    id: null,
  });
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const deleteCard = useMutation(
    (id: number) => PaymentMethodAPI.delete({ id }),
    {
      onSuccess(res) {
        if (res?.code === 200) {
          queryClient.invalidateQueries([
            'get-finance-account-payment-method-list',
          ]);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleDeleteBankCard = (card) => {
    confirm.warning({
      title: 'Xác nhận xóa ngân hàng',
      content: (
        <>
          <p>Bạn có chắc muốn xóa thẻ ngân hàng</p>
          <p>
            <b className="break-word">{card?.type?.name}</b> này không?
          </p>
        </>
      ),
      onOk: async () => {
        await deleteCard.mutateAsync(card?.id);
      },
    });
  };

  return (
    <div className="tera-bank-card overflow-hidden py-10">
      <Swiper
        effect="cards"
        grabCursor={true}
        navigation={true}
        modules={[Navigation, EffectCards]}
        className="mySwiper"
      >
        {data?.map((card, index) => (
          <SwiperSlide key={index}>
            <Card
              image={card?.type?.image_bank}
              fullName={card?.account_name}
              bankName={card?.type?.name}
              description={card?.type?.description}
              cardNumber={card?.account_number}
              onUpdate={() => setModal({ open: true, id: card?.id })}
              onDelete={() => handleDeleteBankCard(card)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {modal?.open && (
        <ModalCreate
          id={modal?.id}
          onClose={() => setModal({ open: false, id: null })}
          open={modal?.open}
          onRefetch={() =>
            queryClient.invalidateQueries(['get-card-bank-list'])
          }
        />
      )}
    </div>
  );
}

export default Slider;
