import Text from '_common/component/Text';
import { DATE_FORMAT, GENDER, REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import { Fragment, useMemo } from 'react';
import { Col, EyeOutlined, EyeSlashOutlined, Row } from 'tera-dls';

const CountryCodeOptions = [
  {
    value: '+84',
    label: '+84',
    labelDisplay: <Text>Vietnam (Việt Nam) +84</Text>,
  },
];

interface IFormCreateUpdateProps {
  isUpdate?: boolean;
}

const FormCreateUpdate = ({
  isUpdate,
}: IFormCreateUpdateProps): JSX.Element => {
  const GenderOptions = useMemo(
    () =>
      Object.entries(GENDER).map(([key, value]) => ({
        value: key,
        label: value,
      })),
    [],
  );

  return (
    <Fragment>
      <Row className="grid grid-cols-3">
        {/* Username */}
        <FormTeraItem
          label="Tài khoản"
          name="username"
          className="w-full mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
              pattern: {
                value: new RegExp(REGEX.USERNAME),
                message: messageValidate.username,
              },
            },
          ]}
        >
          <Input disabled={isUpdate} autoComplete="off" />
        </FormTeraItem>
        {/* Email */}
        <FormTeraItem
          label="Email"
          name="email"
          className="w-full mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
              pattern: {
                value: new RegExp(REGEX.EMAIL),
                message: messageValidate.email,
              },
            },
          ]}
        >
          <Input />
        </FormTeraItem>
        {/* Country */}
        <FormTeraItem
          label="Quốc gia"
          name="country"
          className="w-full mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <Input />
        </FormTeraItem>
      </Row>
      <Row className="grid grid-cols-3">
        {/* Full name */}
        <FormTeraItem
          label="Họ và tên đệm"
          name="last_middle_name"
          className="w-full mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <Input />
        </FormTeraItem>
        {/* Birthday */}
        <Col className="flex flex-col">
          <FormTeraItem
            label="Ngày sinh"
            name="birthday"
            className="w-full mb-0"
          >
            <DatePicker format={DATE_FORMAT} />
          </FormTeraItem>
          <i className="text-[12px] mt-2">Định dạng đúng dd/mm/yyyy</i>
        </Col>
        {/* Province */}
        <FormTeraItem
          label="Tỉnh/Thành"
          name="province"
          className="w-full mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <Input />
        </FormTeraItem>
      </Row>
      <Row className="grid grid-cols-3">
        {/* Name */}
        <FormTeraItem
          label="Tên"
          name="first_name"
          className="w-full mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <Input />
        </FormTeraItem>
        {/* Phone */}
        <FormTeraItem
          label="Điện thoại"
          name="phone"
          className="w-full mb-0"
          childrenClassName="[&>*:first-child>div]:pl-0"
          rules={[
            {
              required: messageValidate.emptyText,
              pattern: {
                value: new RegExp(REGEX.PHONE_NUMBER),
                message: messageValidate.phone,
              },
            },
          ]}
        >
          <Input
            maxLength={10}
            className="pl-[4rem]"
            prefix={
              <FormTeraItem name="country_code" className="mb-0">
                <Select
                  options={CountryCodeOptions}
                  placeholder=""
                  placement="bottom-start"
                  className="w-[55px] [&>*:first-child]:h-[32px] [&>*:first-child]:bg-gray-100 [&>*:first-child]:px-2 [&>*:nth-child(2)]:end-1"
                  dropdownClassName="!w-[250px]"
                />
              </FormTeraItem>
            }
          />
        </FormTeraItem>
        {/* District */}
        <FormTeraItem
          label="Quận/Huyện"
          name="district"
          className="w-full mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <Input />
        </FormTeraItem>
      </Row>
      <Row className="grid grid-cols-3">
        {/* Nickname */}
        <FormTeraItem label="Danh xưng" name="gender" className="w-full mb-0">
          <Select options={GenderOptions} />
        </FormTeraItem>
        {/* Address */}
        <FormTeraItem
          label="Địa chỉ"
          name="address"
          className="w-full mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <Input />
        </FormTeraItem>
        {/* Ward */}
        <FormTeraItem
          label="Phường/Xã"
          name="ward"
          className="w-full mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <Input />
        </FormTeraItem>
      </Row>
      <Row className="grid grid-cols-3">
        {/* ID card/Passport number */}
        <FormTeraItem
          label="Số CCCD/Passport"
          name="citizen_id"
          className="w-full mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
              pattern: {
                value: new RegExp(REGEX.IDENTIFY_NUMBER),
                message: messageValidate.id_number,
              },
            },
          ]}
        >
          <Input />
        </FormTeraItem>
        {!isUpdate && (
          <Fragment>
            {/* Password */}
            <FormTeraItem
              label="Mật khẩu"
              name="password"
              className="w-full mb-0"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.PASSWORD),
                    message: messageValidate.password,
                  },
                  minLength: {
                    value: 8,
                    message: messageValidate.min_length_password,
                  },
                  maxLength: {
                    value: 16,
                    message: messageValidate.max_length_password,
                  },
                },
              ]}
            >
              <InputPassword
                iconRender={(visible) =>
                  visible ? (
                    <EyeSlashOutlined className="size-5" />
                  ) : (
                    <EyeOutlined className="size-5" />
                  )
                }
              />
            </FormTeraItem>
            {/* Confirm-Password */}
            <FormTeraItem
              label="Nhập lại mật khẩu"
              name="confirm_password"
              className="w-full mb-0"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.PASSWORD),
                    message: messageValidate.password,
                  },
                  minLength: {
                    value: 8,
                    message: messageValidate.min_length_password,
                  },
                  maxLength: {
                    value: 16,
                    message: messageValidate.max_length_password,
                  },
                  validate: {
                    existed: (value: string, values: any) => {
                      const password = values?.password.trim();

                      if (value?.trim() !== password) {
                        return messageValidate.confirm_password;
                      }
                    },
                  },
                },
              ]}
            >
              <InputPassword
                iconRender={(visible) =>
                  visible ? (
                    <EyeSlashOutlined className="size-5" />
                  ) : (
                    <EyeOutlined className="size-5" />
                  )
                }
              />
            </FormTeraItem>
          </Fragment>
        )}
      </Row>
    </Fragment>
  );
};

export default FormCreateUpdate;
