export const INVOICE_URL = {
  page: {
    key: '',
    path: '/invoice/invoice',
    shortenUrl: 'invoice',
  },
  detail: {
    key: '',
    path: '/invoice/invoice/detail',
    shortenUrl: 'invoice/detail/:id',
  },
};
