import { IRouteProps } from '_common/interface/router';
import UnAuthEmailSecurityPage from '.';
import { UNAUTH_SERVICE_EMAIL_SECURITY_URL } from './url';

export const UnAuthEmailSecurityRouter: IRouteProps[] = [
  {
    key: UNAUTH_SERVICE_EMAIL_SECURITY_URL.index.key,
    path: UNAUTH_SERVICE_EMAIL_SECURITY_URL.index.shortenUrl,
    component: <UnAuthEmailSecurityPage />,
  },
];
