import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb, Spin } from 'tera-dls';
import DomainAPI from '../../apis';
import { SERVICE_DOMAIN_URL } from '../../url';
import DetailContent from './DetailContent';
import DetailHeader from './DetailHeader';

const DetailPage = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách tên miền
        </span>
      ),
      onClick: () => navigate(SERVICE_DOMAIN_URL.list.path),
    },
    {
      title: 'Chi tiết tên miền',
    },
  ];

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-domain-detail', id],
    () => DomainAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetchDetail();
  }, []);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <Spin spinning={id && isLoadingDetail}>
        {isErrorDetail || _.isEmpty(dataDetail) ? (
          <NoData />
        ) : (
          <div className="p-2 flex flex-col gap-4">
            <DetailHeader dataDetail={dataDetail} />
            <DetailContent dataDetail={dataDetail} />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default DetailPage;
