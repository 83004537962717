import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import TableTera from '_common/dof/TableTera';
import CommissionDetail from 'pages/Affiliates/Commission/containers/Detail';
import CommissionFilter from 'pages/Affiliates/Commission/containers/Filter';
import Searching from 'pages/Affiliates/Commission/containers/Searching';
import { promoData } from 'pages/Affiliates/PromoCode/containers/Form';
import { useState } from 'react';

const CommissionRule = (): JSX.Element => {
  const [params, setParams] = useState<any>();
  const [openFilter, setOpenFilter] = useState(false);
  const [openDetail, setOpenDetail] = useState<any>({ open: false });

  const handleSearch = ({ keyword }) => {
    setParams((prev = {}) => ({ ...prev, keyword, page: 1 }));
  };

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'service_name',
      width: 100,
      render: (val) => <span className="text-green-500">{val}</span>,
    },
    {
      title: 'Hoa hồng',
      dataIndex: 'tip',
      width: 150,
      render: (val) => <span>{val}%</span>,
    },
    {
      title: 'Products',
      dataIndex: 'products',
      width: 150,
      render: (val, record) => (
        <div className="flex">
          <div className="line-clamp-1 flex-1">{val.join(', ')}</div>
          <span
            className="text-blue-500 cursor-pointer"
            onClick={() => setOpenDetail({ open: true, value: record?.id })}
          >
            +{val?.length}
          </span>
        </div>
      ),
    },
  ];

  const handleFilter = (values: any) => {
    setParams((prev = {}) => ({ ...prev, ...values, page: 1 }));
  };

  return (
    <div>
      <p>Chính sách hoa hồng</p>
      <HeaderViewListV2
        title={''}
        onClickFilter={() => setOpenFilter(true)}
        actionLeftRender={<Searching onSearch={handleSearch} />}
      >
        <TableTera
          rowKey="id"
          columns={columns}
          data={[
            {
              id: 1,
              service_name: 'Dịch vụ Hosting, VPS',
              tip: 40,
              products: Object.values(promoData),
            },
          ]}
          pagination={{}}
        />
      </HeaderViewListV2>
      {openFilter && (
        <CommissionFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={params}
        />
      )}
      {openDetail?.open && (
        <CommissionDetail
          open={openDetail?.open}
          onCancel={() => setOpenDetail({ open: false })}
          value={openDetail?.value}
        />
      )}
    </div>
  );
};

export default CommissionRule;
