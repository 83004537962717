import CardDetail from '_common/component/CardDetail';
import HeaderViewList from '_common/component/HeaderViewList';
import Text from '_common/component/Text';
import { useStores } from '_common/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Col,
  Tabs,
  formatCurrency,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import EstimateTab from './tabs/Estimate';
import InvoiceTab from './tabs/Invoice';
import { observer } from 'mobx-react-lite';

const InvoicePage = (): JSX.Element => {
  const {
    authStore: { user },
  } = useStores();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateQueryParams({ subTab: key });
  };

  const tabItems = [
    {
      key: 'invoice',
      label: 'Hóa đơn',
    },
    {
      key: 'estimates',
      label: 'Estimates',
    },
  ];

  const tabData = {
    ['invoice']: <InvoiceTab />,
    ['estimates']: <EstimateTab />,
  };

  return (
    <Col className="tera-page">
      <HeaderViewList
        title="Danh sách hóa đơn"
        middleContent={
          <Col className="p-4">
            <CardDetail className="flex flex-col gap-2">
              <Text className="text-green-500 text-2xl">
                {formatCurrency(user?.account?.wallet?.available_balance)}
              </Text>
              <Text className="text-gray-500">Số dư</Text>
            </CardDetail>
          </Col>
        }
      >
        <Col className="p-4 flex flex-col gap-2">
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.subTab || tabItems[0].key}
            className="mb-0 pt-0 bg-white rounded-t-md"
          />
          {tabData[queryParams?.subTab || tabItems[0].key]}
        </Col>
      </HeaderViewList>
    </Col>
  );
};

export default observer(InvoicePage);
