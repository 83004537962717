import { useStores } from '_common/hooks';
import PageError from 'styles/images/pages/login/page-error.png';
import { ArrowLongLeftSolid, Button } from 'tera-dls';

interface PageNotPermissionProps {
  onClickLogin?: () => void;
}

function PageNotPermission({ onClickLogin }: PageNotPermissionProps) {
  const {
    authStore: { auth_url },
  } = useStores();

  return (
    <div className="w-screen h-screen bg-[#1E429F] bg-opacity-[68%] grid grid-cols-2">
      <div className="flex">
        <img alt="page-error" src={PageError} className="m-auto" />
      </div>
      <div className="flex flex-col items-center justify-center">
        <span className="text-[180px] leading-[180px] font-semibold text-white">
          403
        </span>
        <span className="uppercase font-bold text-4xl text-white mb-20">
          FORBIDDEN
        </span>
        <Button
          className="rounded-full border border-white bg-white/10"
          onClick={() => {
            if (onClickLogin) {
              onClickLogin();
              return;
            }
            window.localStorage.clear();
            window.open(`${auth_url}`, '_self');
          }}
        >
          <div className="flex justify-center items-center gap-x-2.5">
            <ArrowLongLeftSolid className="size-5 text-white" />
            <span>Đăng nhập</span>
          </div>
        </Button>
      </div>
    </div>
  );
}

export default PageNotPermission;
