import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Col,
  PaginationProps,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import TransactionAPI from './apis';
import Filter from './components/Filter';
import Search from './components/Search';
import Table from './components/Table';
import { INVOICE_TRANSACTION_URL } from './url';

const ListPage = (): JSX.Element => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${INVOICE_TRANSACTION_URL.list.path}${paramString}`);
  };

  const handleSearch = (values: any) => {
    handleUpdateQueryParams({ keyword: values?.keyword, page: 1 });
  };

  const handleFilter = (values: any) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const {
    data: dataList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-finance-transaction-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return TransactionAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  return (
    <Col className="tera-page">
      <HeaderViewList
        title="Danh sách giao dịch"
        onClickFilter={() => setIsOpenFilter(true)}
        actionLeftRender={<Search onSearch={handleSearch} />}
        filterCount={{
          filter: queryParams,
          params: [
            'transaction_type',
            'payment_method_type',
            'status',
            'created_at',
          ],
        }}
      >
        <Table
          rowKey={'id'}
          data={dataList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: dataList?.total || 0,
            current: dataList?.current_page,
            pageSize: dataList?.per_page || 10,
            to: dataList?.to,
            from: dataList?.from || 10,
          }}
        />
      </HeaderViewList>
      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </Col>
  );
};

export default ListPage;
