import { extendTailwindMerge } from 'tailwind-merge';

const customTwMerge = extendTailwindMerge({
  classGroups: {
    rounded: ['rounded-xsm'],
    shadow: ['shadow-xsm', 'shadow-smd'],
    'font-size': ['text-xxs'],
  },
});
export default customTwMerge;
