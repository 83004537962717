import { messageWarning } from '_common/constants/message';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import { Modal, Spin } from 'tera-dls';

interface IModalTransferMultipleDomainProps {
  open: boolean;
  close: () => void;
}

const ModalTransferMultipleDomain = ({
  open,
  close,
}: IModalTransferMultipleDomainProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { transfer_domain: '' },
  });
  const { isDirty } = form.formState;
  const confirm = useConfirm();

  const handleCloseConfirm = () => {
    isDirty
      ? confirm.warning({
          title: 'Thoát bản ghi',
          content: (
            <>
              <p>{messageWarning.WARNING_EXIT_1}</p>
              <p>{messageWarning.WARNING_EXIT_2}</p>
            </>
          ),
          onOk: () => close(),
        })
      : close();
  };

  return (
    <Modal
      title="Transfer nhiều tên miền"
      open={open}
      centered={true}
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={close}
      onCancel={handleCloseConfirm}
      contentClassName="md:w-[500px] h-full"
      bodyClassName="!py-4"
    >
      <Spin spinning={false}>
        <div className="flex flex-col gap-2">
          <span>
            Điền danh sách tên miền bạn muốn chuyển về chúng tôi, mỗi tên miền
            và mã AuthCode được ngăn bởi dấu ":".
          </span>
          <FormTera form={form}>
            <FormTeraItem name="transfer_domain" className="mb-0">
              <TextArea
                placeholder="VD: example.com:AuthCode"
                className="h-[200px] resize-none"
              />
            </FormTeraItem>
          </FormTera>
        </div>
      </Spin>
    </Modal>
  );
};

export default ModalTransferMultipleDomain;
