import Filter from '_common/component/Filter';
import DatePicker from '_common/dof/Control/DatePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValues?: any;
}

const HistoryFilter = (props: IProps) => {
  const { open, onClose, onFilter, initialValues } = props;

  const form = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    initialValues && form.setValue('time', initialValues?.time);
  }, [initialValues]);

  const handleSubmitForm = (value): void => {
    onFilter(value);
    onClose();
  };

  const handleReset = (): void => {
    form.reset();
    onFilter({
      time: null,
    });
    onClose();
  };

  return (
    <>
      <Filter
        open={open}
        onClose={onClose}
        onCancel={onClose}
        onFilter={() => form.handleSubmit(handleSubmitForm)()}
      >
        <FormTera form={form}>
          <FormTeraItem label="Thời gian" name="time">
            <DatePicker />
          </FormTeraItem>
          <a
            className="text-red-500 text-sm font-normal cursor-pointer"
            onClick={() => handleReset()}
          >
            Hủy bộ lọc
          </a>
        </FormTera>
      </Filter>
    </>
  );
};

export default HistoryFilter;
