export enum DOMAIN_STATUS {
  ACTIVE = 'Active',
  NEW = 'New',
  SUSPENDED = 'Suspended',
}
export const TABS_STATUS_ITEM = ['all', 'Active', 'New', 'Suspended'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  Active: 'Đang hoạt động',
  New: 'Đang chờ',
  Suspended: 'Dịch vụ đã hùy',
};
export const TABLE_STATUS_COLOR = {
  Active: 'green03',
  New: 'yellow03',
  Suspended: 'red03',
};
export const TABLE_STATUS_TEXT = {
  Active: 'Đang hoạt động',
  New: 'Đang chờ',
  Suspended: 'Dịch vụ đã hùy',
};
export const DOMAIN_PERIOD_TEXT = {
  1: '1 năm',
  2: '2 năm',
  3: '3 năm',
  4: '4 năm',
  5: '5 năm',
  6: '6 năm',
  7: '7 năm',
  8: '8 năm',
  9: '9 năm',
  10: '10 năm',
};
export const DOMAIN_AUTORENEW_COLOR = {
  0: 'yellow03',
  1: 'green03',
};
export const DOMAIN_AUTORENEW_TEXT = {
  0: 'Tắt',
  1: 'Bật',
};
export const DOMAIN_PROTECTION_TEXT = {
  0: 'Không',
  1: 'Có',
};
export const DOMAIN_AVAILABLE_COLOR = {
  true: 'green03',
  false: 'red03',
};
export const DOMAIN_AVAILABLE_TEXT = {
  true: 'ĐĂNG KÝ MỚI',
  false: 'CHUYỂN VỀ ZINGSERVER',
};
