import CardDetail from '_common/component/CardDetail';
import IconText from '_common/component/IconText';
import ModalSummary from '_common/component/ModalSummary';
import ZingIcon from '_common/component/ZingIcon';
import { PRICE_PERIOD_TEXT } from '_common/constants/serviceManagement';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Button, Col, Tooltip, formatCurrency } from 'tera-dls';

interface IFromSummaryProps {
  form: UseFormReturn<any>;
  handleSubmitForm?: (values: any) => void;
}

const FormSummary = ({
  form,
  handleSubmitForm,
}: IFromSummaryProps): JSX.Element => {
  const [isOpenModalSummary, setIsOpenModalSummary] = useState<boolean>(false);

  const price = Number(
    useMemo(
      () => form.watch('product')?.prices?.[form.watch('period')],
      [form.getValues()],
    ),
  );
  const priceVAT = Number(
    useMemo(
      () => form.watch('product')?.prices[form.watch('period')] * 0.08,
      [form.getValues()],
    ),
  );
  const periodText = useMemo(
    () => PRICE_PERIOD_TEXT[form.watch('period')],
    [form.getValues()],
  );
  const PeriodOptions = useMemo(
    () =>
      Object.entries(PRICE_PERIOD_TEXT).map(([key, value]) => ({
        value: key,
        label: value,
      })),
    [form.getValues()],
  );

  const PaymentTodayTooltip = () => {
    return (
      <Col className="flex flex-col">
        <div className="border-b-[1px] pb-2 flex flex-col gap-2">
          Thanh toán hôm nay:
          <span className="font-[500]">{formatCurrency(price)}</span>
        </div>
        <Col className="pt-2 flex flex-col gap-2">
          Thanh toán theo định kỳ:
          <div className="flex gap-1">
            <span className="font-[500]">
              {formatCurrency(price + priceVAT)}
            </span>
            <span>/</span>
            <span>{periodText}</span>
          </div>
        </Col>
      </Col>
    );
  };

  return (
    <div className="mt-auto sticky -bottom-0 z-[1]">
      <CardDetail className="p-4 flex justify-between items-center">
        <div className="flex flex-col gap-2">
          <span className="text-[12px] text-gray-500">Chu kỳ thanh toán</span>
          <FormTeraItem
            name="period"
            className="mb-0"
            childrenClassName="flex flex-col gap-2"
          >
            <Select options={PeriodOptions} className="w-[300px]" />
            <div className="flex items-center gap-2">
              <span>Thuế VAT có áp dụng cho đơn hàng này</span>
              <IconText
                prefix={<ZingIcon.IconInformation />}
                className="text-blue-500 gap-1 cursor-pointer"
                onClick={() => setIsOpenModalSummary(true)}
              >
                Chi tiết
              </IconText>
            </div>
          </FormTeraItem>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="flex flex-col gap-2">
            <span className="text-[12px] text-gray-500">
              Thanh toán hôm nay
            </span>
            <IconText
              suffix={
                <Tooltip title={<PaymentTodayTooltip />} placement="top">
                  <ZingIcon.IconQuestionMark />
                </Tooltip>
              }
              className="text-blue-500 gap-1"
            >
              <span className="text-[20px] text-green-500">
                {formatCurrency(price + priceVAT)}
              </span>
            </IconText>
          </div>
        </div>
        <Button
          htmlType="button"
          className="flex-1 bg-blue-500"
          onClick={handleSubmitForm}
          disabled={_.isNil(
            form.watch('product')?.prices[form.watch('period')],
          )}
        >
          Đăng ký dịch vụ
        </Button>
      </CardDetail>
      {isOpenModalSummary && (
        <ModalSummary
          open={isOpenModalSummary}
          close={() => setIsOpenModalSummary(false)}
          form={form}
        />
      )}
    </div>
  );
};

export default FormSummary;
