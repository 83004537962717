import CardDetail from '_common/component/CardDetail';
import Text from '_common/component/Text';
import { messageValidate } from '_common/constants/message';
import { FormTeraItem } from '_common/dof/FormTera';
import RadioPaymentMethod from '_common/dof/Radio/RadioPaymentMethod';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { formatCurrency } from 'tera-dls';

const PaymentMethod = (): JSX.Element => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <CardDetail
      title="Chọn phương thức thanh toán"
      className="p-4 flex flex-col gap-4"
    >
      <FormTeraItem
        name="payment_method_id"
        className="flex-1 mb-0"
        rules={[{ required: messageValidate.emptySelect }]}
      >
        <RadioPaymentMethod isIncludeWallet />
      </FormTeraItem>
      <Text className="text-[13px] flex gap-1">
        <span className="text-[#6B7280]">Số dư ví</span>
        <span className="text-green-500 font-[500]">
          {formatCurrency(user?.account?.wallet?.available_balance)}
        </span>
      </Text>
    </CardDetail>
  );
};

export default observer(PaymentMethod);
