import { createContext } from 'react';
import { AuthStore } from './authStore';
import { ColumnSettingStore } from './columnSettingStore';
import { CommonStore } from './commonStore';
import { ConfirmStore } from './confirmStore';
import { IAuthStore } from './interface';
import { ShoppingCartStore } from './shoppingCartStore';
import { TransactionStore } from './transaction';

export class RootStore {
  authStore: IAuthStore;
  commonStore: CommonStore;
  confirmStore: ConfirmStore;
  columnSettingStore: ColumnSettingStore;
  transactionStore: TransactionStore;

  // Shopping Cart
  shoppingCartStore: ShoppingCartStore;

  constructor() {
    this.authStore = new AuthStore();
    this.commonStore = new CommonStore();
    this.confirmStore = new ConfirmStore();
    this.transactionStore = new TransactionStore();
    this.columnSettingStore = new ColumnSettingStore();

    // Shopping Cart
    this.shoppingCartStore = new ShoppingCartStore();
  }
}
export const rootStore = new RootStore();
export const rootStoreContext = createContext(rootStore);
