import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import PromoCodeDetail from 'pages/Affiliates/PromoCode/containers/Detail';
import PromoCodeFilter from 'pages/Affiliates/PromoCode/containers/Filter';
import Searching from 'pages/Affiliates/PromoCode/containers/Searching';
import { useState } from 'react';
import { DropdownItem } from 'tera-dls';

const Promo = (): JSX.Element => {
  const [params, setParams] = useState<any>();

  const [openFilter, setOpenFilter] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>();
  const confirm = useConfirm();
  const [openDetail, setOpenDetail] = useState<any>({ open: false });

  const handleSearch = ({ keyword }) => {
    setParams((prev = {}) => ({ ...prev, keyword, page: 1 }));
  };

  const handleDeleteAll = () => {
    confirm.warning({
      title: 'Xác nhận xóa các mục đã chọn',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xác nhận xóa</p>
          <p>các mục đã chọn này không?</p>
        </div>
      ),
      onOk: () => {
        1;
      },
    });
  };

  const handleDelete = (id: number, name: string) => {
    confirm.warning({
      title: 'Xác nhận xóa mã ửu đãi',
      content: (
        <div className="break-word">
          <p>Bạn có chắc xác nhận xóa mã ưu đãi</p>
          <p>
            <span className="font-bold">{name}</span> này không?
          </p>
        </div>
      ),
      onOk: () => {
        id;
      },
    });
  };

  const generateDropDownItems = (record: any): DropdownItem[] => {
    record;
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => {
          setOpenDetail({ open: true, value: 1 });
        },
      },
      {
        key: 5,
        label: <a className="text-red-500">Xóa</a>,
        onClick: () => handleDelete(1, 'MSRFDSFSNFSJK'),
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã ưu đãi',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Giảm giá',
      dataIndex: 'discount',
      width: 100,
    },
    {
      title: 'Hoa hồng',
      dataIndex: 'tip',
      width: 150,
      render: (val) => <span>{val}%</span>,
    },
    {
      title: 'Đã sử dụng',
      dataIndex: 'used',
      width: 150,
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customer',
      width: 150,
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expired_date',
      width: 150,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  const handleFilter = (values: any) => {
    setParams((prev = {}) => ({ ...prev, ...values, page: 1 }));
  };

  const optionItems = (): DropdownItem[] => {
    const result: DropdownItem[] = [
      {
        key: 5,
        label: <a className="text-red-500">Xóa</a>,
        onClick: () => handleDeleteAll(),
      },
    ];

    return result;
  };

  return (
    <div>
      <p>
        Với hệ thống cộng tác viên của chúng tôi, bạn có thể tự tạo mã khuyến
        mãi cho riêng mình và gửi để thu hút nhiều khách hàng hơn.
      </p>
      <HeaderViewListV2
        title={''}
        onClickFilter={() => setOpenFilter(true)}
        selectedNumber={selectedRowKeys?.length}
        optionItems={optionItems()}
        actionLeftRender={<Searching onSearch={handleSearch} />}
      >
        <TableTera
          rowKey="id"
          columns={columns}
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          data={[
            {
              id: 1,
              code: 'MGADWEDD9UL',
              discount: 2,
              tip: 38,
              used: 0,
              customer: 1,
              expired_date: moment().format(),
            },
            {
              id: 2,
              code: 'MGADWEDD9UL',
              discount: 2,
              tip: 38,
              used: 0,
              customer: 2,
              expired_date: moment().format(),
            },
          ]}
          pagination={{}}
        />
      </HeaderViewListV2>
      {openFilter && (
        <PromoCodeFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={params}
        />
      )}

      {openDetail?.open && (
        <PromoCodeDetail
          open={openDetail?.open}
          onCancel={() => setOpenDetail({ open: false })}
          value={openDetail?.value}
        />
      )}
    </div>
  );
};

export default Promo;
