import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const AffiliatesAPIEndpoint = `${endpoint}/portal/affiliates`;

const AffiliatesAPI = {
  join: async () =>
    await api.post(`${AffiliatesAPIEndpoint}/join`).then((res) => res.data),
};

export default AffiliatesAPI;
