import CardDetail from '_common/component/CardDetail';
import Text from '_common/component/Text';
import { DATE_FORMAT, GENDER, REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import { useMemo } from 'react';
import { Col } from 'tera-dls';

const CardImageInfor = (): JSX.Element => {
  const GenderOptions = useMemo(() => {
    delete GENDER.other;

    return Object.entries(GENDER).map(([key, value]) => ({
      value: key,
      label: value,
    }));
  }, []);

  return (
    <Col className="flex flex-col gap-8">
      <CardDetail className="p-4 flex flex-col gap-4">
        <Text className="text-base font-medium">Thông tin mặt trước</Text>
        <table cellPadding={6}>
          <tbody>
            <tr className="align-text-top">
              <td width={115}>
                Số CCCD <span className="text-red-500">*</span>
              </td>
              <td>
                <FormTeraItem
                  name="id_number"
                  className="mb-0"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.IDENTIFY_NUMBER),
                        message: messageValidate.id_number,
                      },
                    },
                  ]}
                >
                  <Input />
                </FormTeraItem>
              </td>
              <td width={115}>
                Họ và tên <span className="text-red-500">*</span>
              </td>
              <td>
                <FormTeraItem
                  name="full_name"
                  className="mb-0"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <Input />
                </FormTeraItem>
              </td>
            </tr>
            <tr className="align-text-top">
              <td width={115}>
                Ngày sinh <span className="text-red-500">*</span>
              </td>
              <td>
                <FormTeraItem
                  name="birthday"
                  className="mb-0"
                  rules={[{ required: messageValidate.emptySelect }]}
                >
                  <DatePicker format={DATE_FORMAT} />
                </FormTeraItem>
              </td>
              <td width={115}>
                Giới tính <span className="text-red-500">*</span>
              </td>
              <td>
                <FormTeraItem
                  name="gender"
                  className="mb-0"
                  rules={[{ required: messageValidate.emptySelect }]}
                >
                  <Select options={GenderOptions} />
                </FormTeraItem>
              </td>
            </tr>
            <tr className="align-text-top">
              <td width={115}>
                Nơi thường trú <span className="text-red-500">*</span>
              </td>
              <td colSpan={3}>
                <FormTeraItem
                  name="residence"
                  className="mb-0"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <Input />
                </FormTeraItem>
              </td>
            </tr>
            <tr className="align-text-top">
              <td width={115}>
                Mã phường <span className="text-red-500">*</span>
              </td>
              <td>
                <FormTeraItem
                  name="ward_code"
                  className="mb-0"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}`}
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                    className="pr-3"
                    allowStepHandler={false}
                  />
                </FormTeraItem>
              </td>
              <td width={115}>
                Mã quận <span className="text-red-500">*</span>
              </td>
              <td>
                <FormTeraItem
                  name="district_code"
                  className="mb-0"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}`}
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                    className="pr-3"
                    allowStepHandler={false}
                  />
                </FormTeraItem>
              </td>
            </tr>
            <tr className="align-text-top">
              <td width={115}>
                Mã tỉnh <span className="text-red-500">*</span>
              </td>
              <td>
                <FormTeraItem
                  name="province_code"
                  className="mb-0"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <InputNumber
                    formatter={(value) => `${value}`}
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                    className="pr-3"
                    allowStepHandler={false}
                  />
                </FormTeraItem>
              </td>
              <td width={115}>
                <Text>
                  Ngày hết hạn <span className="text-red-500">*</span>
                </Text>
              </td>
              <td>
                <FormTeraItem
                  name="date_of_expiry"
                  className="mb-0"
                  rules={[{ required: messageValidate.emptySelect }]}
                >
                  <DatePicker format={DATE_FORMAT} />
                </FormTeraItem>
              </td>
            </tr>
          </tbody>
        </table>
      </CardDetail>
      <CardDetail className="p-4 flex flex-col gap-4">
        <Text className="text-base font-medium">Thông tin mặt sau</Text>
        <table cellPadding={6}>
          <tbody>
            <tr className="align-text-top">
              <td width={115}>
                Cấp bởi <span className="text-red-500">*</span>
              </td>
              <td>
                <FormTeraItem
                  name="provided_by"
                  className="mb-0"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <Input />
                </FormTeraItem>
              </td>
            </tr>
            <tr>
              <td width={115}>
                Ngày cấp <span className="text-red-500">*</span>
              </td>
              <td>
                <FormTeraItem
                  name="provided_at"
                  className="mb-0"
                  rules={[{ required: messageValidate.emptySelect }]}
                >
                  <DatePicker format={DATE_FORMAT} />
                </FormTeraItem>
              </td>
            </tr>
          </tbody>
        </table>
      </CardDetail>
    </Col>
  );
};

export default CardImageInfor;
