import ZingIcon from '_common/component/ZingIcon';

const Advertisement = (): JSX.Element => {
  const ads = [
    'Host 5GB NVMe',
    'Host Starter NVMe',
    'Host Pro NVMe',
    'Host Business NVMe',
  ];

  return (
    <div className="py-8 border-b-[2px] border-dashed flex flex-col gap-4">
      <span className="text-blue-500">Bạn cũng có thể thích</span>
      <div className="flex justify-center gap-4">
        {ads.map((ad, index) => (
          <div
            key={index}
            className="w-[200px] bg-white py-4 rounded-[10px] drop-shadow flex flex-col items-center gap-3 cursor-pointer"
          >
            <ZingIcon.IconWorldWide />
            <span className="font-[500]">{ad}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Advertisement;
