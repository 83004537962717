import { useQuery } from '@tanstack/react-query';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useDebounce } from '_common/hooks/useDebounce';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';
import Text from '_common/component/Text';

interface ISelectSystemPaymentMethodProps extends SelectProps {
  paramsApi?: TParamsApiDof;
  isIncludeWallet?: boolean;
}

const SelectSystemPaymentMethod = forwardRef<
  HTMLInputElement,
  ISelectSystemPaymentMethodProps
>(
  (
    { isIncludeWallet, placeholder = 'Vui lòng chọn', paramsApi, ...props },
    ref,
  ) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);

    const { data: dataPaymentMethodList, isLoading } = useQuery(
      ['get-system-payment-method-list', searchDebounce, paramsApi],
      () => {
        const params = {
          page: 1,
          limit: 15,
          keyword: searchDebounce,
          ...paramsApi,
        };
        return PaymentMethodAPI.getListOfSystem({ params });
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const options: OptionProps[] = useMemo(
      () =>
        dataPaymentMethodList
          ? [
              ...dataPaymentMethodList?.data.map((paymentMethod) => ({
                value: paymentMethod.id,
                valueCustom: paymentMethod,
                label: `${paymentMethod.type.name} - ${paymentMethod.type.description}`,
                labelDisplay: (
                  <Text className="flex flex-col gap-1">
                    <span>
                      {`${paymentMethod.type.name} - ${paymentMethod.type.description}`}
                    </span>
                    <span className="text-amber-700">
                      {paymentMethod.account_number}
                    </span>
                  </Text>
                ),
              })),
              ...(isIncludeWallet
                ? [{ value: '0', label: 'Ví ZingCloud' }]
                : []),
            ]
          : [],
      [dataPaymentMethodList],
    );

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        loading={isLoading}
        placeholder={placeholder}
        options={options}
        searchValue={search}
        onSearch={setSearch}
        {...props}
      />
    );
  },
);

export default SelectSystemPaymentMethod;
