import FormSummary from '_common/component/Form/Summary';
import { messageWarning } from '_common/constants/message';
import {
  PRICE_PERIOD_DAY,
  PRICE_PERIOD_TEXT,
  SERVICE,
} from '_common/constants/serviceManagement';
import FormTera from '_common/dof/FormTera';
import { useStores } from '_common/hooks';
import useConfirm from '_common/hooks/useConfirm';
import { observer } from 'mobx-react-lite';
import { SHOPPING_CART_URL } from 'pages/ShoppingCart/url';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import { SERVICE_CLOUD_VPS_URL } from '../../url';
import FormCreateUpdate from './components/FormCreateUpdate';

const CreateUpdatePage = (): JSX.Element => {
  const {
    shoppingCartStore: { updateCart },
  } = useStores();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      datacenterProduct: '',
      product: '',
      locationId: '',
      osId: '',
      period: 'monthly',
      domain: null,
      isAutoRenew: 1,
      userName: 'root',
    },
  });
  const { isDirty } = form.formState;
  const { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud VPS
        </span>
      ),
      onClick: () =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: () => navigate(SERVICE_CLOUD_VPS_URL.list.path),
            })
          : navigate(SERVICE_CLOUD_VPS_URL.list.path),
    },
    {
      title: `${id ? 'Sửa' : 'Thêm'} Cloud VPS`,
    },
  ];

  const handleSubmitForm = async (values: any) => {
    const paramString = updateQueryParams({
      cart: SERVICE.CLOUD_VPS,
    });

    const data = {
      type: SERVICE.CLOUD_VPS,
      serviceName: values.product.planCode,
      datacenterId: values.datacenterProduct.datacenterId,
      planId: values.product.planId,
      locationId: values.locationId,
      osId: values.osId,
      period: values.period,
      period_text: PRICE_PERIOD_TEXT[values.period],
      period_days: PRICE_PERIOD_DAY[values.period],
      first_payment: values.product.prices[values.period],
      recurring_amount: values.product.prices[values.period],
      isAutoRenew: values.isAutoRenew,
      userName: values.userName,
    };

    updateCart(SERVICE.CLOUD_VPS, data);
    navigate(`${SHOPPING_CART_URL.cart.path}${paramString}`);
  };

  return (
    <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <FormTera form={form} className="flex-1 flex flex-col">
        <FormCreateUpdate form={form} countryCode={queryParams.country} />
        {form.watch('product') && (
          <FormSummary
            form={form}
            handleSubmitForm={form.handleSubmit(handleSubmitForm)}
          />
        )}
      </FormTera>
    </div>
  );
};

export default observer(CreateUpdatePage);
