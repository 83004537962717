import { useStores } from '_common/hooks';
import useUnAuthGroupMenu from '_common/hooks/useUnAuthGroupMenu';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../UnAuthHeader';
import MoreMenu from './MoreMenu';
import { IMenu } from './interface';

export interface MenuProps {
  isExpand?: boolean;
}

const UnAuthMenuComponent: React.FC<MenuProps> = observer(
  ({ isExpand }: MenuProps): JSX.Element => {
    const location = useLocation();
    const {
      commonStore: { activeMenu, openMenuMore, setActiveMenu, setOpenMenuMore },
    } = useStores();

    const menus = useUnAuthGroupMenu();

    const updateActiveMenu = () => {
      const splitUrl = location?.pathname?.split('/');
      const checkMenu: IMenu = menus?.find(
        (obj) => splitUrl.indexOf(obj?.key) > -1,
      );

      setActiveMenu(String(checkMenu?.key));
    };

    useEffect(() => {
      location?.pathname && updateActiveMenu();
    }, [location, menus]);

    const handleOpenMoreMenu = (): void => {
      setOpenMenuMore(!openMenuMore);
    };

    const sliceMenu = useMemo(() => {
      const menu = [...menus].splice(0, 6);
      const menuRemain = [...menus].splice(6, 100);

      return {
        menu,
        menuRemain,
      };
    }, [menus]);

    const elementRef = useRef();

    const activeOtherMenu = useMemo(() => {
      const menuRemain = sliceMenu?.menuRemain.map((item) => item.key);
      return menuRemain?.includes(activeMenu);
    }, [activeMenu, sliceMenu]);
    activeOtherMenu;
    handleOpenMoreMenu;
    return (
      <>
        <div
          ref={elementRef}
          className={`${
            isExpand ? 'md:left-[225px]' : 'md:left-[50px]'
          } transition-all fixed top-0 right-0 z-[49] px-2.5 h-[45px]  flex items-center justify-end bg-white border-b border-gray-200 text-xxs left-0`}
          style={{ boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)' }}
        >
          <Header isExpand={isExpand} />
        </div>
        <MoreMenu menus={sliceMenu.menuRemain} />
      </>
    );
  },
);

export default UnAuthMenuComponent;
