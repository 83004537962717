import { IRouteProps } from '_common/interface/router';
import AffiliatesPage from '.';
import { AFFILIATES_URL } from './url';

export const AffiliatesRouter: IRouteProps[] = [
  {
    key: AFFILIATES_URL.list.key,
    path: AFFILIATES_URL.list.shortenUrl,
    component: <AffiliatesPage />,
  },
];
