import { IRouteProps } from '_common/interface/router';
import PromoCodePage from '.';
import { PROMO_CODE_URL } from './url';

export const PromoCodeRouter: IRouteProps[] = [
  {
    key: PROMO_CODE_URL.list.key,
    path: PROMO_CODE_URL.list.shortenUrl,
    component: <PromoCodePage />,
  },
];
