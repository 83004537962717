import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { TRANSACTION_TYPE } from '_common/constants/invoiceManagement';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Col,
  PaginationProps,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import InvoiceAPI from '../Invoice/apis';
import ButtonWallet from '../_common/components/ButtonWallet';
import Filter from './components/Filter';
import Table from './components/Table';
import WithDrawal from './containers/WithDrawal';
import { WITHDRAWAL_URL } from './url';

const WithdrawalPage = (): JSX.Element => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openWithDrawal, setOpenWithDrawal] = useState<boolean>(false);

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${WITHDRAWAL_URL.list.path}${paramString}`);
  };

  const handleFilter = (values: any) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const {
    data: dataList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-finance-withdrawal-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        transaction_type: TRANSACTION_TYPE.WITHDRAWAL,
      };
      return InvoiceAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  return (
    <Col className="tera-page">
      <HeaderViewList
        title="Rút tiền"
        onClickFilter={() => setIsOpenFilter(true)}
        actionRight={
          <ButtonWallet
            title="Rút tiền"
            typeBtn="withdrawal"
            onClick={() => setOpenWithDrawal(true)}
          />
        }
        filterCount={{
          filter: queryParams,
          params: ['created_at'],
        }}
      >
        <Table
          rowKey={'id'}
          data={dataList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: dataList?.total || 0,
            current: dataList?.current_page,
            pageSize: dataList?.per_page || 10,
            to: dataList?.to,
            from: dataList?.from || 10,
          }}
        />
      </HeaderViewList>
      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
      {openWithDrawal && (
        <WithDrawal
          open={openWithDrawal}
          type={'withdrawal'}
          onClose={() => setOpenWithDrawal(false)}
        />
      )}
    </Col>
  );
};

export default observer(WithdrawalPage);
