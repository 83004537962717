import FormPromoCode from '_common/component/Form/PromoCode';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import RadioGroup from '_common/dof/Control/RadioGroup';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import {
  cpuOptions,
  osOptions,
  panelOptions,
  privateServicePackages,
} from 'pages/ServiceManagement/CloudServer/constants';
import { Fragment } from 'react/jsx-runtime';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatCurrency } from 'tera-dls';

const FormAddPrivate = ({ form }): JSX.Element => {
  const details = [
    { label: 'CPU', value: 'Intel E5 - 268x v3/4' },
    { label: 'Ram', value: '320 GB' },
    { label: 'Disk', value: '1 x 480GB' },
    { label: 'Network Port', value: '1Gbps/4Mbps' },
    { label: 'Network speed', value: '100Mbps/4Mbps' },
    { label: 'Băng thông', value: 'Không giới hạn' },
  ];

  const formSections = [
    {
      name: 'Chọn gói dịch vụ',
      content: (
        <Swiper
          spaceBetween={25}
          slidesPerView={3}
          className="!w-full !h-auto !p-4 !pt-0"
        >
          {privateServicePackages.map((service) => (
            <SwiperSlide
              key={service.id}
              className={`min-w-[400px] max-w-[400px] !h-auto bg-white p-6 py-8 rounded-[8px] hover:drop-shadow-xl flex flex-col cursor-pointer ${
                form.watch('service_package') === service.id &&
                'text-blue-800 !bg-[#EBF5FF] drop-shadow-xl'
              }`}
              onClick={() => form.setValue('service_package', service.id)}
            >
              <span className="text-[30px] font-bold leading-9">
                {service.name}
              </span>
              <div className="pb-8 mt-[4rem] border-b-[1px] border-[#606999] border-dashed flex gap-2">
                <span className="text-[25px] font-bold">
                  {formatCurrency(313000)}
                </span>
                / 01 năm
              </div>
              <div className="mt-[2rem] flex flex-col items-center gap-4">
                {details.map((detail, index) => (
                  <div key={index} className="w-full flex gap-3">
                    <span className="flex-1 text-gray-500 text-right">
                      {detail.label}
                    </span>
                    <span className="flex-1 font-[500]">{detail.value}</span>
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ),
    },
    {
      name: 'CPU',
      content: (
        <FormTeraItem
          name="cpu"
          className="p-4 bg-white rounded-[6px] drop-shadow mb-0"
        >
          <Select options={cpuOptions} />
        </FormTeraItem>
      ),
    },
    {
      name: 'Ram',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex items-center gap-2">
          <FormTeraItem name="ram" className="flex-1 mb-0">
            <InputNumber />
          </FormTeraItem>
          <span className="w-[10%]">x 1GB Ram</span>
        </div>
      ),
    },
    {
      name: 'IP',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex items-center gap-2">
          <FormTeraItem name="ip" className="flex-1 mb-0">
            <InputNumber />
          </FormTeraItem>
          <span className="w-[10%]">x 1</span>
        </div>
      ),
    },
    {
      name: 'OS (Non License)',
      content: (
        <FormTeraItem
          name="os"
          className="p-4 bg-white rounded-[6px] drop-shadow mb-0"
        >
          <RadioGroup
            listOptions={osOptions}
            className="grid grid-cols-3 gap-4 [&>div]:items-start"
          />
        </FormTeraItem>
      ),
    },
    {
      name: 'Panel (Non License)',
      content: (
        <FormTeraItem
          name="panel"
          className="p-4 bg-white rounded-[6px] drop-shadow mb-0"
        >
          <RadioGroup
            listOptions={panelOptions}
            className="grid grid-cols-3 gap-4 [&>div]:items-start"
          />
        </FormTeraItem>
      ),
    },
    {
      name: 'SSD 480GB',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex items-center gap-2">
          <FormTeraItem name="ssd_480" className="flex-1 mb-0">
            <InputNumber />
          </FormTeraItem>
          <span className="w-[10%]">x 1</span>
        </div>
      ),
    },
    {
      name: 'SSD 240GB',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex items-center gap-2">
          <FormTeraItem name="ssd_240" className="flex-1 mb-0">
            <InputNumber />
          </FormTeraItem>
          <span className="w-[10%]">x 1</span>
        </div>
      ),
    },
    {
      name: 'SSD 960GB',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex items-center gap-2">
          <FormTeraItem name="ssd_960" className="flex-1 mb-0">
            <InputNumber />
          </FormTeraItem>
          <span className="w-[10%]">x 1</span>
        </div>
      ),
    },
    {
      name: 'SSD 1.92TB',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex items-center gap-2">
          <FormTeraItem name="ssd_1920" className="flex-1 mb-0">
            <InputNumber />
          </FormTeraItem>
          <span className="w-[10%]">x 1</span>
        </div>
      ),
    },
    {
      name: 'BW',
      content: (
        <FormTeraItem
          name="bandwidth"
          className="p-4 bg-white rounded-[6px] drop-shadow mb-0"
        >
          <Select options={cpuOptions} />
        </FormTeraItem>
      ),
    },
    {
      name: 'SSD 1.5TB',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex items-center gap-2">
          <FormTeraItem name="ssd_1500" className="flex-1 mb-0">
            <InputNumber />
          </FormTeraItem>
          <span className="w-[10%]">x 1</span>
        </div>
      ),
    },
    {
      name: 'Tên miền/Hostname',
      content: (
        <FormTeraItem
          name="domain"
          className="p-4 bg-white rounded-[6px] drop-shadow mb-0"
        >
          <Input />
        </FormTeraItem>
      ),
    },
    {
      name: 'Sử dụng mã khuyến mãi',
      content: <FormPromoCode />,
    },
  ];

  return (
    <div className="p-2 flex flex-col gap-10">
      {formSections.map((section, index) => (
        <Fragment key={index}>
          <div className="flex items-center gap-2">
            <span className="w-[30px] h-[30px] text-blue-500 font-[500] border-[1px] border-blue-500 rounded-[30px] flex justify-center items-center">
              {index + 1}
            </span>
            <span className="text-[20px] font-[500]">{section.name}</span>
          </div>
          {section.content}
        </Fragment>
      ))}
    </div>
  );
};

export default FormAddPrivate;
