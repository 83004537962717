import { IRouteProps } from '_common/interface/router';
import CloudServerPage from '.';
import AddCloudServerPage from './pages/Add';
import DetailCloudServerPage from './pages/Detail';
import { SERVICE_CLOUD_SERVER_URL } from './url';

export const CloudServerRouter: IRouteProps[] = [
  {
    key: SERVICE_CLOUD_SERVER_URL.list.key,
    path: SERVICE_CLOUD_SERVER_URL.list.shortenUrl,
    component: <CloudServerPage />,
  },
  {
    key: SERVICE_CLOUD_SERVER_URL.create.key,
    path: SERVICE_CLOUD_SERVER_URL.create.shortenUrl,
    component: <AddCloudServerPage />,
  },
  {
    key: SERVICE_CLOUD_SERVER_URL.detail.key,
    path: SERVICE_CLOUD_SERVER_URL.detail.shortenUrl,
    component: <DetailCloudServerPage />,
  },
];
