import { IRouteProps } from '_common/interface/router';
import RegisterPage from '.';
import { REGISTER_URL } from './url';

export const RegisterRouter: IRouteProps[] = [
  {
    key: REGISTER_URL.index.key,
    path: REGISTER_URL.index.shortenUrl,
    component: <RegisterPage />,
  },
];
