import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import { DEPOSIT_LIST_URL } from './url';

export const DepositListRouter: IRouteProps[] = [
  {
    key: DEPOSIT_LIST_URL.list.key,
    path: DEPOSIT_LIST_URL.list.shortenUrl,
    component: <ListPage />,
  },
];
