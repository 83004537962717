import { PRICE_PERIOD_TEXT } from '_common/constants/serviceManagement';
import TableTera from '_common/dof/TableTera';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ColumnsType, formatCurrency, Modal } from 'tera-dls';

interface IModalDomainProcessesDetailProps {
  open: boolean;
  close: () => void;
  form: UseFormReturn<any>;
}

const ModalSummary = ({
  open,
  close,
  form,
}: IModalDomainProcessesDetailProps): JSX.Element => {
  const price = Number(
    useMemo(
      () => form.watch('product').prices[form.watch('period')],
      [form.getValues()],
    ),
  );
  const priceVAT = Number(
    useMemo(
      () => form.watch('product').prices[form.watch('period')] * 0.08,
      [form.getValues()],
    ),
  );
  const periodText = useMemo(
    () => PRICE_PERIOD_TEXT[form.watch('period')],
    [form.getValues()],
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Mô tả',
      dataIndex: '1',
      render: (_, record) => record.planCode,
    },
    {
      title: 'Giá',
      dataIndex: '2',
      render: () => (
        <div className="flex gap-1">
          <span>{formatCurrency(price)}</span>
          <span>/</span>
          <span>{periodText}</span>
        </div>
      ),
    },
  ];

  return (
    <Modal
      title="Áp dụng thuế VAT cho tên miền"
      open={open}
      centered={true}
      cancelButtonProps={{ className: 'hidden' }}
      okText="Đóng"
      onOk={close}
      className="sm:w-[90%] xl:w-[1000px]"
    >
      <TableTera
        columns={columns}
        data={[form.watch('product')]}
        summary={() => (
          <>
            <tr className="tera-table-row">
              <td className="tera-table-cell !align-middle">Tạm tính</td>
              <td className="tera-table-cell flex flex-col">
                {formatCurrency(price)}
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell !align-middle">VAT (10%)</td>
              <td className="tera-table-cell flex flex-col">
                {formatCurrency(priceVAT)}
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell !align-middle">
                Thanh toán theo định kỳ
              </td>
              <td className="tera-table-cell flex flex-col">
                <div className="flex gap-1">
                  <span>{formatCurrency(price + priceVAT)}</span>
                  <span>/</span>
                  <span>{periodText}</span>
                </div>
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell !align-middle">
                Thanh toán hôm nay
              </td>
              <td className="tera-table-cell">
                <span className="text-[20px] text-[#0E9F6E]">
                  {formatCurrency(price + priceVAT)}
                </span>
              </td>
            </tr>
          </>
        )}
      />
    </Modal>
  );
};

export default ModalSummary;
