import { useMutation, useQueryClient } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import FormDeposit from '_common/component/Form/Deposit';
import Text from '_common/component/Text';
import FormTera from '_common/dof/FormTera';
import { IOpenModalState } from '_common/interface';
import ModalConfirmPaymentMethod from 'pages/InvoiceManagement/_common/modals/ModalConfirmPaymentMethod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import WalletAPI from 'states/api/WalletAPI';
import napas from 'styles/images/napas.png';
import { Button, Col, CreditCardOutlined, Row, notification } from 'tera-dls';

const DepositContent = (): JSX.Element => {
  const [isOpenModalConfirmPaymentMethod, setIsOpenModalConfirmPaymentMethod] =
    useState<IOpenModalState>({ isOpen: false });
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      total: 50000,
    },
  });
  const queryClient = useQueryClient();

  const { mutate: mutateDeposit, isLoading: isLoadingDeposit } = useMutation(
    (params: any) => WalletAPI.deposit({ params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          form.reset({
            total: 0,
          });
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-finance-transaction-list']);
          setIsOpenModalConfirmPaymentMethod({
            isOpen: true,
            id: res.data.id,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleSubmitForm = (values: any) => {
    if (isLoadingDeposit) return;
    mutateDeposit(values);
  };

  return (
    <CardDetail title="Nạp tiền vào tài khoản" className="p-4">
      <Row className="grid grid-cols-3">
        <Col className="col-span-1 flex flex-col gap-2">
          <Text>Auto payment 247</Text>
          <img src={napas} className="h-[250px]" />
        </Col>
        <FormTera form={form} className="col-span-2 flex flex-col gap-4">
          <Col className="flex flex-col items-start gap-4">
            <FormDeposit form={form} />
            <Button
              prefix={<CreditCardOutlined className="size-4" />}
              className="bg-green-500 hover:bg-green-700"
              onClick={form.handleSubmit(handleSubmitForm)}
            >
              Thanh toán
            </Button>
          </Col>
        </FormTera>
      </Row>
      {isOpenModalConfirmPaymentMethod.isOpen && (
        <ModalConfirmPaymentMethod
          open={isOpenModalConfirmPaymentMethod.isOpen}
          close={() => setIsOpenModalConfirmPaymentMethod({ isOpen: false })}
          id={isOpenModalConfirmPaymentMethod.id}
          callback={() =>
            queryClient.invalidateQueries(['get-finance-transaction-list'])
          }
        />
      )}
    </CardDetail>
  );
};

export default DepositContent;
