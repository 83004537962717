import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export class ShoppingCartStore {
  cart: { [x: string]: any };

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'ShoppingCartStore',
      properties: ['cart'],
      storage: window.localStorage,
    });
  }

  updateCart = (key: string, values: any) => {
    this.cart = {
      ...this.cart,
      [key]: values,
    };
  };
}
