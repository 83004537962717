import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import {
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
} from 'pages/InvoiceManagement/Invoice/constants';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatCurrency,
  formatDate,
} from 'tera-dls';

const Table = (props: ITeraTableProps): JSX.Element => {
  const navigate = useNavigate();

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => navigate(`${INVOICE_URL.detail.path}/${record.id}`),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Ngày rút',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at) => formatDate(created_at),
    },
    {
      title: 'Số tiền rút',
      dataIndex: 'total',
      width: 150,
      render: (total) => formatCurrency(total),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag color={TABLE_STATUS_COLOR[status]} className="font-medium">
          {TABLE_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record: any) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <TableTera
      rowKey="id"
      columns={columns}
      loading={props?.loading}
      className="center-table"
      {...props}
    />
  );
};

export default Table;
