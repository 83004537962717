import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useStores } from '_common/hooks';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';
import { Spin } from 'tera-dls';
import Item from './Item';

function SelectPaymentMethod(props, ref) {
  const {
    transactionStore: { account, setAccount, nextStep },
  } = useStores();
  const limit = 15;
  const latestRef = useRef(null);
  const queryClient = useQueryClient();

  const listPaymentMethod = useInfiniteQuery({
    queryKey: ['get-payment-method-list'],
    staleTime: 30000,
    cacheTime: 30000,
    queryFn: ({ pageParam }) => {
      const params = {
        limit,
        page: pageParam || 1,
      };
      return PaymentMethodAPI.getListOfAccount({ params });
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.current_page < lastPage?.last_page
        ? allPages?.length + 1
        : undefined;
    },
  });

  const value = useMemo(() => {
    return listPaymentMethod.data?.pages?.reduce((acc, page) => {
      return _.unionBy(acc, page?.data ?? [], 'id');
    }, []);
  }, [listPaymentMethod.data]);

  const handleObserver = (entries: any) => {
    if (
      entries[0].isIntersecting &&
      listPaymentMethod.hasNextPage &&
      !listPaymentMethod.isFetching &&
      !listPaymentMethod.isFetchingNextPage
    ) {
      listPaymentMethod.fetchNextPage();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0.5,
    });

    if (observer && latestRef.current) {
      observer.observe(latestRef.current);
    }

    return () => {
      if (latestRef.current) {
        observer.disconnect();
      }
    };
  }, [value, handleObserver]);

  useImperativeHandle(ref, () => {
    return { nextStep: () => nextStep() };
  });

  useEffect(() => {
    queryClient.setQueryData(['get-payment-method-list'], (oldData: any) => {
      return {
        pages: [oldData?.pages[0]],
        pageParams: [1],
      };
    });
    listPaymentMethod?.refetch();
  }, []);

  return (
    <div className="flex flex-col gap-y-2.5">
      <Spin spinning={listPaymentMethod.isLoading}>
        <div className="grid grid-cols-3 gap-2.5 overflow-y-auto p-2 max-h-[330px]">
          {value?.map((item, index) => (
            <Item
              key={index}
              ref={index === value?.length - 1 ? latestRef : undefined}
              number={item?.account_number}
              image={item?.type?.image_bank}
              active={account?.id === item?.id}
              bankName={item?.type?.name}
              fullName={item?.account_name}
              onClick={() =>
                setAccount({
                  cardholder: item?.account_name,
                  id: item?.id,
                  number: item?.account_number,
                  cardName: item?.type?.name,
                  object_type: item?.type?.type,
                  image_bank: item?.type?.image_bank,
                })
              }
            />
          ))}
        </div>
      </Spin>
    </div>
  );
}

export default observer(forwardRef(SelectPaymentMethod));
