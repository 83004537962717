import { useStores } from '_common/hooks';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  PlusOutlined,
  Spin,
  Tabs,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import PromoCodeForm from '../PromoCode/containers/Form';
import AffiliatesWelcome from './components/AffiliatesWelcome';
import CommissionRule from './containers/CommissionRule';
import Overview from './containers/Overview';
import Promo from './containers/Promo';
import Tip from './containers/Tip';
import Withdrawal from './containers/Withdrawal';

const AffiliatesPage = (): JSX.Element => {
  const {
    authStore: { user },
  } = useStores();
  const [openForm, setOpenForm] = useState<boolean>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const tabItems = [
    {
      key: 'overview',
      label: 'Tổng quan',
    },
    {
      key: 'tip',
      label: 'Hoa hồng của tôi',
    },
    {
      key: 'withdrawal',
      label: 'Rút tiền',
    },
    {
      key: 'discount',
      label: 'Mã ưu đãi của tôi',
    },
    {
      key: 'tip-rule',
      label: 'Chính sách hoa hồng',
    },
  ];

  const tabData = {
    ['overview']: <Overview />,
    ['tip']: <Tip />,
    ['withdrawal']: <Withdrawal />,
    ['discount']: <Promo />,
    ['tip-rule']: <CommissionRule />,
  };

  const handleChangeTab = (key) => {
    handleUpdateQueryParams({ tab: key, page: 1 });
  };

  if (_.isNil(user?.account.affiliates)) {
    return <AffiliatesWelcome />;
  }

  return (
    <>
      <Spin spinning={false}>
        <div className="tera-page-form">
          <div className="page-header-sticky">
            <div className="page-header-v2">
              <div className="page-header-v2__breadcrumb">
                <div className="text-gray-700 font-semibold text-base uppercase">
                  AFFILIATES
                </div>
              </div>
              <div className="page-header-v2__function shrink-0">
                <Button
                  onClick={() => setOpenForm(true)}
                  className="px-[12px] py-[5px] rounded-[4px] flex gap-2.5 items-center bg-blue-500"
                >
                  <div className="flex gap-[5px] items-center">
                    <PlusOutlined className="size-[15px]" />
                    <span className="leading-[17px]">Thêm mã ưu đãi</span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
          <div className="page-content-v2 bg-white shadow-xsm rounded-md !px-[16px] h-full mx-2.5 !mb-2.5 !gap-y-0">
            <Tabs
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.tab || 'overview'}
              className="mb-0 pt-0 bg-white rounded-t-md"
            />
            <div className="pt-[16px]">
              {tabData[queryParams?.tab] ?? <Overview />}
            </div>
          </div>
        </div>
      </Spin>
      {openForm && (
        <PromoCodeForm open={openForm} onCancel={() => setOpenForm(false)} />
      )}
    </>
  );
};

export default observer(AffiliatesPage);
