import { IRouteProps } from '_common/interface/router';
import ServiceRegistrationPage from '.';
import { UNAUTH_SERVICE_REGISTRATION_URL } from './url';

export const UnAuthServiceRegistrationRouter: IRouteProps[] = [
  {
    key: UNAUTH_SERVICE_REGISTRATION_URL.list.key,
    path: UNAUTH_SERVICE_REGISTRATION_URL.list.shortenUrl,
    component: <ServiceRegistrationPage />,
  },
];
