import { ReactNode } from 'react';
import customTwMerge from 'tailwind-merge.config';

type CardDetailProductProps = {
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
  titleClassName?: string;
};
const CardDetail = ({
  title,
  children,
  className,
  titleClassName,
}: CardDetailProductProps) => {
  return (
    <div
      className={customTwMerge(
        'px-2.5 py-4 rounded-md [box-shadow:0px_1px_5px_0px_#0000001A,0px_-1px_5px_0px_#0000001A] bg-white',
        className,
      )}
    >
      {title && (
        <h4
          className={customTwMerge(
            'font-medium text-blue-500 mb-4',
            titleClassName,
          )}
        >
          {title}
        </h4>
      )}
      {children}
    </div>
  );
};

export default CardDetail;
