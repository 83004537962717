import { useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import {
  INVOICE_STATUS,
  TRANSACTION_TYPE,
} from '_common/constants/invoiceManagement';
import useConfirm from '_common/hooks/useConfirm';
import { IOpenModalState } from '_common/interface';
import _ from 'lodash';
import ModalConfirmPaymentMethod from 'pages/InvoiceManagement/_common/modals/ModalConfirmPaymentMethod';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb, Button, Spin } from 'tera-dls';
import InvoiceAPI from '../../apis';
import { INVOICE_URL } from '../../url';
import InvoiceContent from './components/InvoiceContent';

const DetailPage = () => {
  const [isOpenModalConfirmPaymentMethod, setIsOpenModalConfirmPaymentMethod] =
    useState<IOpenModalState>({ isOpen: false, id: null });
  const { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-finance-invoice-detail', id],
    () => InvoiceAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const handleConfirmPayment = () =>
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn thanh toán hóa đơn</p>
          <p>
            <b>{dataDetail?.invoice_no}</b> này không?
          </p>
        </div>
      ),
      onOk: () =>
        setIsOpenModalConfirmPaymentMethod({
          isOpen: true,
          id: dataDetail?.id,
        }),
    });

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="size-6" />
            </div>
            <Breadcrumb
              separator={'/'}
              items={[
                {
                  title: (
                    <a className="cursor-pointer text-blue-400 italic text-sm">
                      Danh sách hóa đơn
                    </a>
                  ),
                  onClick: () => navigate(INVOICE_URL.page.path),
                },
                {
                  title: (
                    <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px] text-sm">
                      Chi tiết hóa đơn
                    </a>
                  ),
                },
              ]}
            />
          </div>
          {!_.isEqual(
            dataDetail?.transaction_type,
            TRANSACTION_TYPE.WITHDRAWAL,
          ) &&
            _.isEqual(dataDetail?.status, INVOICE_STATUS.UNPAID) && (
              <div className="page-header-v2__function shrink-0">
                <Button onClick={handleConfirmPayment}>Thanh toán</Button>
              </div>
            )}
        </div>
      </div>
      <div className="page-content-v2">
        <Spin spinning={isLoadingDetail}>
          {id && (isErrorDetail || _.isEmpty(dataDetail)) ? (
            <NoData />
          ) : (
            <InvoiceContent dataDetail={dataDetail} />
          )}
        </Spin>
      </div>
      {isOpenModalConfirmPaymentMethod.isOpen && (
        <ModalConfirmPaymentMethod
          open={isOpenModalConfirmPaymentMethod.isOpen}
          close={() => setIsOpenModalConfirmPaymentMethod({ isOpen: false })}
          id={isOpenModalConfirmPaymentMethod.id}
          callback={() =>
            queryClient.invalidateQueries(['get-finance-invoice-detail'])
          }
        />
      )}
    </div>
  );
};

export default DetailPage;
