import { useMutation, useQueryClient } from '@tanstack/react-query';
import FormDeposit from '_common/component/Form/Deposit';
import Text from '_common/component/Text';
import FormTera from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { IOpenModalState } from '_common/interface';
import ModalConfirmPaymentMethod from 'pages/InvoiceManagement/_common/modals/ModalConfirmPaymentMethod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import WalletAPI from 'states/api/WalletAPI';
import { Modal, Row, notification } from 'tera-dls';

interface IModalDepositProps {
  open: boolean;
  close: () => void;
}

const ModalDeposit = ({ open, close }: IModalDepositProps) => {
  const [isOpenModalConfirmPaymentMethod, setIsOpenModalConfirmPaymentMethod] =
    useState<IOpenModalState>({ isOpen: false });
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      total: 50000,
    },
  });
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateDeposit, isLoading: isLoadingDeposit } = useMutation(
    (params: any) => WalletAPI.deposit({ params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-finance-deposit-list']);
          setIsOpenModalConfirmPaymentMethod({
            isOpen: true,
            id: res.data.id,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleSubmitForm = (values: any) => {
    if (isLoadingDeposit) return;
    mutateDeposit(values);
  };

  const handleClose = () => {
    confirm.warning({
      title: 'Xác nhận hủy giao dịch',
      content: 'Bạn có chắc muốn hủy giao dịch này không?',
      onOk: () => close(),
    });
  };

  return (
    <Modal
      title="Nạp tiền"
      open={open}
      okText="Xác nhận"
      cancelText="Hủy"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleClose}
      centered={true}
      className="!w-[90%] xmd:!w-[80%]"
      bodyClassName="!py-4"
      confirmLoading={isLoadingDeposit}
    >
      <Row className="flex flex-col gap-4">
        <Text className="text-gray-500">
          Nạp tiền để sử dụng trong tương lai, cho các đơn đặt hàng tiếp theo
          hoặc tự động gia hạn các hoá đơn.
        </Text>
        <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
          <FormDeposit form={form} />
        </FormTera>
      </Row>
      {isOpenModalConfirmPaymentMethod.isOpen && (
        <ModalConfirmPaymentMethod
          open={isOpenModalConfirmPaymentMethod.isOpen}
          close={() => {
            setIsOpenModalConfirmPaymentMethod({ isOpen: false });
            close();
          }}
          id={isOpenModalConfirmPaymentMethod.id}
          callback={() =>
            queryClient.invalidateQueries(['get-finance-deposit-list'])
          }
        />
      )}
    </Modal>
  );
};

export default ModalDeposit;
