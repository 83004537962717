import { useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TextNavigate from '_common/component/TextNavigate';
import {
  INVOICE_STATUS,
  PAYMENT_METHOD_TEXT,
  PAYMENT_METHOD_TYPE,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_TEXT,
} from '_common/constants/invoiceManagement';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { IOpenModalState } from '_common/interface';
import _ from 'lodash';
import {
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
} from 'pages/InvoiceManagement/Invoice/constants';
import ModalConfirmPaymentMethod from 'pages/InvoiceManagement/_common/modals/ModalConfirmPaymentMethod';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatCurrency,
  formatDate,
} from 'tera-dls';
import { INVOICE_URL } from '../../../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const [isOpenModalConfirmPaymentMethod, setIsOpenModalConfirmPaymentMethod] =
    useState<IOpenModalState>({ isOpen: false, id: null });
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleConfirmPayment = (record: any) =>
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn thanh toán hóa đơn</p>
          <p>
            <b>{record.invoice_no}</b> này không?
          </p>
        </div>
      ),
      onOk: () =>
        setIsOpenModalConfirmPaymentMethod({
          isOpen: true,
          id: record.id,
        }),
    });

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => navigate(`${INVOICE_URL.detail.path}/${record.id}`),
      },
      ...(!_.isEqual(record.transaction_type, TRANSACTION_TYPE.WITHDRAWAL) &&
      _.isEqual(record.status, INVOICE_STATUS.UNPAID)
        ? [
            {
              key: '2',
              label: 'Thanh toán',
              onClick: () => handleConfirmPayment(record),
            },
          ]
        : []),
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã hóa đơn',
      dataIndex: 'invoice_no',
      width: 150,
      render: (invoice_no, record) => (
        <TextNavigate to={`${INVOICE_URL.detail.path}/${record.id}`}>
          {invoice_no}
        </TextNavigate>
      ),
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 150,
      render: (transaction_type) => TRANSACTION_TYPE_TEXT[transaction_type],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (status) => (
        <Tag color={TABLE_STATUS_COLOR[status]} className="font-medium">
          {TABLE_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      title: 'Tổng giá trị',
      dataIndex: 'final_total',
      width: 150,
      render: (final_total) => formatCurrency(final_total),
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'payment_method',
      width: 200,
      render: (payment_method, record) =>
        record.payment_method_id === 0
          ? PAYMENT_METHOD_TEXT[PAYMENT_METHOD_TYPE.WALLET]
          : PAYMENT_METHOD_TEXT[payment_method?.type.type],
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at) => formatDate(created_at),
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'dueDate',
      width: 150,
      render: (dueDate) => formatDate(dueDate),
    },
    {
      title: 'Ngày thanh toán',
      dataIndex: 'paidDate',
      width: 150,
      render: (paidDate) => formatDate(paidDate),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <Fragment>
      <TableTera
        rowKey="id"
        columns={columns}
        loading={props?.loading}
        className="center-table"
        {...props}
      />
      {isOpenModalConfirmPaymentMethod.isOpen && (
        <ModalConfirmPaymentMethod
          open={isOpenModalConfirmPaymentMethod.isOpen}
          close={() => setIsOpenModalConfirmPaymentMethod({ isOpen: false })}
          id={isOpenModalConfirmPaymentMethod.id}
          callback={() =>
            queryClient.invalidateQueries(['get-finance-invoice-list'])
          }
        />
      )}
    </Fragment>
  );
};

export default Table;
