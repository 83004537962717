import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import Text from '_common/component/Text';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import SecurityAPI from 'states/api/SecurityAPI';
import SecureIcon from 'styles/images/Icons/SecureIcon';
import { Button, Col, Row, Spin, TrashSolid, notification } from 'tera-dls';

interface ISecureProps {
  dataDetail: any;
}

const Secure = ({ dataDetail }: ISecureProps) => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { rule: '' },
  });
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const {
    data: dataList,
    refetch: refetchDataList,
    isLoading: isLoadingDataList,
  } = useQuery(
    ['get-security-ip-list', dataDetail?.id],
    () => {
      return SecurityAPI.getSecurityIPList({
        params: { account_id: dataDetail?.id },
      });
    },
    {
      enabled: !_.isNil(dataDetail?.id),
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetchDataList();
  }, []);

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    (params) => SecurityAPI.createSecurityIP({ params }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          form.reset();
          queryClient.invalidateQueries(['get-security-ip-list']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreate) return;
    mutateCreate(values);
  };

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (idIP) => SecurityAPI.deleteSecurityIP({ id: idIP }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-security-ip-list']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) => {
    if (isLoadingDelete) return;

    confirm.warning({
      title: 'Xác nhận xóa IP',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa IP</p>
          <p>
            <b>{record?.rule}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(record?.id),
    });
  };

  return (
    <CardDetail>
      <Col className="flex flex-col gap-4">
        <Row className="flex gap-[16px] items-center">
          <SecureIcon className="size-[40px]" />
          <Col className="flex flex-col gap-2">
            <Text className="text-[12px] text-gray-500">
              IP của bạn hiện tại:
            </Text>
            <Text className="font-medium">125.235.238.106</Text>
          </Col>
        </Row>
        <Spin
          spinning={isLoadingDataList || isLoadingCreate || isLoadingDelete}
        >
          <CardDetail title="Bảo mật" className="p-0 !shadow-none">
            <Col className="flex flex-col gap-2.5">
              <FormTera form={form} className="flex gap-2.5">
                <FormTeraItem name="rule" className="w-full mb-0">
                  <Input
                    placeholder="eg. 125.235.238.106"
                    className="rounded-md"
                  />
                </FormTeraItem>
                <Button
                  className="bg-blue-500 shrink-0"
                  onClick={form.handleSubmit(handleSubmitForm)}
                >
                  Thêm IP
                </Button>
              </FormTera>
              <Col className="flex flex-col border-[1px] rounded-[5px] divide-x-[1px]">
                <Text className="bg-gray-50 py-2.5 px-[16px] border-b">
                  IP được phép truy cập
                </Text>
                <Col className="py-2.5 px-[16px] ">
                  {dataList?.length > 0 ? (
                    dataList?.map((item: any) => (
                      <Row
                        key={item.id}
                        className="flex justify-between py-2 border-b"
                      >
                        <p>{item.rule}</p>
                        <TrashSolid
                          className="size-4 cursor-pointer text-red-500"
                          onClick={() => handleDelete(item)}
                        />
                      </Row>
                    ))
                  ) : (
                    <Text>
                      Không có rule nào được cấu hình - Hiện tại đang cho phép
                      truy cập từ tất cả các IP. Lưu ý tính năng này chỉ áp dụng
                      nếu mạng internet tại nhà hoặc công ty của bạn sử dụng IP
                      tĩnh, nghĩa là IP public của mạng internet bạn chỉ sử dụng
                      1 hoặc vài IP biết trước.
                    </Text>
                  )}
                </Col>
              </Col>
              <Col className="flex flex-col border-[1px] rounded-[5px] divide-x-[1px]">
                <Text className="bg-gray-50 py-2.5 px-[16px] border-b">
                  Quy định cấu hình
                </Text>
                <Col className="py-2.5 px-[16px] text-gray-700">
                  <Text>all - keyword matching all IPs</Text>
                  <Text>xxx.xxx.xxx.xxx - IP đơn</Text>
                  <Text>xxx.xxx.xxx.xxx/M - IP with Mask in CIDR format</Text>
                  <Text>
                    xxx.xxx.xxx.xxx/mmm.mmm.mmm.mmm - IP with Mask in dotted
                    quad format
                  </Text>
                  <Text className="my-2">Example rules</Text>
                  <Text>
                    120.123.123.57/28 matches IP from 120.123.123.48 to
                    120.123.123.63
                  </Text>
                  <Text>
                    120.123.123.57/16 matches IP from 120.123.0.0 to
                    120.123.255.255
                  </Text>
                </Col>
              </Col>
            </Col>
          </CardDetail>
        </Spin>
      </Col>
    </CardDetail>
  );
};

export default Secure;
