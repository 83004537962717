import { useMutation, useQueryClient } from '@tanstack/react-query';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import UserAccountAPI from 'pages/Account/_common/apis';
import { useForm } from 'react-hook-form';
import { Button, DocumentTextSolid, Spin, notification } from 'tera-dls';

const WorkNote = (): JSX.Element => {
  const {
    authStore: { user },
  } = useStores();
  const form = useForm({
    mode: 'onChange',
    defaultValues: { note: user?.account?.note },
  });
  const queryClient = useQueryClient();

  const { mutate: mutateUpdateNote, isLoading: isLoadingUpdateNote } =
    useMutation(
      (params: any) =>
        UserAccountAPI.updateNote({ id: user?.account.id, params }),
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            queryClient.invalidateQueries(['get-profile']);
            notification.success({
              message: res?.msg,
            });
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if (isLoadingUpdateNote) return;
    mutateUpdateNote(values);
  };

  return (
    <Spin spinning={isLoadingUpdateNote}>
      <FormTera
        form={form}
        onSubmit={form.handleSubmit(handleSubmitForm)}
        className="w-full h-full bg-white mt-[2rem] drop-shadow rounded-[4px] flex flex-col justify-between"
      >
        <div className="bg-[#EBF5FF] p-4 flex items-center gap-2">
          <DocumentTextSolid color="#3F83F8" className="size-4" />
          <span>Ghi chú công việc</span>
        </div>
        <div className="p-4">
          <FormTeraItem name="note" className="w-full mb-0">
            <TextArea
              placeholder="Vui lòng nhập"
              className="w-full h-[130px] border rounded-[3px] resize-none"
            />
          </FormTeraItem>
        </div>
        <div className="bg-[#EBF5FF] p-4">
          <Button className="bg-[#3F83F8]">
            <span className="text-[15px]">Lưu thay đổi</span>
          </Button>
        </div>
      </FormTera>
    </Spin>
  );
};

export default observer(WorkNote);
