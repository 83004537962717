import { Table } from 'tera-dls';
import useTeraTable from '../useTeraTable';

const TeraNormalTable = () => {
  const { ...props } = useTeraTable();

  return <Table {...props} />;
};

export default TeraNormalTable;
