import PageTitle from '_common/component/PageTitle';
import { Fragment } from 'react/jsx-runtime';
import Footer from './components/Footer';
import HighQualityProxy from './components/HighQualityProxy';
import VPSServices from './components/VPSServices';
import WidgetOverview from './components/WidgetOverview';
import WorkNote from './components/WorkNote';

const Dashboard = (): JSX.Element => {
  return (
    <Fragment>
      <PageTitle title="TỔNG QUAN" />
      <div className="tera-page flex flex-col items-center">
        <div className="w-[1000px]">
          <WidgetOverview />
          <WorkNote />
          <VPSServices />
          <HighQualityProxy />
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Dashboard;
