import { INVOICE_STATUS } from '_common/constants/invoiceManagement';

export const TABLE_STATUS_COLOR = {
  [INVOICE_STATUS.UNPAID]: 'yellow03',
  [INVOICE_STATUS.PROCESSING]: 'blue03',
  [INVOICE_STATUS.PAID]: 'green03',
  [INVOICE_STATUS.FAILED]: 'red03',
};
export const TABLE_STATUS_TEXT = {
  [INVOICE_STATUS.UNPAID]: 'Chưa thanh toán',
  [INVOICE_STATUS.PROCESSING]: 'Đang xử lý',
  [INVOICE_STATUS.PAID]: 'Hoàn thành',
  [INVOICE_STATUS.FAILED]: 'Thất bại',
};
