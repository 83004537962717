import CardDetail from '_common/component/CardDetail';
import Input from '_common/dof/Control/Input';
import { FormTeraItem } from '_common/dof/FormTera';
import { Button, Row } from 'tera-dls';

const FormPromoCode = (): JSX.Element => {
  return (
    <CardDetail
      title="Mã khuyến mãi"
      className="p-4 flex flex-col gap-4"
      titleClassName="mb-0"
    >
      <Row className="flex items-center gap-2">
        <FormTeraItem name="promo_code" className="flex-1 mb-0">
          <Input placeholder="Vui lòng nhập" />
        </FormTeraItem>
        <Button htmlType="button" className="bg-blue-500">
          Kiểm tra
        </Button>
      </Row>
    </CardDetail>
  );
};

export default FormPromoCode;
