import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import DetailPage from './pages/Detail';
import { INVOICE_TRANSACTION_URL } from './url';

export const TransactionRouter: IRouteProps[] = [
  {
    key: INVOICE_TRANSACTION_URL.list.key,
    path: INVOICE_TRANSACTION_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: INVOICE_TRANSACTION_URL.detail.key,
    path: INVOICE_TRANSACTION_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
];
