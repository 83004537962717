import { IRouteProps } from '_common/interface/router';
import UnAuthCloudVPSPage from '.';
import UnAuthAddCloudVPSPage from './pages/Add';
import { UNAUTH_SERVICE_CLOUD_VPS_URL } from './url';

export const UnAuthCloudVPSRouter: IRouteProps[] = [
  {
    key: UNAUTH_SERVICE_CLOUD_VPS_URL.index.key,
    path: UNAUTH_SERVICE_CLOUD_VPS_URL.index.shortenUrl,
    component: <UnAuthCloudVPSPage />,
  },
  {
    key: UNAUTH_SERVICE_CLOUD_VPS_URL.add.key,
    path: UNAUTH_SERVICE_CLOUD_VPS_URL.add.shortenUrl,
    component: <UnAuthAddCloudVPSPage />,
  },
];
