import { IRouteProps } from '_common/interface/router';
import InvoicePage from '.';
import DetailPage from './pages/Detail';
import { INVOICE_URL } from './url';

export const InvoiceRouter: IRouteProps[] = [
  {
    key: INVOICE_URL.page.key,
    path: INVOICE_URL.page.shortenUrl,
    component: <InvoicePage />,
  },
  {
    key: INVOICE_URL.detail.key,
    path: INVOICE_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
];
