import { makeAutoObservable } from 'mobx';

export class TransactionStore {
  step = 0;
  account = null;
  amount = 0;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setStep = (value: number) => {
    this.step = value;
  };

  nextStep = () => {
    this.step = this.step + 1;
  };

  backStep = () => {
    this.step = this.step - 1;
  };

  setAccount = (value) => {
    this.account = value;
  };

  setAmount = (value: number) => {
    this.amount = value;
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  clearTransaction = () => {
    this.step = 0;
    this.account = null;
    this.amount = 0;
  };
}
