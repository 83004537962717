import { IRouteProps } from '_common/interface/router';
import SSLPage from '.';
import AddSSLPage from './pages/Add';
import DetailSSLPage from './pages/Detail';
import { SERVICE_SSL_URL } from './url';

export const SSLRouter: IRouteProps[] = [
  {
    key: SERVICE_SSL_URL.list.key,
    path: SERVICE_SSL_URL.list.shortenUrl,
    component: <SSLPage />,
  },
  {
    key: SERVICE_SSL_URL.create.key,
    path: SERVICE_SSL_URL.create.shortenUrl,
    component: <AddSSLPage />,
  },
  {
    key: SERVICE_SSL_URL.detail.key,
    path: SERVICE_SSL_URL.detail.shortenUrl,
    component: <DetailSSLPage />,
  },
];
