import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { REGEX } from '_common/constants/common';
import { messageValidate, messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBank from '_common/dof/Select/SelectBank';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';
import { Modal, Spin, notification } from 'tera-dls';

interface ModalCreateProps {
  open: boolean;
  id: number;
  onClose: () => void;
  onRefetch: () => void;
}

function ModalCreate(props: ModalCreateProps) {
  const { open, id, onClose, onRefetch } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const {
    formState: { isDirty },
  } = form;

  const detailCardBank = useQuery(
    ['get-finance-account-payment-method-detail', id],
    () => PaymentMethodAPI.getDetail({ id }),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const mutationBank = useMutation(
    (params: any) => {
      if (id) return PaymentMethodAPI.update({ id, params });
      return PaymentMethodAPI.create({ params });
    },
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          onClose();
          onRefetch();
          queryClient.invalidateQueries([
            'get-finance-account-payment-method-infinity',
          ]);
          queryClient.invalidateQueries([
            'get-finance-account-payment-method-list',
          ]);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleCloseConfirm = () => {
    if (mutationBank.isLoading) return;
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => onClose(),
      });
    } else onClose();
  };

  const handleSubmitForm = (values) => {
    mutationBank.mutate(values);
  };

  useEffect(() => {
    if (detailCardBank.data) {
      const data = {
        type_id: detailCardBank.data?.type_id,
        account_number: detailCardBank.data?.account_number,
        account_name: detailCardBank.data?.account_name,
      };

      form.reset(data);
    }
  }, [id, detailCardBank.data]);

  useEffect(() => {
    detailCardBank.refetch();
  }, [id]);

  return (
    <Modal
      closeIcon={false}
      open={open}
      okText="Đồng ý"
      cancelText="Hủy"
      title={id ? 'Sửa thông tin thẻ' : 'Thêm thông tin thẻ'}
      width={700}
      confirmLoading={mutationBank.isLoading}
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
    >
      <Spin spinning={detailCardBank.isLoading && !!id}>
        <FormTera form={form} onSubmit={handleSubmitForm}>
          <FormTeraItem
            label="Tên ngân hàng"
            name="type_id"
            rules={[{ required: messageValidate.emptySelect }]}
          >
            <SelectBank />
          </FormTeraItem>
          <FormTeraItem
            label="Người thụ hưởng"
            name="account_name"
            rules={[{ required: messageValidate.emptyText }]}
          >
            <Input maxLength={100} />
          </FormTeraItem>
          <FormTeraItem
            label="Số tài khoản"
            name="account_number"
            rules={[
              {
                required: messageValidate.emptyText,
                pattern: {
                  value: new RegExp(REGEX.CARD_NUMBER),
                  message: messageValidate.format,
                },
              },
            ]}
          >
            <Input maxLength={20} />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default ModalCreate;
