import User from './User';
import UserRegisterService from './UserRegisterService';
import UserShoppingCart from './UserShoppingCart';

export interface HeaderProps {
  isExpand?: boolean;
}

const Header = ({ isExpand }: HeaderProps): JSX.Element => {
  const listIcon = [
    <UserRegisterService isExpand={isExpand} />,
    <div className="w-[2px] h-[30px] bg-[#D1D5DB]" />,
    <UserShoppingCart />,
    <User />,
  ];

  return (
    <ul className="flex flex-row items-center gap-2.5 pr-2.5">
      {listIcon &&
        listIcon.map((item, key) => (
          <li key={key} className="text-gray-500">
            {item}
          </li>
        ))}
    </ul>
  );
};
export default Header;
