import TableTera from '_common/dof/TableTera';
import { ColumnsType, formatCurrency } from 'tera-dls';

const domainList = [
  { id: 1, domain: '.id.vn', subscribe: 60000, tinoGroup: 0, extend: 60000 },
  { id: 2, domain: '.io.vn', subscribe: 30000, tinoGroup: 0, extend: 60000 },
  { id: 3, domain: '.vn', subscribe: 450000, tinoGroup: 0, extend: 60000 },
  { id: 4, domain: '.com.vn', subscribe: 350000, tinoGroup: 0, extend: 60000 },
  { id: 5, domain: '.com', subscribe: 310000, tinoGroup: 0, extend: 60000 },
  {
    id: 6,
    domain: '.net',
    subscribe: 326244,
    tinoGroup: 100000,
    extend: 60000,
  },
  { id: 7, domain: '.org', subscribe: 351195, tinoGroup: 0, extend: 60000 },
  {
    id: 8,
    domain: '.info',
    subscribe: 579000,
    tinoGroup: 200000,
    extend: 60000,
  },
  { id: 9, domain: '.xyz', subscribe: 60000, tinoGroup: 150000, extend: 60000 },
  { id: 10, domain: '.biz.vn', subscribe: 350000, tinoGroup: 0, extend: 60000 },
  { id: 11, domain: '.edu.vn', subscribe: 150000, tinoGroup: 0, extend: 60000 },
  { id: 12, domain: '.net.vn', subscribe: 350000, tinoGroup: 0, extend: 60000 },
  { id: 13, domain: '.info.vn', subscribe: 60000, tinoGroup: 0, extend: 60000 },
];

const DomainPriceList = (): JSX.Element => {
  const columns: ColumnsType<any> = [
    {
      title: 'Tên miền',
      dataIndex: 'domain',
    },
    {
      title: 'Đăng ký mới',
      dataIndex: 'subscribe',
      render: (subscribe) => formatCurrency(subscribe),
    },
    {
      title: 'Chuyển về Tino Group',
      dataIndex: 'tinoGroup',
      render: (tinoGroup) =>
        tinoGroup ? formatCurrency(tinoGroup) : 'Miễn phí',
    },
    {
      title: 'Gia hạn',
      dataIndex: 'extend',
      render: (extend) => formatCurrency(extend),
    },
  ];

  return (
    <div className="bg-white rounded-[10px] p-4 flex flex-col">
      <span>Thiết lập để hệ thống chúng tôi phù hợp với nhu cầu của bạn</span>
      <span className="text-[#3F83F8] font-bold mt-[2rem] mb-[1rem]">
        Bản giá tên miền
      </span>
      <TableTera
        columns={columns}
        data={domainList}
        className="rounded-[10px]"
      />
    </div>
  );
};

export default DomainPriceList;
