import { useStores } from '_common/hooks';
import useConfirm from '_common/hooks/useConfirm';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useMemo, useRef } from 'react';
import { TTransaction } from 'states/stores/interface';
import { Button, Modal } from 'tera-dls';
import Amount from './Amount';
import SelectPaymentMethod from './SelectPaymentMethod';
import Transfer from './Transfer';

type TBtn = 'back' | 'cancel' | 'next';

interface IProps {
  open: boolean;
  onClose: () => void;
  type: TTransaction;
}

export const MIN_AMOUNT = 10000;
export const MAX_AMOUNT = 5000000;

function WithDrawal({ open, onClose, type }: IProps) {
  const elementRef = useRef(null);
  const confirm = useConfirm();
  const {
    transactionStore: {
      step,
      account,
      amount,
      backStep,
      setAmount,
      clearTransaction,
      isLoading,
    },
  } = useStores();

  const renderTitle = (step) => {
    const isRecharge = type === 'recharge';
    const title = {
      0: isRecharge ? 'Chọn phương thức thanh toán' : 'Chọn ngân hàng',
      1: isRecharge ? 'Nạp tiền' : 'Rút tiền',
      2: isRecharge ? 'Nạp tiền' : 'Rút tiền',
    };
    return title[step] || 'Không xác định';
  };

  const renderContent = (step) => {
    const content = {
      0: <SelectPaymentMethod ref={elementRef} />,
      1: <Amount ref={elementRef} />,
      2: <Transfer ref={elementRef} />,
    };
    return content[step] || 'Không xác định';
  };

  const renderTitleBtn = (type: TBtn, step) => {
    if (type === 'next') {
      const title = {
        0: 'Tiếp tục',
        1: 'Xác nhận',
        2: 'Xác nhận',
      };
      return title[step];
    }
  };

  const isDisable = useMemo(() => {
    switch (step) {
      case 0:
        return !account;
      case 1:
        return (
          amount < MIN_AMOUNT || (type === 'withdrawal' && amount > MAX_AMOUNT)
        );
    }
  }, [account, step, amount]);

  const handleBackStep = (step) => {
    if (step === 1) setAmount(0);
    backStep();
  };

  const handleClose = () => {
    confirm.warning({
      title: 'Xác nhận hủy giao dịch',
      content: 'Bạn có chắc muốn hủy giao dịch này không?',
      onOk: () => onClose(),
    });
  };

  useEffect(() => {
    if (step > 2) onClose();
  }, [step]);

  useEffect(() => {
    return () => clearTransaction();
  }, []);

  return (
    <Modal
      closeIcon={false}
      open={open}
      onCancel={handleClose}
      title={renderTitle(step)}
      width={630}
      bodyClassName="min-h-[50vh]"
      footerClassName="justify-center"
      footer={
        <Fragment>
          <Button
            type="alternative"
            className={classNames('w-full', {
              hidden: step <= 0,
            })}
            onClick={() => handleBackStep(step)}
            loading={isLoading}
          >
            Quay lại
          </Button>
          <Button
            type="alternative"
            className="w-full"
            onClick={handleClose}
            loading={isLoading}
          >
            Hủy
          </Button>
          <Button
            className="w-full"
            onClick={() => elementRef.current?.nextStep()}
            loading={isLoading}
            disabled={isDisable}
          >
            {renderTitleBtn('next', step)}
          </Button>
        </Fragment>
      }
    >
      {renderContent(step)}
    </Modal>
  );
}

export default observer(WithDrawal);
