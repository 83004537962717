import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalAddCloudVPS from './modals/ModalAddCloudVPS';

const UnAuthCloudVPSPage = (): JSX.Element => {
  const [openModalAddCloudVPS, setOpenModalAddCloudVPS] =
    useState<boolean>(true);
  const navigate = useNavigate();

  const handleCloseModalAddCloudVPS = () => {
    setOpenModalAddCloudVPS(false);

    if (history.state.idx === 0) navigate('/');
    else navigate(-1);
  };

  return (
    <ModalAddCloudVPS
      open={openModalAddCloudVPS}
      close={handleCloseModalAddCloudVPS}
    />
  );
};

export default UnAuthCloudVPSPage;
