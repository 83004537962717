import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CountryAPIEndpoint = `${endpoint}/portal/country`;

const CountryAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${CountryAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getAreaList: async ({ params }) =>
    await api
      .get(`${CountryAPIEndpoint}/area/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${CountryAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
};

export default CountryAPI;
