import { useNavigate } from 'react-router-dom';
import customTwMerge from 'tailwind-merge.config';

interface ITextProps {
  children: React.ReactNode;
  className?: string;
  to?: string;
}

const TextNavigate = ({ children, className, to }: ITextProps) => {
  const navigate = useNavigate();

  return (
    <p
      className={customTwMerge('text-blue-500 cursor-pointer', className)}
      onClick={() => navigate(to)}
    >
      {children}
    </p>
  );
};

export default TextNavigate;
