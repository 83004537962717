import { useEffect } from 'react';
import { Fragment } from 'react/jsx-runtime';

const belowTen = [
  'không',
  'một',
  'hai',
  'ba',
  'bốn',
  'năm',
  'sáu',
  'bảy',
  'tám',
  'chín',
];
const belowTwenty = [
  'mười',
  'mười một',
  'mười hai',
  'mười ba',
  'mười bốn',
  'mười lăm',
  'mười sáu',
  'mười bảy',
  'mười tám',
  'mười chín',
];
const belowHundred = [
  '',
  '',
  'hai mươi',
  'ba mươi',
  'bốn mươi',
  'năm mươi',
  'sáu mươi',
  'bảy mươi',
  'tám mươi',
  'chín mươi',
];

interface INumberToWordProps {
  number: number;
  onChange?: (value: string) => void;
  isAddEven?: boolean;
  isCupperCase?: boolean;
  isUpperCaseFirstLetter?: boolean;
}

const NumberToWord = ({
  number,
  onChange,
  isAddEven,
  isCupperCase,
  isUpperCaseFirstLetter,
}: INumberToWordProps): JSX.Element => {
  const numberToWords = (number: number): string => {
    if (number < 10) return belowTen[number];
    if (number < 20) return belowTwenty[number - 10];
    if (number < 100) {
      if (number % 10 === 1) {
        return belowHundred[Math.floor(number / 10)] + ' mốt';
      }
      if (number % 10 === 5) {
        return belowHundred[Math.floor(number / 10)] + ' lăm';
      }
      return (
        belowHundred[Math.floor(number / 10)] +
        (number % 10 !== 0 ? ' ' + belowTen[number % 10] : '')
      );
    }

    if (number < 1000)
      return (
        belowTen[Math.floor(number / 100)] +
        ' trăm' +
        (number % 100 !== 0 ? ' ' + numberToWords(number % 100) : '')
      );

    if (number < 1000000)
      return (
        numberToWords(Math.floor(number / 1000)) +
        ' nghìn' +
        (number % 1000 !== 0 ? ' ' + numberToWords(number % 1000) : '')
      );

    if (number < 1000000000)
      return (
        numberToWords(Math.floor(number / 1000000)) +
        ' triệu' +
        (number % 1000000 !== 0 ? ' ' + numberToWords(number % 1000000) : '')
      );

    return (
      numberToWords(Math.floor(number / 1000000000)) +
      ' tỷ' +
      (number % 1000000000 !== 0
        ? ' ' + numberToWords(number % 1000000000)
        : '')
    );
  };

  const capitalize = (str: string): string => {
    return str.toUpperCase();
  };

  const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const convertNumberToWords = (number: number): string => {
    if (!number || number < 1) number = 0;

    const [integerPart, fractionalPart] = number
      .toString()
      .replace(/,/g, '')
      .split('.');
    let words = numberToWords(Number(integerPart)) + ' đồng';

    if (fractionalPart)
      words += ' và ' + numberToWords(Number(fractionalPart)) + ' xu';
    if (!fractionalPart && isAddEven && Number(integerPart) % 2 === 0)
      words += ' chẵn';

    if (isCupperCase) return capitalize(words);
    if (isUpperCaseFirstLetter) return capitalizeFirstLetter(words);

    return words;
  };

  useEffect(() => {
    if (onChange) onChange(convertNumberToWords(number));
  }, [number]);

  return <Fragment>{convertNumberToWords(number)}</Fragment>;
};

export default NumberToWord;
