import { useMutation, useQueryClient } from '@tanstack/react-query';
import ZingIcon from '_common/component/ZingIcon';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { Button, Spin, Tag, notification } from 'tera-dls';
import DomainAPI from '../../apis';
import {
  DOMAIN_STATUS,
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
} from '../../constants';

interface IDetailHeaderProps {
  dataDetail: any;
}

const DetailHeader = ({ dataDetail }: IDetailHeaderProps): JSX.Element => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateRequestCancel, isLoading: isLoadingRequestCancel } =
    useMutation(() => DomainAPI.requestCancel({ id: dataDetail?.id }), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-service-domain-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    });

  const handleRequestCancel = () =>
    confirm.warning({
      title: 'Xác nhận yêu cầu hủy dịch vụ',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn yêu cầu hủy dịch vụ</p>
          <p>
            <b>{dataDetail?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateRequestCancel(),
    });

  return (
    <Spin spinning={isLoadingRequestCancel}>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-start gap-4">
            <h1 className="text-[20px] font-[600] flex gap-4">
              <span className="text-blue-500">{dataDetail?.name}</span>
              {dataDetail?.order[0].bill?.invoice_no}
            </h1>
            <Tag
              color={TABLE_STATUS_COLOR[dataDetail?.status]}
              className="font-[500] p-2 justify-center"
            >
              {TABLE_STATUS_TEXT[dataDetail?.status]}
            </Tag>
          </div>
          <div className="flex gap-4">
            {_.isEqual(dataDetail?.status, DOMAIN_STATUS.ACTIVE) &&
              !_.isEqual(dataDetail?.is_request_cancel, 1) && (
                <Button
                  prefix={<ZingIcon.IconClose />}
                  className="bg-red-500 hover:bg-red-700"
                  onClick={handleRequestCancel}
                >
                  Yêu cầu hủy dịch vụ
                </Button>
              )}
            {_.isEqual(dataDetail?.is_request_cancel, 1) && (
              <Button disabled>Đã yêu cầu hủy dịch vụ</Button>
            )}
          </div>
        </div>
        {_.isEqual(dataDetail?.status, DOMAIN_STATUS.NEW) && (
          <Tag
            color="yellow03"
            className="p-2 border-[1px] border-yellow-500 rounded-none"
          >
            Tên miền chưa được đăng ký. Khi nhận được thanh toán, hệ thống sẽ tự
            động đăng ký tên miền.
          </Tag>
        )}
      </div>
    </Spin>
  );
};

export default DetailHeader;
