import menu from '../component/Layout/UnAuthMenu/menu';
import { useStores } from './useStores';

const useUnAuthSubMenu = () => {
  const {
    commonStore: { activeMenu },
  } = useStores();

  const menus = menu.parentMenu?.filter(
    (item) => item?.parentKey === activeMenu,
  );

  return menus;
};

export default useUnAuthSubMenu;
