export const INVOICE_TRANSACTION_URL = {
  list: {
    key: '',
    path: '/invoice/transaction/list',
    shortenUrl: 'transaction/list',
  },
  detail: {
    key: '',
    path: '/invoice/transaction/detail',
    shortenUrl: 'transaction/detail/:id',
  },
};
