import { IRouteProps } from '_common/interface/router';
import UnAuthCloudServerPage from '.';
import { UNAUTH_SERVICE_CLOUD_SERVER_URL } from './url';

export const UnAuthCloudServerRouter: IRouteProps[] = [
  {
    key: UNAUTH_SERVICE_CLOUD_SERVER_URL.index.key,
    path: UNAUTH_SERVICE_CLOUD_SERVER_URL.index.shortenUrl,
    component: <UnAuthCloudServerPage />,
  },
];
