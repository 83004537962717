import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import { formatCurrency, Tag } from 'tera-dls';

const EstimateTab = () => {
  const columns: any = [
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (val) =>
        val === 'success' ? (
          <Tag color="green03">Hoàn thành</Tag>
        ) : (
          <Tag color="red03">Thất bại</Tag>
        ),
    },
    {
      title: 'Ước tính',
      dataIndex: 'estimate',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Tổng ',
      dataIndex: 'total',
      width: 150,
      render: (val) => (
        <span className="text-green-500"> + {formatCurrency(val ?? 0)}</span>
      ),
    },
    {
      title: 'Expiration date',
      dataIndex: 'expiration_date',
      width: 150,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
  ];

  return (
    <>
      <div className="flex justify-end flex-col shadow rounded-md  gap-2">
        <TableTera
          columns={columns}
          className="center-table"
          wrapperClassName="shadow-none"
          pagination={{}}
          data={[]}
        />
      </div>
    </>
  );
};

export default EstimateTab;
