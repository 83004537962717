import { observer } from 'mobx-react-lite';
import { Badge, BellOutlined, Popover, Spin } from 'tera-dls';
import HeaderModalMoreView from './Header';

const UserNotification = (): JSX.Element => {
  const Notification = (): JSX.Element => {
    return (
      <div className="bg-white p-2 flex justify-between border-b-[1px] relative cursor-pointer">
        <div className="flex flex-col gap-2">
          {/* <span className="text-blue-600 text-[14px]">#ZC123456</span> */}
          <span className="text-[12px]">Chức năng đang cập nhật</span>
        </div>
        {/* <span className="text-[#6B7280]">dd/mm/yyyy - hh:mm</span> */}
        {/* <Badge className="w-[5px] h-[5px] bg-[#1C64F2] p-0 absolute top-1 right-1" /> */}
      </div>
    );
  };

  const Content = (): JSX.Element => {
    return (
      <div className="bg-white shadow-xsm rounded-[5px]">
        <Spin spinning={false}>
          <HeaderModalMoreView />
          <div className="w-[500px] px-5">
            <div className="max-h-[500px] flex flex-col gap-2 py-2 overflow-y-auto">
              {/* <span className="text-[10px] text-[#374151] font-bold">
                dd/mm/yyyy
              </span> */}
              {Array(1)
                .fill(null)
                .map((_, index) => (
                  <Notification key={index} />
                ))}
            </div>
            {/* <div className="flex justify-center items-center p-2 border-t-[1px]">
              <a
                className="text-blue-600 cursor-pointer"
                onClick={() => {
                  const queryParams = updateQueryParams({
                    tab: 'inform',
                  });
                  navigate(`${HISTORY_URL.list.path}${queryParams}`);
                }}
              >
                Xem tất cả thông báo
              </a>
            </div> */}
          </div>
        </Spin>
      </div>
    );
  };

  return (
    <Popover
      trigger="click"
      content={<Content />}
      placement="bottom-end"
      showArrow={false}
      className="border p-0"
    >
      <div className="cursor-pointer relative">
        <BellOutlined className="size-6" />
        <Badge
          showZero
          count={0}
          className="text-white text-[8px] bg-[#F98080] px-1 py-0 absolute -top-1 -right-1"
        />
      </div>
    </Popover>
  );
};

export default observer(UserNotification);
