import CardDetail from '_common/component/CardDetail';
import ZingIcon from '_common/component/ZingIcon';
import { useState } from 'react';
import { Col, formatCurrency, Radio, Row, Tooltip } from 'tera-dls';

const Overview = () => {
  const [radio, setRadio] = useState<any>(1);
  return (
    <CardDetail className="pb-0 px-[18px] rounded-xl">
      <p className="text-gray-500 mb-[10px]">
        Thiết lập để hệ thống chúng tôi phù hợp với nhu cầu của bạn
      </p>
      <div className="flex flex-col divide-y-[2px] divide-dotted">
        <CardDetail
          className="text-sm rounded-non mb-2.5 !shadow-none"
          title="Thống kê"
        >
          <div className="[box-shadow:0px_1px_5px_0px_#0000001A,0px_-1px_5px_0px_#0000001A] rounded-xl divide-y-[1px] divide-gray-100">
            <Row className=" grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">Hoa hồng</Col>
              <Col className="col-span-1">
                {formatCurrency(1000000)} / {formatCurrency(1000000)} (Tháng này
                / Tổng)
              </Col>
            </Row>
            <Row className="rounded grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">Người dùng truy cập</Col>
              <Col className="col-span-1">100 / 200 (Tháng này / Tổng)</Col>
            </Row>
            <Row className="rounded grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">Người dùng đã đăng ký</Col>
              <Col className="col-span-1">50 / 200 (Tháng này / Tổng)</Col>
            </Row>
            <Row className="rounded grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">Số dư hiện tại</Col>
              <Col className="col-span-1">{formatCurrency(1800000000)}</Col>
            </Row>
            <Row className="rounded grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">Link cộng tác viên của bạn</Col>
              <Col className="col-span-1 text-blue-600">https://</Col>
            </Row>
          </div>
        </CardDetail>
        <CardDetail
          className="text-sm rounded-non mb-2.5 !shadow-none"
          title="Tuỳ chọn"
        >
          <div className="[box-shadow:0px_1px_5px_0px_#0000001A,0px_-1px_5px_0px_#0000001A] rounded-xl divide-y-[1px] divide-gray-100">
            <Row className=" grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">
                Gửi email cho tôi mỗi tháng một lần với bản tóm tắt hoa hồng
              </Col>
              <Col className="col-span-1">
                <Radio.Group
                  value={radio}
                  onChange={setRadio}
                  name="1"
                  inline
                  className="gap-3"
                >
                  <Radio value={1}>Bật</Radio>
                  <Radio value={2}>Tắt</Radio>
                </Radio.Group>
              </Col>
            </Row>
            <Row className="rounded grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1 flex items-center gap-1">
                Link đích
                <Tooltip
                  placement="top"
                  title={
                    <div className="w-[250px]">
                      Link đích mà người truy cập sẽ được redirect tới sau khi
                      truy cập link giới thiệu của bạn
                    </div>
                  }
                >
                  <ZingIcon.IconQuestionMark className="text-white bg-black rounded-[15px]" />
                </Tooltip>
              </Col>
              <Col className="col-span-1 text-blue-600">https://</Col>
            </Row>
          </div>
        </CardDetail>
        <CardDetail
          className="text-sm rounded-none !shadow-none"
          title="Campaigns"
        >
          <div className="[box-shadow:0px_1px_5px_0px_#0000001A,0px_-1px_5px_0px_#0000001A] rounded-xl divide-y-[1px] divide-gray-100">
            <Row className="rounded grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">Business Hosting</Col>
              <Col className="col-span-1 text-blue-600">https://</Col>
            </Row>
            <Row className="rounded grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">Cloud Hosting SEO</Col>
              <Col className="col-span-1 text-blue-600">https://</Col>
            </Row>
            <Row className="rounded grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">Unlimited Hosting</Col>
              <Col className="col-span-1 text-blue-600">https://</Col>
            </Row>
            <Row className="rounded grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">NVMe Hosting</Col>
              <Col className="col-span-1 text-blue-600">https://</Col>
            </Row>
            <Row className="rounded grid-cols-2 gap-1 py-[15px] px-[12px] ">
              <Col className="col-span-1">NVMe VPS</Col>
              <Col className="col-span-1 text-blue-600">https://</Col>
            </Row>
          </div>
        </CardDetail>
      </div>
    </CardDetail>
  );
};

export default Overview;
