import Text from '_common/component/Text';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { DEPOSIT_URL } from 'pages/Deposit/url';
import { useNavigate } from 'react-router-dom';
import { CreditCardSolid, formatCurrency, Row } from 'tera-dls';

const UserBalance = (): JSX.Element => {
  const {
    authStore: { user },
  } = useStores();
  const navigate = useNavigate();

  return (
    <Row
      className="flex items-center gap-1 cursor-pointer"
      onClick={() => navigate(DEPOSIT_URL.page.path)}
    >
      <CreditCardSolid className="size-6" color="#3F83F8" />
      <Text>Số dư:</Text>
      <Text className="font-bold">
        {formatCurrency(user?.account?.wallet?.available_balance)}
      </Text>
    </Row>
  );
};

export default observer(UserBalance);
