import PageTitle from '_common/component/PageTitle';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Spin } from 'tera-dls';
import { LOGIN_URL } from '../Login/url';
import { RESET_PASSWORD_URL } from '../ResetPassword/url';

interface IForgotPasswordValues {
  username: string;
}

const ForgotPasswordPage = (): JSX.Element => {
  const form = useForm({ mode: 'onChange' });
  const navigate = useNavigate();

  useEffect(() => {
    const initData: IForgotPasswordValues = {
      username: '',
    };
    form.reset(initData);
  }, []);

  const handleSendEmail = (values: IForgotPasswordValues) => {
    console.log(values);
    navigate(RESET_PASSWORD_URL.index.path);
  };

  return (
    <>
      <PageTitle title="QUÊN MẬT KHẨU" />
      <div className="tera-page h-full flex-1 flex flex-col justify-between items-center">
        <Row className="flex-1 flex items-center">
          <Spin spinning={false}>
            <Row className="w-[476px] h-[703px] bg-white px-[2rem] py-[3rem] rounded-[30px] drop-shadow flex flex-col justify-center items-center gap-[4rem]">
              <FormTera
                form={form}
                onSubmit={handleSendEmail}
                className="w-full h-full flex flex-col justify-between"
              >
                <Row className="w-full flex flex-col items-center">
                  <h2 className="text-[30px] text-[#1E429F] pb-10">
                    Quên mật khẩu
                  </h2>
                  <FormTeraItem
                    label="Nhập email đăng ký"
                    name="username"
                    className="w-full mb-0"
                  >
                    <Input
                      placeholder="Vui lòng nhập email"
                      className="w-full h-[52px] border rounded-[10px]"
                    />
                  </FormTeraItem>
                  <i className="text-[#e02424] flex flex-col items-center">
                    <span>*Nhập email đã đăng ký trước đó.</span>
                    <span>Hệ thống sẽ gửi 1 tin nhắn đến email của bạn.</span>
                    <span>Vui lòng kiểm tra hộp thư đến.</span>
                    <span className="text-[#723B13] pt-5">
                      Lưu ý thời gian hết hạn tin nhắn là 5 phút.
                    </span>
                  </i>
                </Row>
                <Row className="flex flex-col items-center">
                  <Button
                    type="primary"
                    className="w-full h-[49px] text-[20px] font-bold bg-[#1975A7] rounded-[10px] mt-5"
                    disabled={false}
                  >
                    Gửi email
                  </Button>
                  <Row className="mt-5 flex">
                    <span>Bạn đã có tài khoản ZingServer?</span>
                    <Button
                      className="bg-transparent hover:bg-transparent p-0 cursor-pointer"
                      onClick={() => navigate(LOGIN_URL.index.path)}
                    >
                      <span className="text-[15px] text-[#005880] font-normal">
                        Đăng nhập
                      </span>
                    </Button>
                  </Row>
                </Row>
              </FormTera>
            </Row>
          </Spin>
        </Row>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
