import { IRouteProps } from '_common/interface/router';
import ResetPasswordPage from '.';
import { RESET_PASSWORD_URL } from './url';

export const ResetPasswordRouter: IRouteProps[] = [
  {
    key: RESET_PASSWORD_URL.index.key,
    path: RESET_PASSWORD_URL.index.shortenUrl,
    component: <ResetPasswordPage />,
  },
];
