import ZingCloud from '_common/component/Icons/ZingCloud';
import PageTitle from '_common/component/PageTitle';
import { Row } from 'tera-dls';
import FormLogin from './components/FormLogin';

const LoginPage = (): JSX.Element => {
  return (
    <>
      <PageTitle title="ĐĂNG NHẬP" />
      <div className="tera-page h-full flex-1 flex justify-center items-center">
        <Row className="bg-white px-[2rem] py-[5rem] rounded-[30px] drop-shadow flex flex-col justify-center items-center gap-[4rem]">
          <ZingCloud className="w-[140px] h-[140px]" />
          <FormLogin />
        </Row>
      </div>
    </>
  );
};

export default LoginPage;
