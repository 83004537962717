import { Tag } from 'tera-dls';

const Notification = (): JSX.Element => {
  return (
    <div className="py-8 border-b-[2px] border-dashed">
      <Tag color="yellow03" className="w-full p-4 rounded-[10px]">
        <span>
          Quý khách lưu ý: Hóa đơn điện tử (VAT) sẽ xuất theo thông tin khách
          hàng bên dưới.
        </span>
        <br />
        <span>
          Nếu có thay đổi thông tin, vui lòng cập nhật thông tin tài khoản trước
          khi đặt hàng, hoặc liên hệ bộ phận hỗ trợ.
        </span>
      </Tag>
    </div>
  );
};

export default Notification;
