import { DATE_FORMAT } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import RadioGroup from '_common/dof/Control/RadioGroup';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, InputNumber, Modal, Row, Tag, Tooltip } from 'tera-dls';
import { CUSTOMER_TYPE } from '../../constants';

interface IProps {
  open: boolean;
  onCancel: () => void;
}

export const promoData = {
  1: 'Cloud Hosting: Host NVMe 1 GB',
  2: 'Cloud Hosting: Host NVMe 2 GB',
  3: 'Cloud Hosting: Host NVMe 4 GB',
  4: 'Cloud Hosting: Host NVMe 6 GB',
  5: 'Cloud Hosting: Host NVMe 8 GB',
  6: 'VPS Pro: VPS 30 GB SSD',
  7: 'VPS Pro: VPS 100 GB SSD',
  8: 'Cloud Hosting SEO: SSD SEO 5 IP',
  9: 'Cloud Hosting SEO: SSD SEO 10 IP',
  10: 'Cloud Hosting SEO: SSD SEO 15 IP',
  11: 'Cloud Hosting Business: Business NVMe 10 GB',
  12: 'Cloud Hosting Business: Business NVMe 20 GB',
  13: 'Cloud Hosting Business: Business NVMe 40 GB',
  14: 'Unlimited Hosting: Sigma',
  15: 'Unlimited Hosting: Omega',
  16: 'Cloud Hosting: Host SSD 100MB',
  17: 'Hosting EPYC: Hosting EPYC 1G',
  18: 'Hosting EPYC: Hosting EPYC 4G',
};
const PromoCodeForm = (props: IProps) => {
  const { open, onCancel } = props;
  const form = useForm({ mode: 'onChange' });
  const {
    formState: { isDirty },
  } = form;
  const confirm = useConfirm();
  const [step, setStep] = useState<number>(1);
  const enable_max = form.watch('enable_max');
  const enable_deadline = form.watch('enable_deadline');

  useEffect(() => {
    form.setValue('type', 1);
  }, [form]);

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu cấu hình.</p>
          </>
        ),
        onOk: () => {
          onCancel();
        },
      });
      return;
    }
    onCancel();
  };

  const handleSubmitForm = (values) => {
    values;
  };

  const customerOptions = Object.entries(CUSTOMER_TYPE).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Modal
      open={open}
      title={'Tạo mã ưu đãi'}
      closeIcon={false}
      okText="Đồng ý"
      cancelText="Hủy"
      className="!w-[90%] md:!w-[70%]"
      maskClosable={false}
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCancel}
      footer={
        <div className="flex gap-2.5">
          <Button type="light" className="px-5" onClick={handleCancel}>
            Hủy
          </Button>
          {step === 2 && <Button onClick={() => setStep(1)}>Quay lại</Button>}
          {step === 1 ? (
            <Button onClick={() => setStep(2)}>Tiếp tục</Button>
          ) : (
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              Đồng ý
            </Button>
          )}
        </div>
      }
    >
      <div className="grid grid-cols-1">
        <FormTera form={form} isLoading={false}>
          {step === 1 ? (
            <div className="divide-y-[1px] divide-dotted">
              <FormTeraItem label="Chọn gói mà bạn muốn giảm giá" name="type">
                <RadioGroup
                  key={form.watch('type')}
                  listOptions={[
                    {
                      value: 1,
                      label: 'Dịch vụ Hosting, VPS 40% Hoa Hồng',
                    },
                    {
                      value: 2,
                      label: 'Dịch vụ Hosting, VPS 50% Hoa Hồng',
                    },
                  ]}
                />
              </FormTeraItem>
              <FormTeraItem label="Áp dụng cho" name="" className="pt-2.5">
                <div className="flex gap-2.5 flex-wrap">
                  {Object.entries(promoData).map(([key, value]) => (
                    <span className="text-[12px]">
                      <Tag color="green06" className="flex-1" key={key}>
                        {value}
                      </Tag>
                    </span>
                  ))}
                </div>
              </FormTeraItem>
            </div>
          ) : (
            <div className="flex flex-col gap-[9px]">
              <p>
                Trung tâm cộng tác viên của TinoHost cho phép bạn tạo mã giảm
                giá cho riêng mình. Lưu ý để dùng được mã giảm giá thì khách
                hàng phải truy cập từ link cộng tác viên của bạn.
              </p>
              <Row className=" grid-cols-1 sm:grid-cols-2 xmd:grid-cols-3">
                <FormTeraItem
                  label="Mã ưu đãi"
                  name="code"
                  rules={[{ required: messageValidate.emptyText }]}
                >
                  <Input placeholder="Vui lòng nhập" />
                </FormTeraItem>
                <FormTeraItem label="Kiểu giảm giá" name="type_discount">
                  <Select
                    placeholder="Vui lòng chọn"
                    options={[
                      {
                        value: 1,
                        label: 'Áp dụng lần đầu',
                      },
                      {
                        value: 2,
                        label: 'Áp dụng mãi mãi',
                      },
                    ]}
                  />
                </FormTeraItem>
                <FormTeraItem label="Trạng thái" name="status">
                  <Select
                    placeholder="Vui lòng chọn"
                    options={customerOptions}
                  />
                </FormTeraItem>

                <div>
                  <FormTeraItem className="" name="enable_max">
                    <CheckBox labelClassName="font-normal text-[13px]">
                      Số lần sử dụng tối đa
                    </CheckBox>
                  </FormTeraItem>
                  <FormTeraItem className="" name="max">
                    <Input placeholder="Vui lòng nhập" disabled={!enable_max} />
                  </FormTeraItem>
                </div>
                <div>
                  <FormTeraItem className="" name="enable_deadline">
                    <CheckBox labelClassName="font-normal text-[13px]">
                      Ngày hết hạn
                    </CheckBox>
                  </FormTeraItem>
                  <FormTeraItem className="" name="deadline">
                    <DatePicker
                      format={DATE_FORMAT}
                      disabled={!enable_deadline}
                    />
                  </FormTeraItem>
                </div>
                <div />
                <FormTeraItem label="" name="tip">
                  <p className="mb-1 flex gap-2">
                    Hoa hồng
                    <Tooltip
                      title={
                        <div className="w-[190px]">
                          Phần trăm hoa hồng dành cho bạn. Lưu ý sẽ tỷ lệ nghịch
                          với phần trăm giảm giá cho khách hàng
                        </div>
                      }
                      placement="top"
                      className="z-[100]"
                    >
                      <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5ZM8.16858 4.68799C7.53047 4.12964 6.46986 4.12964 5.83174 4.68799C5.60794 4.88382 5.26776 4.86114 5.07193 4.63734C4.8761 4.41354 4.89878 4.07336 5.12258 3.87753C6.16673 2.9639 7.8336 2.9639 8.87774 3.87753C9.96405 4.82804 9.96405 6.40273 8.87774 7.35324C8.69348 7.51448 8.49052 7.64655 8.27655 7.7501C7.79102 7.98508 7.53862 8.30567 7.53862 8.57692V9.11539C7.53862 9.41277 7.29755 9.65385 7.00016 9.65385C6.70278 9.65385 6.4617 9.41277 6.4617 9.11539V8.57692C6.4617 7.65864 7.22221 7.06395 7.80742 6.78073C7.93854 6.71727 8.06005 6.63774 8.16858 6.54278C8.76454 6.02132 8.76454 5.20945 8.16858 4.68799ZM7 11.8077C7.29738 11.8077 7.53846 11.5666 7.53846 11.2692C7.53846 10.9718 7.29738 10.7308 7 10.7308C6.70262 10.7308 6.46154 10.9718 6.46154 11.2692C6.46154 11.5666 6.70262 11.8077 7 11.8077Z"
                          fill="#3F83F8"
                        />
                      </svg>
                    </Tooltip>
                  </p>
                  <InputNumber max={100} min={1} placeholder="Vui lòng nhập" />
                  <span className="mt-1 inline-block">{`<`} 100</span>
                </FormTeraItem>
              </Row>
            </div>
          )}
        </FormTera>
      </div>
    </Modal>
  );
};

export default PromoCodeForm;
