import { IRouteProps } from '_common/interface/router';
import CommissionPage from '.';
import { COMMISSION_URL } from './url';

export const CommissionRouter: IRouteProps[] = [
  {
    key: COMMISSION_URL.list.key,
    path: COMMISSION_URL.list.shortenUrl,
    component: <CommissionPage />,
  },
];
