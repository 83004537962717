import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { ReactComponent as Start } from 'styles/images/wallet/star-bank-card.svg';
import { TrashOutlined } from 'tera-dls';

interface CardProps extends React.HTMLAttributes<HTMLElement> {
  image?: string;
  fullName?: string;
  bankName?: string;
  description?: string;
  cardNumber?: string;
  onUpdate?: () => void;
  onDelete?: () => void;
}

function Card(props: CardProps, ref) {
  const {
    fullName = 'Nguyễn văn a',
    bankName = 'Tên ngân hàng',
    description = 'Mô tả',
    image,
    cardNumber = '00',
    onDelete,
    className,
    ...rest
  } = props;
  const numberCard = cardNumber?.slice(-2) || '00';

  return (
    <div
      ref={ref}
      className={classNames(
        'relative h-full flex flex-col text-white rounded-2xl flex-1 overflow-hidden',
        className,
      )}
      {...rest}
    >
      <div className="relative pt-3 pb-6 px-8 bg-[#1E429F] w-full flex-1 flex flex-col justify-between gap-y-4">
        <div className="flex flex-col gap-y-2.5">
          <div>
            <span className="font-bold text-[#ffffff] text-xl max-w-[80%] line-clamp-1 break-word">
              {bankName}
            </span>
          </div>
          <span className="text-[#D0C1E8] max-w-[70%] line-clamp-3 break-word">
            {description}
          </span>
        </div>
        <div className="flex items-baseline gap-x-2">
          <div className="flex">
            <Start />
            <Start />
            <Start />
            <Start />
          </div>
          <div className="flex">
            <Start />
            <Start />
            <Start />
            <Start />
          </div>
          <div className="flex">
            <Start />
            <Start />
          </div>
          <div className="flex items-baseline text-xl">
            <Start />
            <Start />
            {numberCard}
          </div>
        </div>
        <div
          className="absolute w-[150px] h-[150px] border top-0 right-0 z-50 -translate-y-[45px] translate-x-[70px]"
          style={{ borderBottomLeftRadius: '100px' }}
        />
        <div
          className="absolute w-[150px] h-[150px] border top-0 right-0 z-50 -translate-y-[70px] translate-x-[50px] -rotate-[28deg]"
          style={{ borderBottomLeftRadius: '100px' }}
        />
      </div>
      <div className="px-8 py-4 flex items-center justify-between bg-[#082D59] border-t border-white">
        <p className="uppercase text-lg font-semibold break-word line-clamp-1">
          {fullName}
        </p>
        <div className="flex items-center gap-x-2">
          {onDelete && (
            <TrashOutlined
              className="size-6 text-red-300 cursor-pointer"
              onClick={() => onDelete && onDelete()}
            />
          )}
          {/* <PencilSquareOutlined className="size-6 text-green-300 cursor-pointer" /> */}
        </div>
      </div>
      {image && (
        <div className="w-[30px] h-[30px] absolute top-[10px] right-[20px]">
          <img src={image} alt={bankName} className="w-full" />
        </div>
      )}
    </div>
  );
}

export default forwardRef(Card);
