import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TextNavigate from '_common/component/TextNavigate';
import {
  PAYMENT_METHOD_TEXT,
  PAYMENT_METHOD_TYPE,
  TRANSACTION_TYPE_TEXT,
} from '_common/constants/invoiceManagement';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatCurrency,
  formatDate,
} from 'tera-dls';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../constants';
import { INVOICE_TRANSACTION_URL } from '../url';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const navigate = useNavigate();

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () =>
          navigate(`${INVOICE_TRANSACTION_URL.detail.path}/${record.id}`),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transaction_no',
      width: 150,
      render: (transaction_no, record) => (
        <TextNavigate
          to={`${INVOICE_TRANSACTION_URL.detail.path}/${record.id}`}
        >
          {transaction_no}
        </TextNavigate>
      ),
    },
    {
      title: 'Mã hóa đơn',
      dataIndex: 'invoice',
      width: 150,
      render: (_, record) => (
        <TextNavigate to={`${INVOICE_URL.detail.path}/${record.bill?.id}`}>
          {record.bill?.invoice_no}
        </TextNavigate>
      ),
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 150,
      render: (transaction_type) => TRANSACTION_TYPE_TEXT[transaction_type],
    },
    {
      title: 'Số tiền',
      dataIndex: 'total',
      width: 150,
      render: (total) => formatCurrency(total),
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'payment_method',
      width: 200,
      render: (payment_method, record) =>
        record.payment_method_id === 0
          ? PAYMENT_METHOD_TEXT[PAYMENT_METHOD_TYPE.WALLET]
          : PAYMENT_METHOD_TEXT[payment_method?.type.type],
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag className="font-medium" color={TABLE_STATUS_COLOR[status]}>
          {TABLE_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 150,
      render: (created_at) => formatDate(created_at),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record: any) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <TableTera
      rowKey="id"
      columns={columns}
      loading={props?.loading}
      className="center-table"
      {...props}
    />
  );
};

export default Table;
