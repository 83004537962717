import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DropdownItem, getQueryParams, updateQueryParams } from 'tera-dls';
import Searching from './containers/Searching';
import PromoCodeFilter from './containers/Filter';
import useConfirm from '_common/hooks/useConfirm';
import PromoCodeForm from './containers/Form';
import PromoCodeDetail from './containers/Detail';

const PromoCodePage = (): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>();
  const confirm = useConfirm();
  const [openForm, setOpenForm] = useState<any>();
  const [openDetail, setOpenDetail] = useState<any>({ open: false });

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleSearch = ({ keyword }) => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleDeleteAll = () => {
    confirm.warning({
      title: 'Xác nhận xóa các mục đã chọn',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xác nhận xóa</p>
          <p>các mục đã chọn này không?</p>
        </div>
      ),
      onOk: () => {
        1;
      },
    });
  };

  const handleDelete = (id: number, name: string) => {
    confirm.warning({
      title: 'Xác nhận xóa mã ửu đãi',
      content: (
        <div className="break-word">
          <p>Bạn có chắc xác nhận xóa mã ưu đãi</p>
          <p>
            <span className="font-bold">{name}</span> này không?
          </p>
        </div>
      ),
      onOk: () => {
        id;
      },
    });
  };

  const generateDropDownItems = (record: any): DropdownItem[] => {
    record;
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => {
          setOpenDetail({ open: true, value: 1 });
        },
      },
      {
        key: 5,
        label: <a className="text-red-500">Xóa</a>,
        onClick: () => handleDelete(1, 'MSRFDSFSNFSJK'),
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã ưu đãi',
      dataIndex: 'code',
      width: 150,
    },
    {
      title: 'Giảm giá',
      dataIndex: 'discount',
      width: 100,
    },
    {
      title: 'Hoa hồng',
      dataIndex: 'tip',
      width: 150,
      render: (val) => <span>{val}%</span>,
    },
    {
      title: 'Đã sử dụng',
      dataIndex: 'used',
      width: 150,
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customer',
      width: 150,
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expired_date',
      width: 150,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  const handleFilter = (values: any) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const optionItems = (): DropdownItem[] => {
    const result: DropdownItem[] = [
      {
        key: 5,
        label: <a className="text-red-500">Xóa</a>,
        onClick: () => handleDeleteAll(),
      },
    ];

    return result;
  };

  return (
    <div className="tera-page">
      <HeaderViewListV2
        title={'DANH SÁCH MÃ ƯU ĐÃI CỦA TÔI'}
        onClickFilter={() => setOpenFilter(true)}
        onClickButtonAdd={() => setOpenForm(true)}
        selectedNumber={selectedRowKeys?.length}
        optionItems={optionItems()}
        actionLeftRender={<Searching onSearch={handleSearch} />}
      >
        <TableTera
          rowKey="id"
          columns={columns}
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          data={[
            {
              id: 1,
              code: 'MGADWEDD9UL',
              discount: 2,
              tip: 38,
              used: 0,
              customer: 1,
              expired_date: moment().format(),
            },
            {
              id: 2,
              code: 'MGADWEDD9UL',
              discount: 2,
              tip: 38,
              used: 0,
              customer: 2,
              expired_date: moment().format(),
            },
          ]}
          pagination={{}}
        />
      </HeaderViewListV2>
      {openFilter && (
        <PromoCodeFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
      {openForm && (
        <PromoCodeForm open={openForm} onCancel={() => setOpenForm(false)} />
      )}
      {openDetail?.open && (
        <PromoCodeDetail
          open={openDetail?.open}
          onCancel={() => setOpenDetail({ open: false })}
          value={openDetail?.value}
        />
      )}
    </div>
  );
};

export default PromoCodePage;
