import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { useStores } from '_common/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Tabs, getQueryParams, updateQueryParams } from 'tera-dls';
import SSHKeys from './components/SSHKeys';
import Secure from './components/Secure';
import Verify from './components/Verify';
import { observer } from 'mobx-react-lite';

const SecurityPage = () => {
  const {
    authStore: { user },
  } = useStores();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateQueryParams({ securityTab: key });
  };

  const tabItems = [
    {
      key: 'security',
      label: 'Bảo mật',
    },
    {
      key: 'ssh',
      label: 'SSH Keys',
    },
    {
      key: 'verify',
      label: 'Xác thực 2 bước',
    },
  ];

  const tabData = {
    security: <Secure dataDetail={user?.account} />,
    ssh: <SSHKeys dataDetail={user?.account} />,
    verify: <Verify />,
  };

  return (
    <Col className="tera-page">
      <HeaderViewListV2 title="BẢO MẬT">
        <Col className="page-content-v2 p-4 flex flex-col gap-4">
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.securityTab || tabItems[0].key}
            className="mb-0 pt-0 bg-white rounded-t-md"
          />
          {tabData[queryParams?.securityTab || tabItems[0].key]}
        </Col>
      </HeaderViewListV2>
    </Col>
  );
};

export default observer(SecurityPage);
