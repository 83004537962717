import api from 'states/drivers';

const PaymentMethodEndpoint = `/administrator/payment-method`;

const PaymentMethodApi = {
  getListPaymentMethod: async ({ params }) =>
    await api
      .get(`${PaymentMethodEndpoint}/list`, params)
      .then((result) => result.data?.data),
  transaction: async ({ params }) =>
    await api
      .post(`${PaymentMethodEndpoint}/transaction`, params)
      .then((result) => result.data),
  transactionDetail: async ({ id }) =>
    await api
      .get(`${PaymentMethodEndpoint}/transaction-find/${id}`)
      .then((result) => result.data?.data),
  getBankList: async () =>
    await api
      .get(`${PaymentMethodEndpoint}/bank`)
      .then((result) => result.data?.data),
  getCardBankList: async ({ params }) =>
    await api
      .get(`${PaymentMethodEndpoint}/list`, params)
      .then((result) => result.data?.data),
  createCardBank: async ({ params }) =>
    await api
      .post(`${PaymentMethodEndpoint}/create`, params)
      .then((result) => result.data),
  updateCardBank: async ({ id, params }) =>
    await api
      .put(`${PaymentMethodEndpoint}/update/${id}`, params)
      .then((result) => result.data),
  detailCardBank: async ({ id }) =>
    await api
      .get(`${PaymentMethodEndpoint}/card-detail/${id}`)
      .then((result) => result.data?.data),
  deleteCardBank: async ({ id }) =>
    await api
      .delete(`${PaymentMethodEndpoint}/delete/${id}`)
      .then((result) => result.data),
  transactionList: async ({ params }) =>
    await api
      .get(`${PaymentMethodEndpoint}/transaction-all`, params)
      .then((result) => result.data?.data),
  statistical: async ({ params }) =>
    await api
      .get(`${PaymentMethodEndpoint}/statistical`, params)
      .then((result) => result.data?.data),
};

export default PaymentMethodApi;
