import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Text from '_common/component/Text';
import { TRANSACTION_TYPE } from '_common/constants/invoiceManagement';
import { messageValidate } from '_common/constants/message';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectSystemPaymentMethod from '_common/dof/Select/SelectSystemPaymentMethod';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import InvoiceAPI from 'pages/InvoiceManagement/Invoice/apis';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';
import ImageQRPlaceHolder from 'styles/images/qr-placeholder.png';
import { Col, Modal, Spin, formatCurrency, notification } from 'tera-dls';

interface IModalConfirmPaymentMethodProps {
  open: boolean;
  close: () => void;
  id?: string | number;
  callback?: () => void;
  isAll?: boolean;
}

const ModalConfirmPaymentMethod = ({
  open,
  close,
  id,
  callback,
}: IModalConfirmPaymentMethodProps) => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      payment_method: null,
      payment_method_id: null,
    },
  });
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const {
    data: dataInvoice,
    refetch: refetchDataInvoice,
    isLoading: isLoadingDataInvoice,
  } = useQuery(
    ['get-finance-invoice-detail', id],
    () => InvoiceAPI.getDetail({ id: id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDataInvoice();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataInvoice))
      form.reset({
        payment_method: dataInvoice?.payment_method,
        payment_method_id: dataInvoice?.payment_method_id,
      });
  }, [dataInvoice]);

  // const {
  //   data: dataListInvoice,
  //   refetch: refetchDataListInvoice,
  //   isLoading: isLoadingDataListInvoice,
  // } = useQuery(
  //   ['get-finance-invoice-list', isAll],
  //   () => {
  //     return InvoiceAPI.getList({
  //       params: {
  //         status: INVOICE_STATUS.UNPAID,
  //       },
  //     });
  //   },
  //   {
  //     enabled: isAll,
  //     cacheTime: 300000,
  //     staleTime: 300000,
  //   },
  // );

  // useEffect(() => {
  //   if (isAll) refetchDataListInvoice();
  // }, [isAll]);

  const {
    data: dataQR,
    refetch: refetchDataQR,
    isLoading: isLoadingDataQR,
  } = useQuery(
    ['get-payment-method-qr', form.watch('payment_method_id')],
    () =>
      PaymentMethodAPI.getVietQR({
        id: form.watch('payment_method_id'),
        params: { total: dataInvoice?.final_total },
      }),
    {
      enabled: !_.isNil(form.watch('payment_method_id')),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (form.watch('payment_method_id') && form.watch('payment_method_id') != 0)
      refetchDataQR();
  }, [form.watch('payment_method_id')]);

  const { mutate: mutatePay, isLoading: isLoadingPay } = useMutation(
    (params: any) => InvoiceAPI.pay({ id: params?.id, params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-profile']);
          callback && callback();
          close();
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const detail = [
    {
      label: 'Số tài khoản',
      value: form.watch('payment_method')?.account_number,
    },
    {
      label: 'Chủ tài khoản',
      value: form.watch('payment_method')?.account_name,
    },
    { label: 'Nội dung', value: dataInvoice?.invoice_no },
    { label: 'Số tiền', value: formatCurrency(dataInvoice?.final_total) },
  ];

  const handleSubmitForm = (values: any) => {
    if (isLoadingPay) return;
    mutatePay({
      id: id,
      payment_method_id: values.payment_method_id,
    });
  };

  const handleClose = () => {
    confirm.warning({
      title: 'Xác nhận hủy giao dịch',
      content: 'Bạn có chắc muốn hủy giao dịch này không?',
      onOk: () => close(),
    });
  };

  return (
    <Modal
      title="Thanh toán"
      centered={true}
      okText="Xác nhận thanh toán"
      cancelText="Đóng"
      open={open}
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleClose}
      className="sm:w-[90%] xl:w-[500px]"
      bodyClassName="!py-4"
      confirmLoading={isLoadingPay}
    >
      <Spin spinning={!_.isNil(id) && isLoadingDataInvoice}>
        <Col className="flex flex-col gap-4">
          <FormTera form={form}>
            <FormTeraItem
              name="payment_method_id"
              className="mb-0"
              rules={[{ required: messageValidate.emptySelect }]}
            >
              <SelectSystemPaymentMethod
                onChangeCustom={(value: any) =>
                  form.setValue('payment_method', value.valueCustom)
                }
                isIncludeWallet={
                  _.isEqual(
                    dataInvoice?.transaction_type,
                    TRANSACTION_TYPE.PAY,
                  ) ||
                  _.isEqual(dataInvoice?.transaction_type, TRANSACTION_TYPE.PAY)
                }
                paramsApi={{
                  include_id: form.watch('payment_method_id'),
                }}
                allowClear={false}
              />
            </FormTeraItem>
          </FormTera>
          <Spin
            spinning={
              !_.isNil(form.watch('payment_method_id')) &&
              form.watch('payment_method_id') != 0 &&
              isLoadingDataQR
            }
          >
            {form.watch('payment_method_id') != 0 && (
              <Col className="flex flex-col gap-4">
                <img
                  src={dataQR?.data?.qrDataURL ?? ImageQRPlaceHolder}
                  alt={dataQR?.data?.qrCode}
                  className="w-80 h-80 object-cover m-auto"
                />
                <Col className="flex flex-col gap-2">
                  {detail.map((detail, index) => (
                    <Text key={index}>
                      <span>{detail.label}:</span>
                      <span className="ml-1">{detail.value}</span>
                    </Text>
                  ))}
                </Col>
                <Text>
                  <i>
                    Quý khách vui lòng điền đúng số hóa đơn và số tiền hoặc quét
                    mã QR để thanh toán được xác nhận nhanh chóng.
                  </i>
                </Text>
              </Col>
            )}
          </Spin>
        </Col>
      </Spin>
    </Modal>
  );
};

export default ModalConfirmPaymentMethod;
