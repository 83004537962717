import { useQuery } from '@tanstack/react-query';
import ZingIcon from '_common/component/ZingIcon';
import { SERVICE_STATUS } from '_common/constants/serviceManagement';
import { useEffect } from 'react';
import { Spin } from 'tera-dls';
import DashboardAPI from '../apis';

const WidgetOverview = (): JSX.Element => {
  const {
    data: dataOverviewService,
    refetch: refetchDataOverviewService,
    isLoading: isLoadingDataOverviewService,
  } = useQuery(
    ['get-dashboard-overview-service'],
    () => {
      return DashboardAPI.getOverviewService();
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetchDataOverviewService();
  }, []);

  return (
    <Spin spinning={isLoadingDataOverviewService}>
      <div className="grid grid-cols-4 gap-4">
        <div className="bg-white p-5 rounded-[4px] drop-shadow flex justify-between items-center">
          <ZingIcon.IconLightning />
          <div className="font-bold text-[#0E9F6E] flex flex-col items-center gap-2">
            <span>
              {dataOverviewService?.status_count[SERVICE_STATUS.RUNNING]}
            </span>
            <span>Đang chạy</span>
          </div>
        </div>
        <div className="bg-white p-5 rounded-[4px] drop-shadow flex justify-between items-center">
          <ZingIcon.IconHourglass />
          <div className="font-bold text-[#C27803] flex flex-col items-center gap-2">
            <span>{dataOverviewService?.expired_soon}</span>
            <span>Sắp hết hạn</span>
          </div>
        </div>
        <div className="bg-white p-5 rounded-[4px] drop-shadow flex justify-between items-center">
          <ZingIcon.IconCloseCircle />
          <div className="font-bold text-[#6B7280] flex flex-col items-center gap-2">
            <span>
              {dataOverviewService?.status_count[SERVICE_STATUS.BLOCK]}
            </span>
            <span>Đã hủy</span>
          </div>
        </div>
        <div className="bg-white p-5 rounded-[4px] drop-shadow flex justify-between items-center">
          <ZingIcon.IconSquaresBox />
          <div className="font-bold text-[#3F83F8] flex flex-col items-center gap-2">
            <span>{dataOverviewService?.total}</span>
            <span>Tổng số</span>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default WidgetOverview;
