import {
  ArrowsRightLeftSolid,
  Button,
  formatCurrency,
  ShoppingCartSolid,
  Tag,
  TrashSolid,
} from 'tera-dls';

export interface IDomainItem {
  id: number;
  name: string;
  is_register: boolean;
  price: number;
  discount: number;
}

interface ISearchResultItemProps {
  domain: IDomainItem;
  searchKey: string;
  handleToggleDomain: (domain: IDomainItem) => void;
  isExisted: boolean;
}

const SearchResultItem = ({
  domain,
  searchKey,
  handleToggleDomain,
  isExisted,
}: ISearchResultItemProps): JSX.Element => {
  return (
    <div className="border-b-[1px] py-3 flex justify-between items-center">
      <div className="flex items-center gap-2">
        <span>{domain.name}</span>
        <span className="text-[#0E9F6E]">{searchKey}</span>
        <span className="text-[#0E9F6E]">whois</span>
        {domain.is_register && <Tag color="yellow03">ĐÃ ĐƯỢC ĐĂNG KÝ</Tag>}
      </div>
      <div className="flex items-center gap-2">
        {domain.discount > 0 ? (
          <div className="flex items-center gap-4">
            <del className="text-[20px] text-[#6B7280]">
              {formatCurrency(domain.price)}
            </del>
            <span className="text-[20px] text-[#0E9F6E]">
              {formatCurrency(domain.discount)}
            </span>
          </div>
        ) : (
          <span className="text-[20px] text-[#0E9F6E]">
            {formatCurrency(domain.price)}
          </span>
        )}
        <span>1 năm</span>
        <Button
          className={`w-[200px] ${isExisted ? 'bg-[#E05252]' : 'bg-[#0E9F6E]'}`}
          prefix={
            isExisted ? (
              <TrashSolid />
            ) : domain.is_register ? (
              <ArrowsRightLeftSolid />
            ) : (
              <ShoppingCartSolid />
            )
          }
          onClick={() => handleToggleDomain(domain)}
        >
          {isExisted
            ? 'Xóa'
            : domain.is_register
            ? 'Chuyển về ZingServer'
            : 'Đăng ký mới'}
        </Button>
      </div>
    </div>
  );
};

export default SearchResultItem;
