import { IRouteProps } from '_common/interface/router';
import SetupSecurityPage from '.';
import { SETUP_SECURITY_URL } from './url';

export const SetupSecurityRouter: IRouteProps[] = [
  {
    key: SETUP_SECURITY_URL.list.key,
    path: SETUP_SECURITY_URL.list.shortenUrl,
    component: <SetupSecurityPage />,
  },
];
