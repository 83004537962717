import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { IOpenModalState } from '_common/interface';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import SecurityAPI from 'states/api/SecurityAPI';
import {
  Button,
  Col,
  PlusOutlined,
  Row,
  TrashSolid,
  notification,
} from 'tera-dls';
import ModalCreateSSHKey from '../modals/ModalCreateSSHKey';

interface ISSHKeysProps {
  dataDetail: any;
}

const SSHKeys = ({ dataDetail }: ISSHKeysProps) => {
  const [isOpenModalCreateSSHKey, setIsOpenModalCreateSSHKey] =
    useState<IOpenModalState>({
      isOpen: false,
      id: null,
    });
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const {
    data: dataList,
    refetch: refetchDataList,
    isLoading: isLoadingDataList,
  } = useQuery(
    ['get-security-ssh-key-list', dataDetail.id],
    () => {
      return SecurityAPI.getSecuritySSHKeyList({
        params: { account_id: dataDetail.id },
      });
    },
    {
      enabled: !_.isNil(dataDetail.id),
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetchDataList();
  }, []);

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (idSSHKey) =>
      SecurityAPI.deleteSecuritySSHKey({
        id: idSSHKey,
      }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-security-ssh-key-list']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) => {
    if (isLoadingDelete) return;

    confirm.warning({
      title: 'Xác nhận xóa SSH Key',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa SSH Key</p>
          <p>
            <b>{record?.ssh_key_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(record?.id),
    });
  };

  const columns: any = [
    {
      title: 'Tên SSH Key',
      dataIndex: 'ssh_key_name',
      width: 200,
    },
    {
      title: 'SSH key in OpenSSH format',
      dataIndex: 'ssh_key_key',
      width: 200,
    },
    {
      dataIndex: 'action',
      width: 10,
      fixed: 'right',
      render: (_: any, record: any) => (
        <TrashSolid
          className="size-4 text-red-500 cursor-pointer"
          onClick={() => handleDelete(record)}
        />
      ),
    },
  ];

  return (
    <Col className="flex flex-col gap-[16px]">
      <Row className="flex justify-end">
        <Button
          prefix={<PlusOutlined className="size-4" />}
          className="bg-blue-500"
          onClick={() => setIsOpenModalCreateSSHKey({ isOpen: true })}
        >
          Thêm mới SSH Key
        </Button>
      </Row>
      <TableTera
        rowKey="id"
        data={dataList ? dataList : []}
        columns={columns}
        loading={isLoadingDataList || isLoadingDelete}
        className="center-table"
      />
      {isOpenModalCreateSSHKey.isOpen && (
        <ModalCreateSSHKey
          open={isOpenModalCreateSSHKey.isOpen}
          close={() => setIsOpenModalCreateSSHKey({ isOpen: false })}
          id={dataDetail.id}
        />
      )}
    </Col>
  );
};

export default SSHKeys;
