export const mockDataList = [
  {
    id: 1,
  },
  {
    id: 2,
    created_at: '103.250.78.65',
    expirated_at: '103.250.78.65',
    status: 0,
  },
  {
    id: 3,
    created_at: '103.250.78.65',
    expirated_at: '103.250.78.65',
    status: 2,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
  {
    id: 10,
  },
];
export const TABS_STATUS_ITEM = ['all', '0', '1', '2'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  0: 'Hết hạn',
  1: 'Đang chạy',
  2: 'Dịch vụ đã hủy',
};
export const VPS_STATUS_COLOR = {
  0: 'gray02',
  1: 'green03',
  2: 'red03',
};
export const VPS_STATUS = {
  0: 'Hết hạn',
  1: 'Đang chạy',
  2: 'Dịch vụ đã hùy',
};
export const locationOptions = [
  { value: 1, label: 'Vị trí hiện tại (FPT Hà Nội)' },
  { value: 2, label: 'VNPT - Hà Nội' },
  { value: 3, label: 'VNPT - TP.Hồ Chí Minh' },
  { value: 4, label: 'Random VN' },
];
export const osOptions = [
  { value: 1, label: 'Windows Server 2012' },
  { value: 2, label: 'Windows Server 2019' },
  { value: 3, label: 'Windows Server 2022' },
  { value: 4, label: 'CentOS 7.7' },
  { value: 5, label: 'Ubuntu 18.04' },
  { value: 6, label: 'Ubuntu 19.10' },
  { value: 7, label: 'CentOS 8.5.2111' },
  { value: 8, label: 'Windows 10 Enterprise' },
  { value: 9, label: 'Ubuntu 20.04' },
];
export const deleteOptions = [
  { value: 1, label: 'Đổi IP và giữ lại dữ liệu' },
  { value: 2, label: 'Đổi IP và xóa dữ liệu' },
];
export const servicePackages = [
  {
    id: 1,
    name: 'VPS 20 GB NVMe',
  },
  {
    id: 2,
    name: 'VPS 30 GB NVMe',
  },
  {
    id: 3,
    name: 'VPS 50 GB NVMe',
  },
  {
    id: 4,
    name: 'VPS 80 GB NVMe',
  },
  {
    id: 5,
    name: 'VPS 160 GB NVMe',
  },
  {
    id: 6,
    name: 'VPS 250 GB NVMe',
  },
  {
    id: 7,
    name: 'VPS 320 GB NVMe',
  },
  {
    id: 8,
    name: 'VPS 480 GB NVMe',
  },
];
export const osTemplateOptions = [
  {
    value: 1,
    label: (
      <div className="flex flex-col gap-1">
        <span>AlmaLinux 9 x64</span>
        <span className="text-[12px] text-gray-500">AlmaLinux</span>
      </div>
    ),
  },
  {
    value: 2,
    label: (
      <div className="flex flex-col gap-1">
        <span>AlmaLinux 8 x64</span>
        <span className="text-[12px] text-gray-500">AlmaLinux</span>
      </div>
    ),
  },
  {
    value: 3,
    label: (
      <div className="flex flex-col gap-1">
        <span>Ubuntu 22 04 x64</span>
        <span className="text-[12px] text-gray-500">Ubuntu</span>
      </div>
    ),
  },
  {
    value: 4,
    label: (
      <div className="flex flex-col gap-1">
        <span>Ubuntu 20 04 x64</span>
        <span className="text-[12px] text-gray-500">Ubuntu</span>
      </div>
    ),
  },
  {
    value: 5,
    label: (
      <div className="flex flex-col gap-1">
        <span>Ubuntu 18 04 x64</span>
        <span className="text-[12px] text-gray-500">Ubuntu</span>
      </div>
    ),
  },
  {
    value: 6,
    label: (
      <div className="flex flex-col gap-1">
        <span>Ubuntu 16 04 x64</span>
        <span className="text-[12px] text-gray-500">Ubuntu</span>
      </div>
    ),
  },
  {
    value: 7,
    label: (
      <div className="flex flex-col gap-1">
        <span>Debian 11 x64</span>
        <span className="text-[12px] text-gray-500">Debian</span>
      </div>
    ),
  },
  {
    value: 8,
    label: (
      <div className="flex flex-col gap-1">
        <span>Debian 10 x64</span>
        <span className="text-[12px] text-gray-500">Debian</span>
      </div>
    ),
  },
  {
    value: 9,
    label: (
      <div className="flex flex-col gap-1">
        <span>DirectAdmin (Personal Plus) (1,908,000 đ 01 năm)</span>
        <span className="text-[12px] text-gray-500">DirectAdmin</span>
      </div>
    ),
  },
  {
    value: 10,
    label: (
      <div className="flex flex-col gap-1">
        <span>Windows 10 (Non License)</span>
        <span className="text-[12px] text-gray-500">Windows</span>
      </div>
    ),
  },
  {
    value: 11,
    label: (
      <div className="flex flex-col gap-1">
        <span>Windows Server 2022 (Non License)</span>
        <span className="text-[12px] text-gray-500">Windows</span>
      </div>
    ),
  },
  {
    value: 12,
    label: (
      <div className="flex flex-col gap-1">
        <span>Windows Server 2019 (Non License)</span>
        <span className="text-[12px] text-gray-500">Windows</span>
      </div>
    ),
  },
  {
    value: 13,
    label: (
      <div className="flex flex-col gap-1">
        <span>Windows Server 2016 (Non License)</span>
        <span className="text-[12px] text-gray-500">Windows</span>
      </div>
    ),
  },
  {
    value: 14,
    label: (
      <div className="flex flex-col gap-1">
        <span>CentOS 7 (Đã lỗi thời)</span>
        <span className="text-[12px] text-gray-500">CentOS</span>
      </div>
    ),
  },
  {
    value: 15,
    label: (
      <div className="flex flex-col gap-1">
        <span>CentOS 8 (Đã lỗi thời)</span>
        <span className="text-[12px] text-gray-500">CentOS</span>
      </div>
    ),
  },
];
export const billingCycleOptions = [
  { value: 1, label: '01 tháng' },
  { value: 2, label: '03 tháng' },
  { value: 3, label: '06 tháng' },
  { value: 4, label: '01 năm - 10% discount' },
  { value: 5, label: '02 năm - 15% discount' },
  { value: 6, label: '03 năm - 20% discount' },
  { value: 7, label: '04 năm - 25% discount' },
  { value: 8, label: '05 năm - 30% discount' },
];
export const directAdminPeriodOptions = [
  { value: 1, label: '01 tháng' },
  { value: 2, label: '03 tháng' },
  { value: 3, label: '06 tháng' },
  { value: 4, label: '01 năm' },
  { value: 5, label: '02 năm' },
  { value: 6, label: '03 năm' },
  { value: 7, label: '04 năm' },
  { value: 8, label: '05 năm' },
];
