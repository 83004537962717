import { IRouteProps } from '_common/interface/router';
import SecurityPage from '.';
import { SECURITY_URL } from './url';

export const SecurityRouter: IRouteProps[] = [
  {
    key: SECURITY_URL.list.key,
    path: SECURITY_URL.list.shortenUrl,
    component: <SecurityPage />,
  },
];
