import CardDetail from '_common/component/CardDetail';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spin, Tabs, getQueryParams, updateQueryParams } from 'tera-dls';
import HistoryDetail from './containers/HistoryDetail';
import Inform from './containers/Inform';
import Tip from './containers/Tip';

const HistoryPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };
  const tabItems = [
    {
      key: 'tip',
      label: 'Hoa hồng của tôi',
    },
    {
      key: 'history',
      label: 'Lịch sử email đã gửi',
    },
    {
      key: 'inform',
      label: 'Thông báo',
    },
  ];

  const data = {
    tip: <Tip />,
    history: <HistoryDetail />,
    inform: <Inform />,
  };

  return (
    <Spin spinning={false}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div className="text-gray-700 font-semibold text-base uppercase">
                Danh sách lịch sử
              </div>
            </div>
          </div>
        </div>
        <div className="page-content-v2 !px-0  h-full mx-2.5 !mb-2.5 !gap-y-0">
          <CardDetail className="flex flex-col gap-4">
            <Tabs
              onChange={(tab) => handleUpdateQueryParams({ tab })}
              items={tabItems}
              activeKey={queryParams?.tab || 'tip'}
              className="mb-0 pt-0 rounded-t-md"
            />
            {data[queryParams?.tab || 'tip']}
          </CardDetail>
        </div>
      </div>
    </Spin>
  );
};

export default HistoryPage;
