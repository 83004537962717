import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { IMiddleRouterProps } from '../_common/interface/router';

const CheckAuth: React.FC<IMiddleRouterProps> = observer(({ children }) => {
  const { authStore } = useStores();
  const location = useLocation();

  if (authStore.authenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <>{children}</>;
});

export default CheckAuth;
