import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import { useState } from 'react';
import { Button, formatCurrency, FunnelOutlined, Tag } from 'tera-dls';
import TipFilter from './Filter';
import { DATE_FORMAT } from '_common/constants/common';
import Searching from './Searching';

const Tip = () => {
  const [params, setParams] = useState<any>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const columnsA = [
    {
      title: 'Ngày đăng ký',
      dataIndex: 'date',
      width: 200,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
    {
      title: 'Quản lý dịch vụ',
      dataIndex: 'description',
      width: 200,
    },
    {
      title: 'Hoa hồng',
      dataIndex: 'tip',
      width: 200,
      render: (val) => (
        <span className="text-green-500"> + {formatCurrency(val)}</span>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (val) =>
        val === 'success' ? (
          <Tag color="green03">Hoàn thành</Tag>
        ) : (
          <Tag color="yellow03">Đang chờ</Tag>
        ),
    },
  ];

  const handleSearch = ({ keyword }) => {
    setParams((prev = {}) => ({ ...prev, keyword }));
  };
  return (
    <>
      <div className="flex flex-col gap-[16px] ">
        <p>Dưới đây là danh sách các đơn hàng mà bạn đã giới thiệu</p>
        <div className="flex justify-end flex-col shadow bg-white rounded-md py-2 gap-2">
          <div className="flex justify-end pr-2 gap-2.5">
            <Searching onSearch={handleSearch} />
            <Button
              type="alternative"
              className="rounded-xsm py-1 px-1 bg-blue-50"
              icon={
                <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0" />
              }
              onClick={() => setOpenFilter(true)}
            />
          </div>
          <TableTera
            columns={columnsA}
            className="center-table"
            wrapperClassName="shadow-none"
            pagination={{}}
            data={[
              {
                id: 1,
                date: moment().format(),
                description: 'Dịch vụ hosting',
                tip: 50000,
                status: 'success',
              },
              {
                id: 2,
                date: moment().format(),
                description: 'Nạp tiền',
                tip: 50000,
                status: 'success1',
              },
            ]}
          />
        </div>
      </div>
      {openFilter && (
        <TipFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValue={{ time: params?.time, service: params?.service }}
          onFilter={(val) => setParams((prev = {}) => ({ ...prev, ...val }))}
        />
      )}
    </>
  );
};

export default Tip;
