import ZingIcon from '_common/component/ZingIcon';

const menu = {
  groupMenu: [
    {
      id: 1,
      code: 'welcome',
      title: 'ZING VPS',
      path: '',
      icon: '',
      key: 'welcome',
      permission: '',
    },
  ],
  parentGroup: [
    {
      id: 1,
      title: 'Quản lý dịch vụ',
      key: 'service',
      parentKey: 'welcome',
    },
  ],
  parentMenu: [
    {
      id: 1,
      title: 'Tổng quan',
      path: '/welcome/login',
      icon: '',
      iconNode: <ZingIcon.IconMenuHouse />,
      key: 'login',
      parentKey: 'welcome',
      parentGroupKey: '',
      permission: '',
    },
    {
      id: 2,
      title: 'Tên miền',
      path: '/welcome/service/domain',
      icon: '',
      iconNode: <ZingIcon.IconMenuWorld />,
      key: 'service-domain',
      parentKey: 'welcome',
      parentGroupKey: 'service',
      permission: '',
    },
    {
      id: 3,
      title: 'Email và Bảo mật',
      path: '/welcome/service/email-security',
      icon: '',
      iconNode: <ZingIcon.IconMenuMail />,
      key: 'service-email-security',
      parentKey: 'welcome',
      parentGroupKey: 'service',
      permission: '',
    },
    {
      id: 4,
      title: 'Web Hosting',
      path: '/welcome/service/hosting',
      icon: '',
      iconNode: <ZingIcon.IconMenuHosting />,
      key: 'service-hosting',
      parentKey: 'welcome',
      parentGroupKey: 'service',
      permission: '',
    },
    {
      id: 5,
      title: 'Cloud Server',
      path: '/welcome/service/cloud-server',
      icon: '',
      iconNode: <ZingIcon.IconMenuDatabase />,
      key: 'service-cloud-server',
      parentKey: 'welcome',
      parentGroupKey: 'service',
      permission: '',
    },
    {
      id: 6,
      title: 'SSL',
      path: '/welcome/service/ssl',
      icon: '',
      iconNode: <ZingIcon.IconMenuLock />,
      key: 'service-ssl',
      parentKey: 'welcome',
      parentGroupKey: 'service',
      permission: '',
    },
    {
      id: 7,
      title: 'Cloud VPS',
      path: '/welcome/service/cloud-vps',
      icon: '',
      iconNode: <ZingIcon.IconMenuCloud />,
      key: 'service-cloud-vps',
      parentKey: 'welcome',
      parentGroupKey: 'service',
      permission: '',
    },
  ],
  subMenu: [],
};

export default menu;
