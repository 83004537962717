function Sumi(props) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 15.1125C0 6.82823 6.60323 0 14.8875 0C23.1718 0 30 6.60323 30 14.8875C30 23.1718 23.3968 30 15.1125 30C6.82823 30 0 23.3968 0 15.1125Z"
        fill="#1975A7"
      />
      <path
        d="M8.93073 7.73438C12.9347 7.73438 16.9387 7.73438 21.0641 7.73438C21.0641 10.4038 21.0641 10.4038 20.475 11.0563C20.3916 11.1371 20.3083 11.2178 20.2224 11.301C20.0493 11.4889 19.8763 11.6769 19.7036 11.8651C19.6175 11.9552 19.5313 12.0454 19.4424 12.1382C19.0866 12.5198 18.7716 12.928 18.4557 13.3427C17.934 14.0139 17.4016 14.6758 16.8641 15.3344C16.1982 16.1504 15.5428 16.9738 14.8948 17.8042C14.4219 18.4063 13.9423 19.0032 13.4641 19.601C16.0161 19.645 18.5681 19.689 21.1974 19.7344C21.1974 20.7024 21.1974 21.6704 21.1974 22.6677C17.0614 22.6677 12.9254 22.6677 8.66406 22.6677C8.66406 20.0692 8.66406 20.0692 9.52367 19.1295C9.58632 19.0708 9.64898 19.012 9.71354 18.9516C10.1351 18.5394 10.4907 18.0945 10.8474 17.626C11.3774 16.9436 11.9176 16.2703 12.4641 15.601C13.2542 14.6325 14.0275 13.6525 14.7974 12.6677C15.2814 12.0517 15.7654 11.4357 16.2641 10.801C13.8441 10.757 11.4241 10.713 8.93073 10.6677C8.93073 9.69971 8.93073 8.73171 8.93073 7.73438Z"
        fill="#F7F7F5"
      />
    </svg>
  );
}

export default Sumi;
