import { useMutation, useQueryClient } from '@tanstack/react-query';
import affiliateBackground from 'styles/images/affiliates-background.png';
import affiliate from 'styles/images/affiliates.png';
import { Button, Spin, notification } from 'tera-dls';
import AffiliatesAPI from '../apis';

const AffiliatesWelcome = (): JSX.Element => {
  const queryClient = useQueryClient();

  const { mutate: mutateJoinAffiliates, isLoading: isLoadingJoinAffiliates } =
    useMutation(() => AffiliatesAPI.join(), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-profile']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    });

  const handleSubmitForm = () => {
    if (isLoadingJoinAffiliates) return;
    mutateJoinAffiliates();
  };

  return (
    <Spin spinning={isLoadingJoinAffiliates}>
      <div
        className="w-full h-[calc(100vh-45px)]"
        style={{
          background: `url(${affiliateBackground})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundColor: 'white',
        }}
      >
        <div className="p-2.5 h-full">
          <p className="text-gray-700 font-semibold text-base uppercase">
            AFFILIATES
          </p>
          <div className="flex tracking-[1px] text-black items-center h-[calc(100%-24px)] justify-between px-[24px]">
            <div className="flex flex-col gap-[50px] max-w-[900px]">
              <div className="flex flex-col">
                <h2 className="text-[80px] leading-[120px] xmd:text-[100px] font-[PaytoneOne] xmd:leading-[140px] text-blue-500  font-[1000] tracking-[10px]">
                  Affiliates
                </h2>
                <p className="text-[20px] leading-[30px]">
                  Hoa hồng lên đến 40% giá trị cho mỗi đơn hàng giới thiệu!
                </p>
              </div>
              <div className="flex flex-col gap-[16px]">
                <p>
                  Giới thiệu người dùng sử dụng dịch vụ của chúng tôi để nhận
                  ngay 30% hoa hồng, tại sao không?
                </p>
                <p className="leading-[21px]">
                  Nếu bạn là webmaster, blogger, hay đơn thuần bạn có nhiều mối
                  quan hệ và họ đang cần tìm một nhà cung cấp tin tưởng, hãy
                  tham gia ngay chương trình tiếp thị liên kết (Affiliates) để
                  tăng thu nhập lên đến hơn hàng trăm triệu đồng mỗi tháng.
                </p>
              </div>
              <div>
                <Button
                  className="flex gap-[8px] bg-blue-500"
                  onClick={handleSubmitForm}
                >
                  <span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.9789 11.1949C13.9779 11.5831 13.8963 11.9669 13.7393 12.3219C13.5822 12.677 13.3531 12.9955 13.0664 13.2573C12.7798 13.5192 12.4419 13.7186 12.0741 13.843C11.7063 13.9674 11.3167 14.014 10.93 13.9799C10.5432 13.9458 10.1678 13.8317 9.82748 13.6449C9.48714 13.4581 9.18933 13.2026 8.95292 12.8947C8.7165 12.5867 8.54665 12.233 8.45411 11.856C8.36158 11.4789 8.34838 11.0868 8.41537 10.7044L4.98195 9.14882C4.71956 9.47432 4.38768 9.73702 4.01063 9.91766C3.63357 10.0983 3.22088 10.1923 2.80279 10.1929C2.05944 10.1929 1.34654 9.89756 0.820917 9.37194C0.295293 8.84631 0 8.13341 0 7.39007C0 6.64672 0.295293 5.93382 0.820917 5.4082C1.34654 4.88257 2.05944 4.58728 2.80279 4.58728C3.1871 4.58852 3.56705 4.66877 3.91903 4.82307C4.271 4.97736 4.58749 5.20239 4.84882 5.48417L8.46442 3.52222C8.40117 3.29412 8.3705 3.05822 8.37333 2.82152C8.37261 2.16943 8.59928 1.53749 9.01432 1.03453C9.42936 0.531566 10.0068 0.189062 10.6472 0.0659955C11.2875 -0.0570705 11.9508 0.0470042 12.5227 0.360298C13.0946 0.673591 13.5394 1.17649 13.7804 1.78241C14.0214 2.38832 14.0437 3.05931 13.8432 3.67985C13.6428 4.30038 13.2323 4.83161 12.6824 5.18205C12.1325 5.5325 11.4775 5.68023 10.8304 5.59981C10.1833 5.51938 9.5845 5.21583 9.13709 4.74143L5.52149 6.71039C5.58455 6.93882 5.61539 7.17425 5.61258 7.41109C5.61298 7.5755 5.59891 7.73963 5.57054 7.90158L9.00395 9.45712C9.36705 9.00077 9.86346 8.66904 10.424 8.50816C10.9846 8.34728 11.5813 8.36526 12.1312 8.55959C12.6811 8.75393 13.1566 9.11494 13.4916 9.59233C13.8265 10.0697 14.0042 10.6397 13.9999 11.2229L13.9789 11.1949Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  Tham gia ngay
                </Button>
              </div>
            </div>
            <img
              className=" hidden xmd:block w-[350px] -scale-x-100 mr-[8%]"
              src={affiliate}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AffiliatesWelcome;
