import ZingIcon from '_common/component/ZingIcon';
import { Button, ChevronDoubleRightSolid } from 'tera-dls';

const Footer = (): JSX.Element => {
  return (
    <div className="w-full bg-[#1F2937] p-10 mt-[10rem] flex justify-center items-center">
      <div className="w-[1020px] text-white flex flex-col gap-8">
        <div className="grid grid-cols-3">
          <div className="flex flex-col gap-4">
            <span>TRUNG TÂM TRỢ GIÚP</span>
            <div className="flex flex-col items-start gap-1">
              <Button className="bg-[#3f83f8] flex items-center gap-2">
                <span>Telegram</span>
                <ZingIcon.IconTelegram />
              </Button>
              <Button className="bg-[#3f83f8] flex items-center gap-2">
                <span>Skype</span>
                <ZingIcon.IconTelegram />
              </Button>
              <Button className="bg-[#3f83f8] flex items-center gap-2">
                <span>Messenger</span>
                <ZingIcon.IconMessenger />
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <span>HƯỚNG DẪN CƠ BẢN</span>
            <div className="flex flex-col items-start gap-1">
              <Button
                className="bg-[#3f83f8]"
                prefix={<ChevronDoubleRightSolid />}
              >
                Hướng dẫn đăng nhập VPS
              </Button>
              <Button
                className="bg-[#0E9F6E]"
                prefix={<ChevronDoubleRightSolid />}
              >
                Thuê Proxy giá rẻ, chất lượng cao
              </Button>
              <Button
                className="bg-[#FACA15]"
                prefix={<ChevronDoubleRightSolid />}
              >
                Check IP online
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <span>ZINGSERVER.COM</span>
            <span>
              Dịch vụ Cloud VPS Việt Nam, VPS US, VPS Châu Âu, VPS Châu Á chất
              lượng cao.
            </span>
          </div>
        </div>
        <div className="flex justify-center">
          <span>
            ZingServer.com © 2022 - Đơn vị chủ quản Công ty TNHH CNĐT Đám Mây
            Xanh - MST 5100488157
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
