import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const ServiceAPIEndpoint = `${endpoint}/portal/service`;

const ServiceAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${ServiceAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${ServiceAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  reboot: async ({ id }) =>
    await api
      .post(`${ServiceAPIEndpoint}/detail/${id}/reboot`)
      .then((res) => res.data),
  runDiag: async ({ id }) =>
    await api
      .post(`${ServiceAPIEndpoint}/detail/${id}/diag`)
      .then((res) => res.data),
  changeIP: async ({ id, params }) =>
    await api
      .post(`${ServiceAPIEndpoint}/detail/${id}/change-ip`, params)
      .then((res) => res.data),
  manualRenew: async ({ id, params }) =>
    await api
      .post(`${ServiceAPIEndpoint}/detail/${id}/manualrenew`, params)
      .then((res) => res.data),
  updateNote: async ({ id, params }) =>
    await api
      .post(`${ServiceAPIEndpoint}/detail/${id}/note`, params)
      .then((res) => res.data),
  cancel: async ({ id }) =>
    await api
      .post(`${ServiceAPIEndpoint}/detail/${id}/cancel`)
      .then((res) => res.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${ServiceAPIEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
};

export default ServiceAPI;
