import React from 'react';
import customTwMerge from 'tailwind-merge.config';

interface IBreadcrumbTagProps {
  items: Array<ItemType>;
  className?: string;
  containerClassName?: string;
  tagClassName?: string;
  innerTagClassName?: string;
}
interface ItemType {
  title?: React.ReactElement | string;
  onClick?: () => void;
}

const BreadcrumbTag = ({
  items,
  className,
  containerClassName,
  tagClassName,
  innerTagClassName,
}: IBreadcrumbTagProps): JSX.Element => {
  return (
    <div className={customTwMerge('w-full', className)}>
      <div className={customTwMerge('h-[56px] flex', containerClassName)}>
        {items.map((item, index) => {
          let itemClassName = 'flex-1 px-[28px] drop-shadow relative';
          const arrowLeft = `px-0 pl-[14px] before:content-[''] before:border-white before:border-l-[28px] before:border-y-[28px] before:border-l-transparent before:absolute before:top-0 before:left-[-14px] ${
            item.onClick && 'before:border-blue-100 before:border-l-transparent'
          }`;
          const arrowRight = `px-0 pr-[14px] after:content-[''] after:border-white after:border-l-[28px] after:border-y-[28px] after:border-y-transparent after:absolute after:top-0 after:right-[-14px] ${
            item.onClick && 'after:border-blue-100'
          }`;

          if (index === 0)
            itemClassName = customTwMerge(itemClassName, arrowRight);
          if (index === items.length - 1)
            itemClassName = customTwMerge(itemClassName, arrowLeft);
          if (index > 0 && index < items.length - 1)
            itemClassName = customTwMerge(
              itemClassName,
              arrowRight,
              arrowLeft,
              'px-[28px] before:left-0 after:right-0',
            );

          return (
            <div
              key={index}
              className={customTwMerge(itemClassName, tagClassName)}
              onClick={item.onClick}
            >
              <div
                className={customTwMerge(
                  `h-full text-gray-500 bg-white flex justify-center items-center ${
                    item.onClick && 'text-blue-500 bg-blue-100 cursor-pointer'
                  }`,
                  innerTagClassName,
                )}
              >
                {item.title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BreadcrumbTag;
