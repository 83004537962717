import { IRouteProps } from '_common/interface/router';
import ListPage from '.';
import CreateUpdatePage from './pages/CreateUpdate';
import DetailPage from './pages/Detail';
import { SERVICE_DOMAIN_URL } from './url';

export const ServiceDomainRouter: IRouteProps[] = [
  {
    key: SERVICE_DOMAIN_URL.list.key,
    path: SERVICE_DOMAIN_URL.list.shortenUrl,
    component: <ListPage />,
  },
  {
    key: SERVICE_DOMAIN_URL.create.key,
    path: SERVICE_DOMAIN_URL.create.shortenUrl,
    component: <CreateUpdatePage />,
  },
  {
    key: SERVICE_DOMAIN_URL.detail.key,
    path: SERVICE_DOMAIN_URL.detail.shortenUrl,
    component: <DetailPage />,
  },
  {
    key: SERVICE_DOMAIN_URL.update.key,
    path: SERVICE_DOMAIN_URL.update.shortenUrl,
    component: <CreateUpdatePage />,
  },
];
