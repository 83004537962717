import CopyClipboardText from '_common/component/CopyClipboardText';
import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import { IOpenModalState } from '_common/interface';
import _ from 'lodash';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import customTwMerge from 'tailwind-merge.config';
import {
  Button,
  ChevronDoubleRightSolid,
  CloudArrowUpSolid,
  formatCurrency,
} from 'tera-dls';
import { CLOUD_VPS_STATUS } from '../constants';
import ModalChangeIP from '../modals/ModalChangeIP';
import ModalExtend from '../modals/ModalExtend';
import ModalNote from '../modals/ModalNote';
import { SERVICE_CLOUD_VPS_URL } from '../url';

interface ITableRowExpandProps {
  record: any;
  handleReboot: (record: any) => void;
  handleDiag: (record: any) => void;
  handleRequestCancel: (record: any) => void;
}

const TableRowExpand = ({
  record,
  handleReboot,
  handleDiag,
  handleRequestCancel,
}: ITableRowExpandProps): JSX.Element => {
  const [isOpenModalChangeIP, setIsOpenModalChangeIP] =
    useState<IOpenModalState>({
      isOpen: false,
      id: null,
    });
  const [isOpenModalExtend, setIsOpenModalExtend] = useState<IOpenModalState>({
    isOpen: false,
    id: null,
  });
  const [isOpenModalNote, setIsOpenModalNote] = useState<IOpenModalState>({
    isOpen: false,
    id: null,
  });
  const navigate = useNavigate();

  const configDetails = [
    {
      icon: <ZingIcon.IconRam className="text-blue-500" />,
      label: 'RAM',
      value: record.ram,
      unit: 'GB',
    },
    {
      icon: <ZingIcon.IconCPU className="text-blue-500" />,
      label: 'vCPU',
      value: record.cpu,
      unit: '',
    },
    {
      icon: <ZingIcon.IconCPUStorage className="text-blue-500" />,
      label: 'GB SSD',
      value: record.ssd,
      unit: '',
    },
    {
      icon: <ZingIcon.IconNetwork className="text-blue-500" />,
      label: 'Mbps',
      value: '',
      unit: '',
    },
    {
      icon: <ZingIcon.IconWifiVertical className="text-blue-500" />,
      label: 'Mbps',
      value: record.bandwidth,
      unit: '',
    },
    {
      icon: <ZingIcon.IconDollarCard className="text-blue-500" />,
      label: '',
      value: (
        <span className="text-red-500">
          {formatCurrency(record.product[record.period])}
        </span>
      ),
      unit: '/ tháng',
    },
  ];

  const credentialDetails = [
    {
      icon: <ZingIcon.IconLocationSolid color="#3f83f8" />,
      label: 'IP Port:',
      value: record?.ip,
    },
    {
      icon: <ZingIcon.IconShield />,
      label: 'Username:',
      value: record?.userName,
    },
    {
      icon: <ZingIcon.IconKey />,
      label: 'Password:',
      value: record?.defaultPassword,
    },
  ];

  const ControlPanels = [
    {
      name: 'Restart',
      icon: <ZingIcon.IconArrowRestart />,
      onClick: () => handleReboot(record),
      className: 'bg-blue-500',
      isShow: true,
    },
    {
      name: 'Thay đổi IP',
      icon: <ZingIcon.IconLocationSolid />,
      onClick: () => setIsOpenModalChangeIP({ isOpen: true, id: record.id }),
      className: 'bg-red-500 hover:bg-red-700',
      isShow: true,
    },
    {
      name: 'Gia hạn',
      icon: <ZingIcon.IconSquarePlus />,
      onClick: () => setIsOpenModalExtend({ isOpen: true, id: record.id }),
      className: 'bg-green-500 hover:bg-green-700',
      isShow: true,
    },
    {
      name: 'Nâng cấp',
      icon: <CloudArrowUpSolid />,
      onClick: () =>
        navigate(`${SERVICE_CLOUD_VPS_URL.upgrade.path}/${record?.id}`),
      className: 'bg-yellow-500 hover:bg-yellow-700',
      isShow: true,
    },
    {
      name: 'Hệ điều hành',
      icon: <ZingIcon.IconRecycle />,
      onClick: () =>
        navigate(`${SERVICE_CLOUD_VPS_URL.reinstallOS.path}/${record?.id}`),
      className: 'bg-indigo-500 hover:bg-indigo-700',
      isShow: true,
    },
    {
      name: 'Khắc phục sự cố',
      icon: <ZingIcon.IconTools />,
      onClick: () => handleDiag(record),
      className: 'bg-gray-500 hover:bg-gray-700',
      isShow: true,
    },
    {
      name: 'Thông tin chi tiết',
      icon: <ChevronDoubleRightSolid />,
      onClick: () =>
        navigate(`${SERVICE_CLOUD_VPS_URL.detail.path}/${record?.id}`),
      className: 'bg-purple-500 hover:bg-purple-700',
      isShow: true,
    },
    {
      name: 'Ghi chú',
      icon: <ZingIcon.IconNote />,
      onClick: () => setIsOpenModalNote({ isOpen: true, id: record.id }),
      className: 'bg-blue-500',
      isShow: true,
    },
    {
      name: 'Yêu cầu hủy dịch vụ',
      icon: <ZingIcon.IconClose />,
      onClick: () => handleRequestCancel(record),
      className: 'bg-red-500 hover:bg-red-700',
      isShow:
        _.isEqual(record.status, CLOUD_VPS_STATUS.RUNNING) &&
        !_.isEqual(record?.is_request_cancel, 1),
    },
    {
      name: 'Đã yêu cầu hủy dịch vụ',
      isDisable: true,
      isShow: _.isEqual(record?.is_request_cancel, 1),
    },
  ];

  const ExpandSections = [
    {
      name: 'Cấu hình:',
      icon: <ZingIcon.IconVPS color="#0E9F6E" />,
      content: configDetails.map((config, index) => (
        <IconText key={index} prefix={config.icon}>
          {config.value && <span className="font-[500]">{config.value}</span>}
          {config.unit && <span>{config.unit}</span>}
          {config.label && <span>{config.label}</span>}
        </IconText>
      )),
    },
    {
      name: 'Credentials:',
      icon: <ZingIcon.IconPersonShield />,
      content: credentialDetails.map((credential, index) => (
        <IconText key={index} prefix={credential.icon}>
          <span className="text-blue-500">{credential.label}</span>
          <CopyClipboardText
            valueCopy={credential.value}
            tooltipPlacement="top"
          >
            <u className="text-black font-[500]">{credential.value}</u>
          </CopyClipboardText>
        </IconText>
      )),
    },
    {
      name: 'Control Panel:',
      icon: <ZingIcon.IconSettings color="#0e9f6e" />,
      content: (
        <div className="flex flex-wrap gap-2">
          {ControlPanels.map(
            (control, index) =>
              control.isShow && (
                <Button
                  key={index}
                  className={customTwMerge('min-h-[36px]', control.className)}
                  prefix={control.icon}
                  onClick={control.onClick}
                  disabled={control.isDisable}
                >
                  {control.name}
                </Button>
              ),
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="bg-[#F9FAFB] px-4 py-2 flex flex-col gap-2">
      {ExpandSections.map((section, index) => (
        <Fragment key={index}>
          <IconText prefix={section.icon}>
            <span className="font-bold">{section.name}</span>
          </IconText>
          <div className="flex gap-4">{section.content}</div>
        </Fragment>
      ))}
      {isOpenModalChangeIP.isOpen && (
        <ModalChangeIP
          open={isOpenModalChangeIP.isOpen}
          close={() => setIsOpenModalChangeIP({ isOpen: false })}
          id={isOpenModalChangeIP.id}
        />
      )}
      {isOpenModalExtend.isOpen && (
        <ModalExtend
          open={isOpenModalExtend.isOpen}
          close={() => setIsOpenModalExtend({ isOpen: false })}
          id={isOpenModalExtend.id}
        />
      )}
      {isOpenModalNote.isOpen && (
        <ModalNote
          open={isOpenModalNote.isOpen}
          close={() => setIsOpenModalNote({ isOpen: false })}
          id={isOpenModalNote.id}
        />
      )}
    </div>
  );
};

export default TableRowExpand;
