import { DATE_FORMAT } from '_common/constants/common';
import moment from 'moment';
import { Button, Description, Modal } from 'tera-dls';

interface IProps {
  open: boolean;
  onCancel: () => void;
  value?: any;
}

const PromoCodeDetail = (props: IProps) => {
  const { open, onCancel } = props;

  return (
    <Modal
      open={open}
      title={'Chi tiết danh mục sản phẩm'}
      closeIcon={false}
      okText="Đồng ý"
      cancelText="Hủy"
      width={500}
      maskClosable={false}
      footer={<Button onClick={onCancel}>Đóng</Button>}
    >
      <Description label="Mã ưu đãi">MSDFIUJFNF</Description>
      <Description label="Giảm giá">2%</Description>
      <Description label="Hoa hồng">38%</Description>
      <Description label="Đã sử dụng">0</Description>
      <Description label="Khách hàng">Khách hàng mới</Description>
      <Description label="Ngày hết hạn">
        {moment().format(DATE_FORMAT)}
      </Description>
    </Modal>
  );
};

export default PromoCodeDetail;
