import { useQuery } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import IconText from '_common/component/IconText';
import NoData from '_common/component/NoData';
import ZingIcon from '_common/component/ZingIcon';
import useConfirm from '_common/hooks/useConfirm';
import { IOpenModalState } from '_common/interface';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ServiceAPI from 'states/api/ServiceAPI';
import customTwMerge from 'tailwind-merge.config';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  CloudArrowUpSolid,
  Spin,
} from 'tera-dls';
import { CLOUD_VPS_STATUS } from '../../constants';
import ModalChangeIP from '../../modals/ModalChangeIP';
import ModalExtend from '../../modals/ModalExtend';
import { SERVICE_CLOUD_VPS_URL } from '../../url';
import LoginInformation from './components/LoginInformation';
import Overview from './components/Overview';

const DetailPage = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState(1);
  const [isOpenModalChangeIP, setIsOpenModalChangeIP] =
    useState<IOpenModalState>({
      isOpen: false,
      id: null,
    });
  const [isOpenModalExtend, setIsOpenModalExtend] = useState<IOpenModalState>({
    isOpen: false,
    id: null,
  });
  const { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud VPS
        </span>
      ),
      onClick: () => navigate(SERVICE_CLOUD_VPS_URL.list.path),
    },
    {
      title: 'Chi tiết Cloud VPS',
    },
  ];

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-cloud-vps-detail', id],
    () => ServiceAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const ControlPanels = [
    {
      name: 'Restart',
      icon: <ZingIcon.IconArrowRestart />,
      onClick: () =>
        confirm.warning({
          title: 'Xác nhận restart VPS',
          content: (
            <div className="break-word">
              <p>Bạn có chắc muốn xác nhận restart VPS</p>
              <p>
                <b>Tên VPS</b> này không?
              </p>
            </div>
          ),
        }),
      className: 'bg-blue-500',
      isShow: true,
    },
    {
      name: 'Thay đổi IP',
      icon: <ZingIcon.IconLocationSolid />,
      onClick: () => setIsOpenModalChangeIP({ isOpen: true, id: id }),
      className: 'bg-red-500 hover:bg-red-700',
      isShow: true,
    },
    {
      name: 'Gia hạn',
      icon: <ZingIcon.IconSquarePlus />,
      onClick: () => setIsOpenModalExtend({ isOpen: true, id: id }),
      className: 'bg-green-500 hover:bg-green-700',
      isShow: true,
    },
    {
      name: 'Nâng cấp',
      icon: <CloudArrowUpSolid />,
      onClick: () => navigate(`${SERVICE_CLOUD_VPS_URL.upgrade.path}/${id}`),
      className: 'bg-yellow-500 hover:bg-yellow-700',
      isShow: true,
    },
    {
      name: 'Hệ điều hành',
      icon: <ZingIcon.IconRecycle />,
      onClick: () =>
        navigate(`${SERVICE_CLOUD_VPS_URL.reinstallOS.path}/${id}`),
      className: 'bg-indigo-500 hover:bg-indigo-700',
      isShow: true,
    },
    {
      name: 'Khắc phục sự cố',
      icon: <ZingIcon.IconTools />,
      onClick: () =>
        confirm.warning({
          title: 'Xác nhận khắc phục sự cố VPS',
          content: (
            <div className="break-word">
              <p>Bạn có chắc muốn xác nhận khắc phục sự cố VPS</p>
              <p>
                <b>Tên VPS</b> này không?
              </p>
            </div>
          ),
        }),
      className: 'bg-gray-500 hover:bg-gray-700',
      isShow: true,
    },
    {
      name: 'Yêu cầu hủy dịch vụ',
      icon: <ZingIcon.IconClose />,
      className: 'bg-red-500 hover:bg-red-700',
      isShow: _.isEqual(dataDetail?.status, CLOUD_VPS_STATUS.RUNNING),
    },
    {
      name: 'Đã yêu cầu hủy dịch vụ',
      isDisable: true,
      isShow: _.isEqual(dataDetail?.is_request_cancel, 1),
    },
  ];

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <Spin spinning={isLoadingDetail}>
        {isErrorDetail || _.isEmpty(dataDetail) ? (
          <NoData />
        ) : (
          <div className="p-2">
            <CardDetail className="p-4 flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <IconText prefix={<ZingIcon.IconSettings color="#0e9f6e" />}>
                  <span className="font-bold">Control Panel:</span>
                </IconText>
                <div className="flex flex-wrap gap-3">
                  {ControlPanels.map(
                    (control, index) =>
                      control.isShow && (
                        <Button
                          key={index}
                          className={customTwMerge(
                            'min-h-[36px]',
                            control.className,
                          )}
                          prefix={control.icon}
                          onClick={control.onClick}
                          disabled={control.isDisable}
                        >
                          {control.name}
                        </Button>
                      ),
                  )}
                </div>
              </div>
              <CardDetail className="p-4 flex gap-10">
                <div className="w-[300px] flex flex-col gap-2">
                  <span
                    className={`p-2 px-[1rem] rounded-[4px] cursor-pointer ${
                      activeTab === 1 && 'text-white bg-blue-500'
                    }`}
                    onClick={() => setActiveTab(1)}
                  >
                    Tổng quan
                  </span>
                  <span
                    className={`p-2 px-[1rem] rounded-[4px] cursor-pointer ${
                      activeTab === 2 && 'text-white bg-blue-500'
                    }`}
                    onClick={() => setActiveTab(2)}
                  >
                    Thông tin đăng nhập
                  </span>
                </div>
                {activeTab === 1 && <Overview dataDetail={dataDetail} />}
                {activeTab === 2 && (
                  <LoginInformation dataDetail={dataDetail} />
                )}
              </CardDetail>
            </CardDetail>
          </div>
        )}
      </Spin>
      {isOpenModalChangeIP.isOpen && (
        <ModalChangeIP
          open={isOpenModalChangeIP.isOpen}
          close={() => setIsOpenModalChangeIP({ isOpen: false })}
          id={isOpenModalChangeIP.id}
        />
      )}
      {isOpenModalExtend.isOpen && (
        <ModalExtend
          open={isOpenModalExtend.isOpen}
          close={() => setIsOpenModalExtend({ isOpen: false })}
          id={isOpenModalExtend.id}
        />
      )}
    </div>
  );
};

export default DetailPage;
