import BreadcrumbTag from '_common/component/BreadcrumbTag';
import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import { SERVICE_OPTIONS } from '_common/constants/serviceManagement';
import _ from 'lodash';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  ShoppingCartSolid,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import { SHOPPING_CART_URL } from '../url';

const Header = (): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const serviceType = useMemo(
    () => SERVICE_OPTIONS.find((service) => service.value === queryParams.cart),
    [queryParams.cart],
  );

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          {`Danh sách ${serviceType?.label}`}
        </span>
      ),
      onClick: () => navigate(serviceType?.listPath),
    },
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          {`Thêm ${serviceType?.label}`}
        </span>
      ),
      onClick: () => navigate(serviceType?.createPath),
    },
    {
      title: `Thông tin thanh toán ${serviceType?.label}`,
    },
  ];

  const itemTags = [
    {
      title: (
        <IconText prefix={serviceType?.icon} className="text-blue-500">
          {serviceType?.label}
        </IconText>
      ),
      onClick: () => navigate(serviceType?.listPath),
    },
    {
      title: (
        <IconText prefix={<ZingIcon.IconVPS />} className="text-blue-500">
          {`Cấu hình ${serviceType?.label}`}
        </IconText>
      ),
      onClick: () => navigate(serviceType?.createPath),
    },
    {
      title: (
        <IconText
          prefix={<ShoppingCartSolid className="size-4" />}
          className="text-blue-500"
        >
          Thông tin đơn hàng
        </IconText>
      ),
      onClick: () =>
        navigate(
          `${SHOPPING_CART_URL.cart.path}${updateQueryParams({
            ...queryParams,
          })}`,
        ),
    },
  ];

  return _.isEmpty(queryParams.cart) ? (
    <div className="page-header-sticky">
      <div className="page-header-v2">
        <div className="text-gray-700 font-semibold text-base">GIỎ HÀNG</div>
      </div>
    </div>
  ) : (
    <div className="page-header-sticky">
      <div className="page-header-v2">
        <div className="page-header-v2__breadcrumb">
          <div
            className="page-header__breadcrumb-back cursor-pointer"
            onClick={items[items.length - 2].onClick}
          >
            <ArrowSmallLeftSolid className="h-6 w-6" />
          </div>
          <Breadcrumb separator={'/'} items={items} />
        </div>
      </div>
      <div className="pb-4">
        <BreadcrumbTag items={itemTags} className="px-2" />
      </div>
    </div>
  );
};

export default Header;
