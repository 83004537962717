import { TRANSACTION_STATUS } from '_common/constants/invoiceManagement';

export const TABLE_STATUS_COLOR = {
  [TRANSACTION_STATUS.PROCESSING]: 'blue03',
  [TRANSACTION_STATUS.PAID]: 'green03',
  [TRANSACTION_STATUS.FAILED]: 'red03',
  [TRANSACTION_STATUS.ERROR]: 'gray03',
};
export const TABLE_STATUS_TEXT = {
  [TRANSACTION_STATUS.PROCESSING]: 'Đang xử lý',
  [TRANSACTION_STATUS.PAID]: 'Hoàn thành',
  [TRANSACTION_STATUS.FAILED]: 'Thất bại',
  [TRANSACTION_STATUS.ERROR]: 'Báo lỗi',
};
