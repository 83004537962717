import { IRouteProps } from '_common/interface/router';
import LoginPage from '.';
import { LOGIN_URL } from './url';

export const LoginRouter: IRouteProps[] = [
  {
    key: LOGIN_URL.index.key,
    path: LOGIN_URL.index.shortenUrl,
    component: <LoginPage />,
  },
];
