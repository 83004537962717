import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import Text from '_common/component/Text';
import { TRANSACTION_TYPE } from '_common/constants/invoiceManagement';
import { IOpenModalState } from '_common/interface';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Col,
  PaginationProps,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import InvoiceAPI from '../Invoice/apis';
import ButtonWallet from '../_common/components/ButtonWallet';
import Filter from './components/Filter';
import Table from './components/Table';
import ModalDeposit from './modals/ModalDeposit';
import { DEPOSIT_LIST_URL } from './url';

const ListPage = (): JSX.Element => {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [isOpenModalDeposit, setIsOpenModalDeposit] = useState<IOpenModalState>(
    { isOpen: false },
  );
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${DEPOSIT_LIST_URL.list.path}${paramString}`);
  };

  const handleFilter = (values: any) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const {
    data: dataList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-finance-deposit-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        transaction_type: TRANSACTION_TYPE.DEPOSIT,
      };
      return InvoiceAPI.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(dataList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  return (
    <Col className="tera-page">
      <HeaderViewList
        title="Nạp tiền"
        onClickFilter={() => setIsOpenFilter(true)}
        actionRightRender={
          <Text>
            Nạp tiền để sử dụng trong tương lai, cho các đơn đặt hàng tiếp theo
            hoặc tự động gia hạn các hoá đơn.
          </Text>
        }
        actionRight={
          <ButtonWallet
            title="Nạp tiền"
            typeBtn="recharge"
            onClick={() => setIsOpenModalDeposit({ isOpen: true })}
          />
        }
        filterCount={{
          filter: queryParams,
          params: ['created_at'],
        }}
      >
        <Table
          rowKey={'id'}
          data={dataList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: dataList?.total || 0,
            current: dataList?.current_page,
            pageSize: dataList?.per_page || 10,
            to: dataList?.to,
            from: dataList?.from || 10,
          }}
        />
      </HeaderViewList>
      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
      {isOpenModalDeposit.isOpen && (
        <ModalDeposit
          open={isOpenModalDeposit.isOpen}
          close={() => setIsOpenModalDeposit({ isOpen: false })}
        />
      )}
    </Col>
  );
};

export default ListPage;
