import { IRouteProps } from '_common/interface/router';
import ForgotPasswordPage from '.';
import { FORGOT_PASSWORD_URL } from './url';

export const ForgotPasswordRouter: IRouteProps[] = [
  {
    key: FORGOT_PASSWORD_URL.index.key,
    path: FORGOT_PASSWORD_URL.index.shortenUrl,
    component: <ForgotPasswordPage />,
  },
];
