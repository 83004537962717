export const UNAUTH_DOMAIN_URL = {
  index: {
    key: '',
    path: '/welcome/service/domain',
    shortenUrl: 'domain',
  },
  add: {
    key: '',
    path: '/welcome/service/domain/add',
    shortenUrl: 'domain/add',
  },
};
