import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox as CheckBoxTera, CheckboxProps } from 'tera-dls';

const CheckBox = ({ ...props }: CheckboxProps, ref: ForwardedRef<unknown>) => {
  const { form } = useTeraForm();
  const { item, config, rules } = useTeraFormItem();
  const { control } = form;

  const inputProps = useMemo(
    () => ({
      className: config?.class_name || props?.className,
    }),
    [config],
  );

  return (
    <Controller
      control={control}
      {...item}
      rules={rules}
      render={({ field }) => (
        <CheckBoxTera
          ref={ref}
          data-object_type={item?.object_type}
          data-object_id={item?.object_id}
          checked={field?.value}
          {...field}
          {...props}
          {...inputProps}
        />
      )}
    />
  );
};

export default forwardRef(CheckBox);
