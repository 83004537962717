import ImgVPSLogo from 'styles/images/pages/dashboard/vps-logo.png';
import { Button, Tag } from 'tera-dls';

const vpsList = [
  {
    id: 1,
    name: 'VPS Việt Nam',
    description: 'VPS Việt Nam gồm 4 Datacenter tại Hà Nội và TP.Hồ Chí Minh',
    is_new: false,
  },
  {
    id: 2,
    name: 'VPS Việt Nam',
    description: 'VPS Việt Nam gồm 4 Datacenter tại Hà Nội và TP.Hồ Chí Minh',
    is_new: true,
  },
  {
    id: 3,
    name: 'VPS Việt Nam',
    description: 'VPS Việt Nam gồm 4 Datacenter tại Hà Nội và TP.Hồ Chí Minh',
    is_new: false,
  },
  {
    id: 4,
    name: 'VPS Việt Nam',
    description: 'VPS Việt Nam gồm 4 Datacenter tại Hà Nội và TP.Hồ Chí Minh',
    is_new: false,
  },
  {
    id: 5,
    name: 'VPS Việt Nam',
    description: 'VPS Việt Nam gồm 4 Datacenter tại Hà Nội và TP.Hồ Chí Minh',
    is_new: true,
  },
  {
    id: 6,
    name: 'VPS Việt Nam',
    description: 'VPS Việt Nam gồm 4 Datacenter tại Hà Nội và TP.Hồ Chí Minh',
    is_new: false,
  },
];

const VPSServices = (): JSX.Element => {
  return (
    <div className="mt-2 flex flex-col">
      <span>
        Tại ZingServer.com, chúng tôi mong muốn mang đến cho Quý khác một dịch
        vụ VPS tốt nhất:
      </span>
      <div className="mt-[2rem] grid grid-cols-3 gap-6">
        {vpsList.map((vps) => (
          <div key={vps.id} className="drop-shadow flex flex-col items-center">
            <div
              className={`w-full h-[128px] bg-[url('styles/images/pages/dashboard/vps-background.png')] bg-center bg-cover bg-no-repeat rounded-t-[24px] `}
            />
            <div className="bg-white p-2 rounded-b-[24px] flex-1 flex flex-col justify-between items-center gap-4">
              <img
                alt="vps-logo"
                src={ImgVPSLogo}
                className="w-[90px] h-[90px] object-cover border-[6px] border-white rounded-[90px] mt-[-45px]"
              />
              {vps.is_new && (
                <Tag color="yellow02" className="!text-white">
                  MỚI
                </Tag>
              )}
              <span className="font-bold">{vps.name}</span>
              <span className="text-center">{vps.description}</span>
              <Button className="bg-yellow-300">Xem chi tiết</Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VPSServices;
