import FormSummary from '_common/component/Form/Summary';
import FormTera from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb } from 'tera-dls';
import { emailSecurityTypes } from '../../constants';
import { SERVICE_SSL_URL } from '../../url';
import FormAddBusiness from './components/FormAddComodo';
import FormAddRelay from './components/FormAddRapid';

const AddSSLPage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      product: null,
      email_security_type: 1,
      service_package: 1,
      email_accounts: 1,
      domain_settings: {
        name: 'tenmien',
        top_level: 1,
        duration: 1,
        epp_code: '',
        bought_domain: {
          option: 1,
          name: 'tenmien',
          cart: 1,
        },
        hostname: '',
      },
    },
  });
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách SSL
        </span>
      ),
      onClick: () => navigate(SERVICE_SSL_URL.list.path),
    },
    {
      title: 'Thêm cấu hình SSL',
    },
  ];

  return (
    <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <div className="px-2">
        <div className="bg-white p-4 rounded-[6px] drop-shadow mb-4 flex flex-col gap-4">
          <span>
            Giải pháp hosting cho doanh nghiệp cao cấp và mạnh mẽ nhất, sử dụng
            toàn bộ ổ cứng NVME U.2 RAID 10 cùng vi xử lý AMD EPYC/Intel Xeon
            Scalable Gen 2 cao cấp nhất chuyên dụng dành cho máy chủ, hệ thống
            mạng riêng biệt 10Gbps tốc độ cao giúp tăng sự chịu tải và tốc độ
            cho website.
          </span>
          <div className="flex justify-center gap-6">
            {emailSecurityTypes.map((type) => (
              <div
                key={type.id}
                className={`w-[300px] bg-white p-6 py-8 rounded-[10px] drop-shadow-xl flex flex-col items-center gap-4 cursor-pointer ${
                  form.watch('email_security_type') === type.id &&
                  'text-blue-800 !bg-[#EBF5FF]'
                }`}
                onClick={() => form.setValue('email_security_type', type.id)}
              >
                <div className="w-full h-[150px] bg-[#D9D9D9] rounded-[10px]" />
                <span className="w-full text-blue-800 font-[500]">
                  {type.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <FormTera form={form} className="flex-1 flex flex-col">
        {form.watch('email_security_type') === 1 ? (
          <FormAddBusiness form={form} />
        ) : (
          <FormAddRelay form={form} />
        )}
        {form.watch('product') && <FormSummary form={form} />}
      </FormTera>
    </div>
  );
};

export default AddSSLPage;
