import { IRouteProps } from '_common/interface/router';
import ShoppingCartPage from '.';
import { SHOPPING_CART_URL } from './url';
import PaymentPage from './pages/Payment';

export const ShoppingCartRouter: IRouteProps[] = [
  {
    key: SHOPPING_CART_URL.cart.key,
    path: SHOPPING_CART_URL.cart.shortenUrl,
    component: <ShoppingCartPage />,
  },
  {
    key: SHOPPING_CART_URL.payment.key,
    path: SHOPPING_CART_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
];
