import { useQuery } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import InvoiceAPI from 'pages/InvoiceManagement/Invoice/apis';
import { useEffect } from 'react';
import { Row, Spin } from 'tera-dls';
import TransactionItem from './TransactionItem';

const Transaction = (): JSX.Element => {
  const {
    data: dataList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-finance-invoice-list'],
    () => {
      return InvoiceAPI.getList({
        params: { transaction_type: 'Deposit' },
      });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <CardDetail title="Giao dịch nạp tiền gần đây" className="p-4">
      <Spin spinning={isLoading}>
        <Row className="grid-cols-4 gap-4">
          {dataList?.data.map((item) => (
            <TransactionItem item={item} />
          ))}
        </Row>
      </Spin>
    </CardDetail>
  );
};

export default Transaction;
