export enum CLOUD_VPS_STATUS {
  INITIALIZING = 'Initializing',
  RUNNING = 'Running',
  BLOCK = 'Block',
}
export const TABS_STATUS_ITEM = ['all', 'Running', 'Initializing', 'Block'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  Initializing: 'Khởi tạo',
  Running: 'Đang chạy',
  Block: 'Dịch vụ đã hủy',
};
export const TABLE_STATUS_COLOR = {
  Initializing: 'gray02',
  Running: 'green03',
  Block: 'red03',
};
export const TABLE_STATUS_TEXT = {
  Initializing: 'Khởi tạo',
  Running: 'Đang chạy',
  Block: 'Dịch vụ đã hùy',
};
export const deleteOptions = [
  { value: 1, label: 'Đổi IP và giữ lại dữ liệu' },
  { value: 2, label: 'Đổi IP và xóa dữ liệu' },
];
