import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { BusinessType } from './interface';

export class CommonStore {
  activeMenu = '';

  openMenuMore = false;

  business: BusinessType = {
    id: 1,
    name: '',
    ownerName: '',
  };
  module = 'crm';
  location_id = null;
  stock_id = 6;
  openModalLocationId = false;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'CommonStore',
      properties: ['business', 'location_id', 'stock_id'],
      storage: window.localStorage,
    });
  }

  setActiveMenu = (key: string) => {
    this.activeMenu = key;
  };

  setOpenMenuMore = (open: boolean) => {
    this.openMenuMore = open;
  };

  setBusiness = (params: BusinessType) => {
    this.business = params;
  };
  setLocationId = (id: number) => {
    this.location_id = id;
  };

  setOpenModalLocationId = (open: boolean) => {
    this.openModalLocationId = open;
  };

  clear = () => {
    this.business = {
      id: null,
      name: '',
      ownerName: '',
    };
    this.location_id = null;
    this.stock_id = 6;
  };
}
