import { useMutation } from '@tanstack/react-query';
import PageLoading from '_common/component/PageLoading';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthApi } from 'states/api';
import { getQueryParams, notification } from 'tera-dls';

const CheckAuthPage = () => {
  const navigate = useNavigate();
  const {
    authStore: { auth_url, updateUser },
  } = useStores();
  const location = useLocation();
  const params: { [key: string]: any } = getQueryParams(location.search);

  const { mutate: onCheckAuth, isLoading } = useMutation(
    (variables) => AuthApi.checkAuth(variables),
    {
      onSuccess: (res) => {
        updateUser(res?.data);

        setTimeout(() => {
          navigate('/');
        }, 200);
      },
      onError: (error: any) =>
        notification.error({
          message: error?.data?.msg ?? 'Lỗi bất thường trong quá trình login',
        }),
    },
  );

  useEffect(() => {
    try {
      if (!params?.client_id) return navigate('/welcome/login');

      const parseReq = JSON.parse(params?.req);

      if (parseReq?.iv && !isLoading) {
        onCheckAuth(parseReq);
      }
    } catch (error) {
      notification.error({
        message: error?.message || 'Lỗi bất thường trong quá trình login',
      });
    }
  }, [auth_url, params?.client_id]);

  return <PageLoading />;
};

export default observer(CheckAuthPage);
