import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const DashboardAPIEndpoint = `${endpoint}/portal/dashboard/overview`;

const DashboardAPI = {
  getOverviewService: async () =>
    await api
      .get(`${DashboardAPIEndpoint}/service`)
      .then((res) => res.data?.data),
};

export default DashboardAPI;
