import { IRouteProps } from '_common/interface/router';
import { DEPOSIT_URL } from './url';
import DepositPage from '.';

export const DepositRouter: IRouteProps[] = [
  {
    key: DEPOSIT_URL.page.key,
    path: DEPOSIT_URL.page.shortenUrl,
    component: <DepositPage />,
  },
];
