import Text from '_common/component/Text';
import BlurCitizenIDCard from 'styles/images/pages/KYC/blur-citizen-id-card.png';
import CutCitizenIDCard from 'styles/images/pages/KYC/cut-citizen-id-card.png';
import GlareCitizenIDCard from 'styles/images/pages/KYC/glare-citizen-id-card.png';
import ValidCitizenIDCard from 'styles/images/pages/KYC/valid-citizen-id-card.png';
import { Col } from 'tera-dls';

interface ICardHintProps {
  text: string;
  isCut?: boolean;
  isBlur?: boolean;
  isGlare?: boolean;
}

const CardHint = ({
  text,
  isCut,
  isBlur,
  isGlare,
}: ICardHintProps): JSX.Element => {
  return (
    <Col className="flex flex-col items-center">
      <img
        src={
          (isCut && CutCitizenIDCard) ||
          (isBlur && BlurCitizenIDCard) ||
          (isGlare && GlareCitizenIDCard) ||
          ValidCitizenIDCard
        }
        alt="Citizen ID Card"
      />
      <Text className="text-base">{text}</Text>
    </Col>
  );
};

export default CardHint;
