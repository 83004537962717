import { IRouteProps } from '_common/interface/router';
import UnAuthHostingPage from '.';
import { UNAUTH_SERVICE_HOSTING_URL } from './url';

export const UnAuthHostingRouter: IRouteProps[] = [
  {
    key: UNAUTH_SERVICE_HOSTING_URL.index.key,
    path: UNAUTH_SERVICE_HOSTING_URL.index.shortenUrl,
    component: <UnAuthHostingPage />,
  },
];
