export const SHOPPING_CART_URL = {
  cart: {
    key: '',
    path: '/shopping-cart/cart',
    shortenUrl: 'cart',
  },
  payment: {
    key: '',
    path: '/shopping-cart/payment',
    shortenUrl: 'payment',
  },
};
