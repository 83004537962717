import Filter from '_common/component/Filter';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { pick } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type IProps = {
  open: boolean;
  onClose: () => void;
  onFilter: (value: any) => void;
  initialValue: any;
};

const CommissionFilter = ({
  initialValue,
  onClose,
  onFilter,
  open,
}: IProps) => {
  const newForm = useForm({
    mode: 'onChange',
  });

  const handleReset = () => {
    const values = {
      service: null,
    };
    onFilter(values);
    onClose();
  };

  const handleSubmitForm = (values: any) => {
    onFilter({
      ...values,
    });
    onClose();
  };

  useEffect(() => {
    const values = pick(initialValue, ['service']);

    newForm?.reset({
      ...values,
    });
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onFilter={() => newForm?.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={newForm} onSubmit={handleSubmitForm}>
        <FormTeraItem label="Dịch vụ" name="service">
          <Select
            placeholder="Vui lòng chọn"
            options={[
              {
                value: 1,
                label: 'Tất cả',
              },
            ]}
          />
        </FormTeraItem>
        <a
          className="text-sm font-normal text-red-500 cursor-pointer"
          onClick={handleReset}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
};

export default CommissionFilter;
