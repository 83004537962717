import TextNavigate from '_common/component/TextNavigate';
import {
  INVOICE_STATUS,
  PAYMENT_METHOD_TEXT,
  PAYMENT_METHOD_TYPE,
} from '_common/constants/invoiceManagement';
import { copyText } from '_common/utils';
import _ from 'lodash';
import {
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
} from 'pages/InvoiceManagement/Invoice/constants';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';
import bgInvoice from 'styles/images/wallet/bg-invoice.png';
import {
  CreditCardSolid,
  DocumentDuplicateOutlined,
  Tag,
  Tooltip,
  formatCurrency,
  formatDate,
} from 'tera-dls';

const CheckedIcon = (props) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_13987_316)">
      <path
        d="M28 3C34.6304 3 40.9893 5.63392 45.6777 10.3223C50.3661 15.0107 53 21.3696 53 28C53 34.6304 50.3661 40.9893 45.6777 45.6777C40.9893 50.3661 34.6304 53 28 53C21.3696 53 15.0107 50.3661 10.3223 45.6777C5.63392 40.9893 3 34.6304 3 28C3 21.3696 5.63392 15.0107 10.3223 10.3223C15.0107 5.63392 21.3696 3 28 3ZM24.8857 32.9321L19.3321 27.375C19.133 27.1759 18.8967 27.018 18.6366 26.9102C18.3764 26.8025 18.0976 26.747 17.8161 26.747C17.5345 26.747 17.2557 26.8025 16.9956 26.9102C16.7355 27.018 16.4991 27.1759 16.3 27.375C15.8979 27.7771 15.672 28.3224 15.672 28.8911C15.672 29.4597 15.8979 30.0051 16.3 30.4071L23.3714 37.4786C23.57 37.6787 23.8061 37.8375 24.0663 37.9459C24.3265 38.0543 24.6056 38.1101 24.8875 38.1101C25.1694 38.1101 25.4485 38.0543 25.7087 37.9459C25.9689 37.8375 26.205 37.6787 26.4036 37.4786L41.0464 22.8321C41.2482 22.6339 41.4087 22.3976 41.5187 22.137C41.6287 21.8764 41.686 21.5966 41.6873 21.3137C41.6886 21.0309 41.6339 20.7505 41.5263 20.4889C41.4188 20.2273 41.2605 19.9896 41.0606 19.7894C40.8607 19.5893 40.6231 19.4307 40.3616 19.3228C40.1001 19.215 39.8199 19.1599 39.537 19.1609C39.2542 19.1619 38.9743 19.2189 38.7135 19.3286C38.4528 19.4383 38.2164 19.5985 38.0179 19.8L24.8857 32.9321Z"
        fill="#0E9F6E"
      />
    </g>
    <rect
      x="1.5"
      y="1.5"
      width="53"
      height="53"
      rx="26.5"
      stroke="white"
      strokeWidth="3"
    />
    <defs>
      <clipPath id="clip0_13987_316">
        <rect x="3" y="3" width="50" height="50" rx="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const UnCheckIcon = (props) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25ZM22.3237 21.3037C25.2628 19.8342 28.572 22.4888 27.7751 25.6766L25.9571 32.9486L26.0635 32.8954C27.0134 32.4204 28.1686 32.8054 28.6435 33.7554C29.1185 34.7053 28.7335 35.8605 27.7835 36.3355L27.6771 36.3887C24.7381 37.8582 21.4288 35.2036 22.2258 32.0157L24.0438 24.7438L23.9374 24.797C22.9874 25.272 21.8323 24.8869 21.3573 23.937C20.8823 22.987 21.2674 21.8319 22.2173 21.3569L22.3237 21.3037ZM25 17.3077C26.0621 17.3077 26.9231 16.4467 26.9231 15.3846C26.9231 14.3225 26.0621 13.4615 25 13.4615C23.9379 13.4615 23.0769 14.3225 23.0769 15.3846C23.0769 16.4467 23.9379 17.3077 25 17.3077Z"
      fill="#C27803"
    />
  </svg>
);

const TransactionItem = ({ item }) => {
  return (
    <div
      className="px-[16px] [box-shadow:0px_1px_5px_0px_#0000001A,0px_-1px_5px_0px_#0000001A] rounded-xl pt-[30px]"
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,0.95),rgba(255,255,255,0.95)),url(${bgInvoice})`,
        backgroundPosition: 'center',
      }}
    >
      <div className="flex flex-col gap-[13px] min-h-[400px]">
        <div className="flex flex-col items-center gap-[16px]">
          <h2 className="text-base font-bold">ZingServer Auto Payment</h2>
          {_.isEqual(item.status, INVOICE_STATUS.PAID) ? (
            <CheckedIcon />
          ) : (
            <UnCheckIcon />
          )}
        </div>
        <div className="flex gap-x-2.5 items-center">
          <CreditCardSolid className="size-9" color="#3F83F8" />
          <div className="flex flex-col gap-y-[5px] text-base">
            <span className={'text-gray-500 font-light'}>
              {item?.payment_method_id === 0
                ? PAYMENT_METHOD_TEXT[PAYMENT_METHOD_TYPE.WALLET]
                : PAYMENT_METHOD_TEXT[item.payment_method?.type?.type]}
            </span>
            <span className="text-green-500 font-bold flex gap-1 items-center">
              <span className="tracking-[3px]">
                {formatCurrency(item.total)}
              </span>
              <Tooltip placement="top" title="Sao chép">
                <DocumentDuplicateOutlined
                  className="size-5 text-green-600 shrink-0 cursor-pointer"
                  onClick={() => copyText(item.total)}
                  strokeWidth={'1px'}
                />
              </Tooltip>
            </span>
          </div>
        </div>
        <div className="flex flex-col divide-y-[1px] divide-dashed">
          <div className="flex flex-col gap-[7px] pb-[13px]">
            <div className="flex justify-between items-center">
              <span className={'text-gray-500 font-light'}>Trạng thái</span>
              <span className="flex gap-x-1 items-center">
                <Tag
                  className="font-medium"
                  color={TABLE_STATUS_COLOR[item.status]}
                >
                  {TABLE_STATUS_TEXT[item.status]}
                </Tag>
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className={'text-gray-500 font-light'}>Thời gian</span>
              <span className="flex gap-x-1 items-center">
                {formatDate(item.created_at)}
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center py-[13px]">
            <span className={'text-gray-500 font-light'}> Nội dung</span>
            <span className="flex gap-x-1 items-center">
              <TextNavigate to={`${INVOICE_URL.detail.path}/${item.id}`}>
                {item.invoice_no}
              </TextNavigate>
              <Tooltip placement="top" title="Sao chép">
                <DocumentDuplicateOutlined
                  strokeWidth={'1px'}
                  className="size-5 text-blue-600 shrink-0 cursor-pointer"
                  onClick={() => copyText(item.invoice_no)}
                />
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionItem;
