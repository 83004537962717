import { IRouteProps } from '_common/interface/router';
import WithdrawalPage from '.';
import { WITHDRAWAL_URL } from './url';

export const WithdrawalRouter: IRouteProps[] = [
  {
    key: WITHDRAWAL_URL.list.key,
    path: WITHDRAWAL_URL.list.shortenUrl,
    component: <WithdrawalPage />,
  },
];
