import FormTera from '_common/dof/FormTera';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

const EditableRow: React.FC<any> = (props) => {
  const form = useForm();
  const { record, onSave } = props;

  // const watchedData = useWatch({ control: form.control });
  // const isUpdatedData: boolean = Object.keys(watchedData)?.length > 0;

  const handleSubmitForm = useCallback(
    _.debounce((values: any): void => {
      onSave && onSave({ ...record, ...values });
    }, 0),
    [onSave],
  );

  // useEffect(() => {
  //   isUpdatedData && form.handleSubmit(handleSubmitForm)();
  // }, [watchedData]);

  return (
    <FormTera
      form={form}
      key={props['data-row-key']}
      wrapper_type="table"
      isUpdate
      onSubmit={handleSubmitForm}
      {...props}
    />
  );
};

export default React.memo(EditableRow);
