import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const OSAPIEndpoint = `${endpoint}/portal/operating-system`;

const OSAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${OSAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${OSAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
};

export default OSAPI;
