import { IRouteProps } from '_common/interface/router';
import LogoutPage from '.';
import { LOGOUT_URL } from './url';

export const LogoutRouter: IRouteProps[] = [
  {
    key: LOGOUT_URL.logout.key,
    path: LOGOUT_URL.logout.shortenUrl,
    component: <LogoutPage />,
  },
];
