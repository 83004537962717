import { useQuery } from '@tanstack/react-query';
import { useStores } from '_common/hooks';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { BrowserRouter } from 'react-router-dom';
import { AuthApi } from 'states/api';
import { Routers } from './routers';

const basename = document.querySelector('base')?.getAttribute('href') ?? '/';

const Root = observer(() => {
  const {
    authStore,
    commonStore: { setBusiness, clear: clearCRM },
  } = useStores();

  useQuery(['get-device'], AuthApi.getDeviceCode, {
    staleTime: 300000,
    // enabled: !authStore.device,
    onSuccess: (data) => {
      authStore.setInitData(data);
    },
  });

  useQuery(['get-profile'], AuthApi.getProfile, {
    staleTime: 300000,
    enabled: !!authStore.token,
    onSuccess: (res) => {
      if (_.isEqual(res.code, 200)) {
        setBusiness({
          id: res?.data?.business?.id,
          name: res?.data?.business?.name,
          ownerName: res?.data?.business?.owner_name,
        });
        authStore.updateUser({ user: res?.data });
      }
    },
    onError: (res) => {
      console.log(res);
      authStore.clear();
      clearCRM();
    },
  });

  // useQuery(['get_permissions'], AuthApi.getPermissions, {
  //   staleTime: 300000,
  //   enabled: !!authStore.token,
  //   onSuccess: (res) => {
  //     authStore.updatePermissions(res?.data);
  //   },
  // });
  return (
    <BrowserRouter basename={basename}>
      <Routers />
    </BrowserRouter>
  );
});

export default Root;
