import { IColumnType } from '_common/component/ColumnSetting/_interface';
import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import { IColumnSettingStore } from './interface';

export class ColumnSettingStore {
  open = false;
  object_type = null;
  columns = {};
  modalProps = {};

  constructor() {
    makeAutoObservable(this);
  }

  setColumnSetting = (value: IColumnSettingStore): void => {
    this.open = value.open;
    this.object_type = value.object_type;
  };

  openModal = (objectType: string): void => {
    this.open = true;
    this.object_type = objectType;
  };

  closeModal = (): void => {
    this.open = false;
    this.object_type = null;
  };

  add = (columns: IColumnType, objectType: string): void => {
    this.columns[objectType] = columns;
  };

  setModalProps = (props: { [key: string]: any } = {}): void => {
    this.modalProps = { ...this.modalProps, ...props };
  };

  canSetting = (objectType: string): boolean => !!this.columns[objectType];

  clear = (objectType: string): void => {
    this.open = false;
    this.object_type = null;
    const clonedColumns = _.cloneDeep(this.columns);
    delete clonedColumns[objectType];
    this.columns = clonedColumns;
  };

  clearAll = (): void => {
    this.open = false;
    this.object_type = null;
    this.columns = {};
  };
}
