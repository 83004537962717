import CardDetail from '_common/component/CardDetail';
import CheckBox from '_common/dof/Control/CheckBox';
import { FormTeraItem } from '_common/dof/FormTera';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
  Button,
  CheckSolid,
  Col,
  formatCurrency,
  getQueryParams,
} from 'tera-dls';

interface IPaymentProps {
  form: UseFormReturn<any>;
  handleSubmitForm: (values: any) => void;
}

const Payment = ({ form, handleSubmitForm }: IPaymentProps): JSX.Element => {
  const {
    shoppingCartStore: { cart },
  } = useStores();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const serviceInCart = useMemo(() => cart?.[queryParams.cart], [cart]);
  const price = Number(
    useMemo(() => serviceInCart?.first_payment, [serviceInCart]),
  );
  const priceVAT = Number(useMemo(() => price * 0.08, [price]));

  const details = [
    {
      label: 'Tổng giá trị sản phẩm',
      value: (
        <span className="text-[#C27803] font-[500]">
          {formatCurrency(price)}
        </span>
      ),
    },
    {
      label: 'Phí khởi tạo',
      value: <span className="font-[500]">{formatCurrency(0)}</span>,
    },
    {
      label: 'Giảm giá',
      value: <span className="font-[500]">0%</span>,
    },
    {
      label: 'VAT (10%)',
      value: <span className="font-[500]">{formatCurrency(priceVAT)}</span>,
    },
    {
      label: 'Tổng thanh toán',
      value: (
        <span className="text-[#046C4E] font-[500]">
          {formatCurrency(price + priceVAT)}
        </span>
      ),
    },
  ];

  return (
    <CardDetail title="Thanh toán" className="p-4 flex flex-col gap-4">
      <Col className="flex flex-col gap-2">
        <Col className="w-full pb-8 border-b-[1px] flex flex-col gap-8">
          {details.map((detail, index) => (
            <div key={index} className="flex justify-between items-center">
              <span>{detail.label}</span>
              <span>{detail.value}</span>
            </div>
          ))}
        </Col>
        <Col className="pt-6 flex flex-col gap-4">
          <FormTeraItem name="is_ok" className="mb-0">
            <CheckBox>
              <span className="text-gray-500">Tôi đã đọc và chấp nhận</span>
              <span className="text-blue-500 ml-1">
                Điều khoản sử dụng dịch
              </span>
            </CheckBox>
          </FormTeraItem>
          {/* <Button
            prefix={<ShoppingCartSolid />}
            className="text-blue-500 hover:text-white bg-white border-[1px] border-blue-500"
            onClick={() => navigate(SERVICE_REGISTRATION_URL.list.path)}
          >
            Đăng ký thêm dịch vụ
          </Button> */}
          <Button
            htmlType="button"
            prefix={<CheckSolid />}
            className="bg-blue-500"
            onClick={() => form.handleSubmit(handleSubmitForm)()}
            disabled={!form.watch('is_ok')}
          >
            Hoàn thành
          </Button>
        </Col>
      </Col>
    </CardDetail>
  );
};

export default observer(Payment);
