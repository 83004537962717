import customTwMerge from 'tailwind-merge.config';

interface IIconTextProps {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const IconText = ({
  prefix,
  suffix,
  className,
  onClick,
  children,
}: IIconTextProps): JSX.Element => {
  return (
    <div
      className={customTwMerge('flex items-center gap-2', className)}
      onClick={onClick}
    >
      {prefix}
      {children}
      {suffix}
    </div>
  );
};

export default IconText;
