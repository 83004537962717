import { useMutation, useQueryClient } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import HeaderViewList from '_common/component/HeaderViewList';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import FormTera from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import UserAccountAPI from 'pages/Account/_common/apis';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, notification } from 'tera-dls';
import FormRegister from './components/FormRegister';
import { uuidv4 } from '_common/utils';

const RegisterPage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      username: '',
      email: '',
      country: '',
      country_code: '+84',
      last_middle_name: '',
      birthday: null,
      province: '',
      first_name: '',
      phone: '',
      district: '',
      gender: '',
      address: '',
      ward: '',
      citizen_id: '',
      customer_id: uuidv4(),
      password: '',
      confirm_password: '',
      status: 1,
    },
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: mutateCreateUpdate, isLoading: isLoadingCreateUpdate } =
    useMutation((params) => UserAccountAPI.create({ params }), {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          queryClient.invalidateQueries(['get-profile']);
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    });

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreateUpdate) return;

    const data = {
      ...values,
      birthday: values?.birthday
        ? moment(values?.birthday).format(DATE_BACKEND_FORMAT)
        : null,
    };

    mutateCreateUpdate(data);
  };

  return (
    <div className="tera-page">
      <HeaderViewList
        title="ĐĂNG KÝ"
        actionRight={
          <Button
            className="bg-blue-500"
            onClick={form.handleSubmit(handleSubmitForm)}
          >
            Đăng ký
          </Button>
        }
      >
        <CardDetail className="w-full h-full p-3">
          <FormTera form={form} className="flex flex-col gap-4">
            <FormRegister />
          </FormTera>
        </CardDetail>
      </HeaderViewList>
    </div>
  );
};

export default RegisterPage;
