import React from 'react';
import DynamicControl from '../../Control/DynamicControl';
import { FormTeraItem } from '../../FormTera';
import { TABLE_ROW_EXCEPT_COPE_CLASS } from '../constants';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import customTwMerge from 'tailwind-merge.config';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: any;
  record: any;
  index: number;
  children: React.ReactNode;
  control: any;
  register: any;
  inputProps: any;
  rules: any;
  editableRender?: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  inputType,
  children,
  dataIndex,
  inputProps,
  rules = [],
  editableRender,
  record,
  index,
  className,
  ...restProps
}) => {
  const { form } = useTeraForm();

  return (
    <td {...restProps} className={customTwMerge(className, '!py-3')}>
      {editing ? (
        editableRender ? (
          editableRender
        ) : (
          <FormTeraItem
            name={dataIndex}
            isUpdate={editing}
            rules={rules}
            label=""
            displayLabel={false}
            style={{ margin: 0 }}
          >
            <DynamicControl
              type={inputType}
              {...(typeof inputProps === 'function'
                ? inputProps?.(record, form, index)
                : inputProps)}
              {...{
                ...(inputType === 'select' && {
                  className: 'bg-white',
                  dropdownClassName: `${TABLE_ROW_EXCEPT_COPE_CLASS}`,
                }),
              }}
            />
          </FormTeraItem>
        )
      ) : (
        children
      )}
    </td>
  );
};

export default React.memo(EditableCell);
