export default ({
  title,
  leftSide,
}: {
  title: string;
  leftSide?: JSX.Element;
}): JSX.Element => (
  <div className="w-full bg-[#f3f3f9] p-3 flex justify-between items-center sticky top-[45px] z-[49]">
    <div className="text-[16px] font-bold text-left">{title}</div>
    {leftSide && leftSide}
  </div>
);
