import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const WalletAPIEndpoint = `${endpoint}/portal/finance/wallet`;

const WalletAPI = {
  deposit: async ({ params }) =>
    await api
      .post(`${WalletAPIEndpoint}/deposit`, params)
      .then((res) => res.data),
  withdrawal: async ({ params }) =>
    await api
      .post(`${WalletAPIEndpoint}/withdrawal`, params)
      .then((result) => result.data),
};

export default WalletAPI;
