export const TABS_STATUS_ITEM = ['all', '1', '2', '0'];
export const TABS_STATUS_TEXT = {
  all: 'Tất cả',
  0: 'Đã hủy',
  1: 'Hoạt động',
  2: 'Đang chờ',
};
export const DOMAIN_STATUS_COLOR = {
  0: 'red03',
  1: 'green03',
  2: 'yellow03',
};
export const DOMAIN_STATUS = {
  0: 'Đã hủy',
  1: 'Hoạt động',
  2: 'Đang chờ',
};
export const emailSecurityTypes = [
  { id: 1, name: 'Comodo SSL' },
  { id: 2, name: 'RapidSSL' },
];
export const comodoServicePackages = [
  {
    id: 1,
    name: 'Comodo PositiveSSL',
  },
  {
    id: 2,
    name: 'Comodo PositiveSSL Wildcard',
  },
  {
    id: 3,
    name: 'Comodo PositiveSSL Certificate (DV)',
  },
];
export const rapidServicePackages = [
  {
    id: 1,
    name: 'RapidSSL Certificate',
  },
  {
    id: 2,
    name: 'RapidSSL Wildcard Certificate',
  },
];
export const durations = [
  { value: 1, label: '1 năm - 30,000 đ' },
  { value: 2, label: '2 năm - 60,000 đ' },
  { value: 3, label: '3 năm - 90,000 đ' },
  { value: 4, label: '4 năm - 120,000 đ' },
  { value: 5, label: '5 năm - 150,000 đ' },
  { value: 6, label: '6 năm - 180,000 đ' },
  { value: 7, label: '7 năm - 210,000 đ' },
  { value: 8, label: '8 năm - 240,000 đ' },
  { value: 9, label: '9 năm - 270,000 đ' },
  { value: 10, label: '10 năm - 300,000 đ' },
];
export const billingCycleOptions = [
  { value: 1, label: '01 tháng' },
  { value: 2, label: '03 tháng' },
  { value: 3, label: '06 tháng' },
  { value: 4, label: '01 năm - 10% discount' },
  { value: 5, label: '02 năm - 15% discount' },
  { value: 6, label: '03 năm - 20% discount' },
  { value: 7, label: '04 năm - 25% discount' },
  { value: 8, label: '05 năm - 30% discount' },
];
export const serverSoftwareOptions = [
  { value: 1, label: 'AOL' },
  { value: 2, label: 'Tên phần mềm server' },
];
