import { IRouteProps } from '_common/interface/router';
import ServiceRegistrationPage from '.';
import { SERVICE_REGISTRATION_URL } from './url';

export const ServiceRegistrationRouter: IRouteProps[] = [
  {
    key: SERVICE_REGISTRATION_URL.list.key,
    path: SERVICE_REGISTRATION_URL.list.shortenUrl,
    component: <ServiceRegistrationPage />,
  },
];
