import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const InvoiceAPIEndpoint = `${endpoint}/portal/bill`;

const InvoiceAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${InvoiceAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${InvoiceAPIEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  pay: async ({ id, params }) =>
    await api
      .put(`${InvoiceAPIEndpoint}/pay/${id}`, params)
      .then((res) => res.data),
};

export default InvoiceAPI;
