import { useQuery } from '@tanstack/react-query';
import Text from '_common/component/Text';
import RadioGroup from '_common/dof/Control/RadioGroup';
import { useMemo } from 'react';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';
import { Spin } from 'tera-dls';

interface IRadioPaymentMethodProps {
  isIncludeWallet?: boolean;
}

const RadioPaymentMethod = ({
  isIncludeWallet,
}: IRadioPaymentMethodProps): JSX.Element => {
  const { data: dataPaymentMethodList, isLoading } = useQuery(
    ['get-finance-payment-method-list-system'],
    () => {
      return PaymentMethodAPI.getListOfSystem({ params: {} });
    },
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const PaymentMethodOptions = useMemo(
    () =>
      dataPaymentMethodList
        ? [
            ...dataPaymentMethodList?.data.map((paymentMethod) => ({
              value: paymentMethod.id,
              label: (
                <Text className="flex flex-col">
                  <span>
                    {paymentMethod.type.name} - {paymentMethod.type.description}
                  </span>
                  <span className="text-amber-700">
                    {paymentMethod.account_number}
                  </span>
                </Text>
              ),
            })),
            ...(isIncludeWallet ? [{ value: '0', label: 'Ví ZingCloud' }] : []),
          ]
        : [],
    [dataPaymentMethodList],
  );

  return (
    <Spin spinning={isLoading}>
      <RadioGroup listOptions={PaymentMethodOptions} />
    </Spin>
  );
};

export default RadioPaymentMethod;
