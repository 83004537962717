import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TextNavigate from '_common/component/TextNavigate';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  Tag,
  formatDate,
  notification,
} from 'tera-dls';
import DomainAPI from '../apis';
import {
  DOMAIN_AUTORENEW_COLOR,
  DOMAIN_AUTORENEW_TEXT,
  DOMAIN_PERIOD_TEXT,
  DOMAIN_STATUS,
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
} from '../constants';
import { SERVICE_DOMAIN_URL } from '../url';
import _ from 'lodash';

const Table = (props: ITeraTableProps): JSX.Element => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: mutateRequestCancel, isLoading: isLoadingRequestCancel } =
    useMutation((id: any) => DomainAPI.requestCancel(id), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-service-domain-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    });

  const handleRequestCancel = (record: any) =>
    confirm.warning({
      title: 'Xác nhận yêu cầu hủy dịch vụ',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn yêu cầu hủy dịch vụ</p>
          <p>
            <b>{record.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateRequestCancel({ id: record.id }),
    });

  const itemActions = (record: any): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () =>
          navigate(`${SERVICE_DOMAIN_URL.detail.path}/${record.id}`),
      },
      ...(!_.isEqual(record.status, DOMAIN_STATUS.SUSPENDED) &&
      !_.isEqual(record?.is_request_cancel, 1)
        ? [
            {
              key: '2',
              label: <span className="text-red-500">Yêu cầu hủy dịch vụ</span>,
              onClick: () => handleRequestCancel(record),
            },
          ]
        : []),
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'IP',
      dataIndex: 'ip',
      width: 150,
      render: (ip, record) => (
        <TextNavigate to={`${SERVICE_DOMAIN_URL.detail.path}/${record.id}`}>
          {ip}
        </TextNavigate>
      ),
    },
    {
      title: 'Tên miền',
      dataIndex: 'name',
      width: 150,
      render: (name, record) => (
        <TextNavigate to={`${SERVICE_DOMAIN_URL.detail.path}/${record.id}`}>
          {name}
        </TextNavigate>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag color={TABLE_STATUS_COLOR[status]} className="font-[500]">
          {TABLE_STATUS_TEXT[status]}
        </Tag>
      ),
    },
    {
      title: 'Chu kỳ thanh toán',
      dataIndex: 'period',
      width: 150,
      render: (period) => DOMAIN_PERIOD_TEXT[period],
    },
    {
      title: 'Ngày đăng ký',
      dataIndex: 'date_created',
      width: 150,
      render: (date_created) => formatDate(date_created),
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expires',
      width: 150,
      render: (expires) => formatDate(expires),
    },
    {
      title: 'Tự động gia hạn',
      dataIndex: 'autorenew',
      width: 150,
      render: (autorenew) => (
        <Tag
          color={DOMAIN_AUTORENEW_COLOR[autorenew]}
          className={`font-[500] ${autorenew !== 1 ? '!text-yellow-600' : ''}`}
        >
          {DOMAIN_AUTORENEW_TEXT[autorenew]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        return (
          <ActionDropdown dropdownItems={itemActions(record)} trigger="click" />
        );
      },
    },
  ];

  return (
    <TableTera
      rowKey="id"
      columns={columns}
      loading={props?.loading || isLoadingRequestCancel}
      className="center-table"
      {...props}
    />
  );
};

export default Table;
