export const SERVICE_DOMAIN_URL = {
  list: {
    key: '',
    path: '/service/domain/list',
    shortenUrl: 'domain/list',
  },
  create: {
    key: '',
    path: '/service/domain/create',
    shortenUrl: 'domain/create',
  },
  detail: {
    key: '',
    path: '/service/domain/detail',
    shortenUrl: 'domain/detail/:id',
  },
  update: {
    key: '',
    path: '/service/domain/update',
    shortenUrl: 'domain/update/:id',
  },
};
